import React from 'react';
import styles from './index.module.css';

type MarketInfoProps = {
    markets?: Array<{
        marketName: string;
        agentMlsInfo: Array<AgentInfo>;
    }>;
};

type AgentInfo = {
    displayMlsName: string;
    agentIds: Array<string>;
};
const MarketsInfo = ({ markets }: MarketInfoProps) => {
    const formattedIds = (ids: Array<string>) => {
        return (
            <div className={styles.subContainer}>
                {ids.map((id, idx) => (
                    <div key={idx} className={styles.idText}>
                        MLS ID: {id}
                    </div>
                ))}
            </div>
        );
    };
    const formattedAgentMlsInfo = (info: Array<AgentInfo>) => {
        return (
            <div className={styles.subContainer}>
                {info.map((agentInfo: AgentInfo, idx) => (
                    <div key={idx}>
                        <span className={styles.mlsName}>{agentInfo.displayMlsName}</span>
                        {formattedIds(agentInfo.agentIds)}
                    </div>
                ))}
            </div>
        );
    };
    const formattedMarkets = markets.map((market, idx) => {
        return (
            <div className={styles.subContainer} key={idx}>
                <span>{market.marketName}</span>
                {formattedAgentMlsInfo(market.agentMlsInfo)}
            </div>
        );
    });
    return <div className={styles.marketIdContainer}>{formattedMarkets}</div>;
};

export default MarketsInfo;
