import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { connect } from 'react-redux';
import { fetchAdminUsersRequested } from '../../actions';
import { getAdminUsers, getLoading } from '../../selectors';
import { RandomLoader } from '../../../../../components';
import { AdminUseCard } from './AdminCard';

const mapDispatchToProps = (dispatch: any) => ({
    fetchAdminUsersRequested: () => dispatch(fetchAdminUsersRequested()),
});
const mapStateToProps = (state: any) => ({
    loading: getLoading(state),
    adminUsers: getAdminUsers(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type adminUserProps = {
    loading: boolean;
    adminUsers: Array<any>;
    fetchAdminUsersRequested: Function;
};

const AdminUsers = ({ loading, adminUsers, fetchAdminUsersRequested }: adminUserProps) => {
    useEffect(() => {
        fetchAdminUsersRequested();
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.adminContainer}>
                <div className={styles.sectionText}>Admins:</div>
                <div className={styles.line}></div>

                {!loading && adminUsers?.length ? (
                    adminUsers.map((admin) => {
                        return (
                            <>
                                <AdminUseCard admin={admin} />
                                <div className={styles.line}></div>
                            </>
                        );
                    })
                ) : (
                    <RandomLoader />
                )}
            </div>
        </div>
    );
};

export default connector(AdminUsers);
