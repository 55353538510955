import { createSelector } from 'reselect';
import { createStringLiteral } from 'typescript';
import { SearchableDropdown } from 'web-lib';
import { Brokerage } from './reducer';

export const getBrokerageState = (state: { brokerages: Brokerage }) => state.brokerages || {};
export const getAllBrokerages = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.allBrokerages[0]?.data || {},
);
export const getAllBrokeragesLoading = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.loading || false,
);
export const getTotalCount = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.allBrokerages?.totalCount || 0,
);
export const getSelectedBrokerage = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.selectedBrokerage || {},
);
export const getNumberOfPages = createSelector(
    getBrokerageState,
    (brokerage: any) => Math.ceil(brokerage.allBrokerages[0]?.metadata[0]?.total / 50) || {},
);
export const getSelectedBrokerageUsers = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.selectedBrokerageUsers[0]?.data || {},
);
export const getSelectedBrokerageUsersTotalPages = createSelector(
    getBrokerageState,
    (brokerage: any) =>
        Math.ceil(brokerage.selectedBrokerageUsers[0]?.metadata[0]?.total / 50) || {},
);
export const getSelectedBrokerageAgents = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.selectedBrokerageAgents[0]?.data || {},
);
export const getSelectedBrokerageAgentsTotalPages = createSelector(
    getBrokerageState,
    (brokerage: any) =>
        Math.ceil(brokerage.selectedBrokerageAgents[0]?.metadata[0]?.total / 50) || {},
);
export const getCurrentPage = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.currentPage || 1,
);
export const getErrors = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.error || null,
);
export const getSuccess = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.success || null,
);

export const getErrorActivateBSuite = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.errorActivateBSuite,
);

export const getLoadingActivateBSuite = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.loadingActivateBSuite,
);

export const getErrorActivatePlus = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.errorActivatePlus,
);
export const getLoadingActivatePlus = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage.loadingActivatePlus || false,
);

export const getLoadingSelectedBrokerage = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage?.loadingSelectedBrokerage || false,
);

export const getLoadingBrokerageUsers = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage?.loadingBrokerageUsers,
);

export const getLoadingBrokerageAgents = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage?.loadingBrokerageAgents,
);

export const getFetchBrokerageUsersError = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage?.fetchBrokerageUsersError,
);

export const getFetchBrokerageAgentsError = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage?.fetchBrokerageAgentsError,
);

export const getCreateBrokerageUserError = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage?.createBrokerageUserError,
);

export const getCreateBrokerageUserSuccess = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage?.createBrokerageUserSuccess,
);

export const getCreateBrokerageUserLoading = createSelector(
    getBrokerageState,
    (brokerage: any) => brokerage?.loadingCreateBrokerageUser,
);
/*------------- Delete User -------------------*/
export const isDeletingUser = createSelector(
    getBrokerageState,
    (state: any) => state.loadingDeleteUser,
);
/*------------- Update User -------------------*/
export const getLoadingUpdateUser = createSelector(
    getBrokerageState,
    (state: any) => state.loadingUpdateUser,
);
export const getErrorUpdateUser = createSelector(
    getBrokerageState,
    (state: any) => state.updateUserError,
);
