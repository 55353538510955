import { BSON } from 'mongodb-stitch-browser-sdk';
import { STATUS } from '../../../store/api/constants';
import {
    ApprovalType,
    ListingScheduleType,
    LockBoxType,
    UserOnAgentListing,
} from '../../../utils/constants';
import { AgentSearchType, EnvironmentType, InternExternType, ListingSearchType } from './constants';

export enum DATA_ACTIONS {
    CreateLoggedAgent = '[Data] Create Logged Agent',
    SearchAgent = '[Data] Search Agent Cross Environment',
    SearchListing = '[Data] Search Listing Cross Environment',
    DeleteAgent = '[Data] Delete Agent',
    ClearSearchResults = '[Data] Clear Search Results',
    AttributeListing = '[Data] Attribute Listing',
    ConnectListing = '[Data] Connect Listing',
}

// CREATE LOGGED AGENT =====================================
export const createLoggedAgentRequested = (
    agentData: {
        internExtern: InternExternType;
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
    },
    environment: EnvironmentType,
) =>
    ({
        type: DATA_ACTIONS.CreateLoggedAgent,
        status: STATUS.Requested,
        agentData,
        environment,
    } as const);
export const createLoggedAgentSucceeded = () =>
    ({
        type: DATA_ACTIONS.CreateLoggedAgent,
        status: STATUS.Succeeded,
    } as const);
export const createLoggedAgentFailed = (error: any) =>
    ({
        type: DATA_ACTIONS.CreateLoggedAgent,
        status: STATUS.Failed,
        error,
    } as const);

export type CreateLoggedAgentAction =
    | ReturnType<typeof createLoggedAgentRequested>
    | ReturnType<typeof createLoggedAgentSucceeded>
    | ReturnType<typeof createLoggedAgentFailed>;

// AGENT SEARCH IN ANY ENV =====================================
export const agentSearchCrossEnvRequested = (
    searchText: string,
    searchType: AgentSearchType,
    environment: EnvironmentType,
) =>
    ({
        type: DATA_ACTIONS.SearchAgent,
        status: STATUS.Requested,
        searchText,
        searchType,
        environment,
    } as const);
export const agentSearchCrossEnvSucceeded = (foundAgents) =>
    ({
        type: DATA_ACTIONS.SearchAgent,
        status: STATUS.Succeeded,
        foundAgents,
    } as const);
export const agentSearchCrossEnvFailed = (error: any) =>
    ({
        type: DATA_ACTIONS.SearchAgent,
        status: STATUS.Failed,
        error,
    } as const);

export type SearchAgentCrossEnv =
    | ReturnType<typeof agentSearchCrossEnvRequested>
    | ReturnType<typeof agentSearchCrossEnvSucceeded>
    | ReturnType<typeof agentSearchCrossEnvFailed>;

// LISTING SEARCH IN ANY ENVIRONMENT =====================================
export const listingSearchCrossEnvRequested = (
    searchText: string,
    searchType: ListingSearchType,
    environment: EnvironmentType,
) =>
    ({
        type: DATA_ACTIONS.SearchListing,
        status: STATUS.Requested,
        searchText,
        searchType,
        environment,
    } as const);
export const listingSearchCrossEnvSucceeded = (foundListings: Array<any>) =>
    ({
        type: DATA_ACTIONS.SearchListing,
        status: STATUS.Succeeded,
        foundListings,
    } as const);
export const listingSearchCrossEnvFailed = (error: any) =>
    ({
        type: DATA_ACTIONS.SearchListing,
        status: STATUS.Failed,
        error,
    } as const);

export type SearchListingCrossEnv =
    | ReturnType<typeof listingSearchCrossEnvRequested>
    | ReturnType<typeof listingSearchCrossEnvSucceeded>
    | ReturnType<typeof listingSearchCrossEnvFailed>;

// DELETE AGENT IN ANY ENVIRONMENT =====================================
export const deleteAgentRequested = (agentObjectId: string, environment: EnvironmentType) =>
    ({
        type: DATA_ACTIONS.DeleteAgent,
        status: STATUS.Requested,
        agentObjectId,
        environment,
    } as const);
export const deleteAgentSucceeded = () =>
    ({
        type: DATA_ACTIONS.DeleteAgent,
        status: STATUS.Succeeded,
    } as const);
export const deleteAgentFailed = (error: any) =>
    ({
        type: DATA_ACTIONS.DeleteAgent,
        status: STATUS.Failed,
        error,
    } as const);

export type DeleteAgent =
    | ReturnType<typeof deleteAgentRequested>
    | ReturnType<typeof deleteAgentSucceeded>
    | ReturnType<typeof deleteAgentFailed>;

// ATTRIBUTE LISTING TO AGENT =====================================
export const attributeListingRequested = (
    listingData: any,
    agentData: any,
    environment: EnvironmentType,
) =>
    ({
        type: DATA_ACTIONS.AttributeListing,
        status: STATUS.Requested,
        listingData,
        agentData,
        environment,
    } as const);
export const attributeListingSucceeded = () =>
    ({
        type: DATA_ACTIONS.AttributeListing,
        status: STATUS.Succeeded,
    } as const);
export const attributeListingFailed = (error: any) =>
    ({
        type: DATA_ACTIONS.AttributeListing,
        status: STATUS.Failed,
        error,
    } as const);

export type AttributeListing =
    | ReturnType<typeof attributeListingRequested>
    | ReturnType<typeof attributeListingSucceeded>
    | ReturnType<typeof attributeListingFailed>;

// CONNECT LISTING =====================================
export const connectListingRequested = (
    agentListingData: {
        agentsData: Array<{
            agent: {
                agentMlsId: string;
                mlsName: string;
            };
            canApprove: boolean;
            notificationSettings: Array<string>;
        }>;
        consumersData?: Array<{
            consumer: UserOnAgentListing;
            canApprove: boolean;
            notificationSettings: Array<any>;
        }>;
        noticeRequired: number;
        allowOverlap: boolean;
        approvalSettings: {
            approvalType: ApprovalType;
            unverified: { allowed: boolean; hiddenId: string | undefined | null };
        };
        availability: { recurring: any };
        lockboxLocation: string;
        lockboxType: LockBoxType;
        lockCombo: string;
        maxDuration: number;
        showingInstructions: string;
        type: ListingScheduleType;
        listingId: BSON.ObjectId;
    },
    environment: EnvironmentType,
) =>
    ({
        type: DATA_ACTIONS.ConnectListing,
        status: STATUS.Requested,
        agentListingData,
        environment,
    } as const);
export const connectListingSucceeded = () =>
    ({
        type: DATA_ACTIONS.ConnectListing,
        status: STATUS.Succeeded,
    } as const);
export const connectListingFailed = (error: any) =>
    ({
        type: DATA_ACTIONS.ConnectListing,
        status: STATUS.Failed,
        error,
    } as const);

export type ConnectListing =
    | ReturnType<typeof connectListingRequested>
    | ReturnType<typeof connectListingSucceeded>
    | ReturnType<typeof connectListingFailed>;

// CLEAR SEARCHES ===================================================
export const clearSearchResults = () =>
    ({
        type: DATA_ACTIONS.ClearSearchResults,
        status: STATUS.Requested,
    } as const);
export type ClearSearches = ReturnType<typeof clearSearchResults>;

//===================================================================

export type DataActions =
    | CreateLoggedAgentAction
    | SearchAgentCrossEnv
    | DeleteAgent
    | ClearSearches
    | SearchListingCrossEnv
    | AttributeListing
    | ConnectListing;
