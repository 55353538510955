import { getSelectedAgent } from './selectors';
import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import * as Actions from './actions';
import { BSON, Stitch, StitchUser } from 'mongodb-stitch-browser-sdk';
import { callStitchFunction, findRecord, parseStitchServiceError } from '../../../store/api/sagas';
import { AGENTS, STATUS, BROKERAGES } from '../../../store/api/constants';

export function* getAgentSubscription({
    agentId,
}: ReturnType<typeof Actions.getAgentSubscriptionRequested>) {
    try {
        const stitch = yield call(callStitchFunction, 'getAgentSubscription', agentId);
        yield put(Actions.getAgentSubscriptionSucceded(stitch));
    } catch (error) {
        yield put(Actions.getAgentSubscriptionFailed(error));
    }
}

export function* grantFreetrial({ agentId }: ReturnType<typeof Actions.grantFreetrialRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const trialstatus = yield call(
            callStitchFunction,
            client,
            'manageFreeTrialAgentSubscription',
            agentId,
            'add',
        );
        if (trialstatus) {
            yield put(Actions.grantFreetrialSucceded(trialstatus));
        } else {
            yield put(Actions.grantFreetrialFailed(trialstatus));
        }
    } catch (error) {}
}

export function* removeFreeTrial({ agentId }: ReturnType<typeof Actions.grantFreetrialRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const trialstatus = yield call(
            callStitchFunction,
            client,
            'manageFreeTrialAgentSubscription',
            agentId,
            'remove',
        );
        if (trialstatus) {
            yield put(Actions.grantFreetrialSucceded(trialstatus));
        } else {
            yield put(Actions.grantFreetrialFailed(trialstatus));
        }
    } catch (error) {}
}

export function* fetchAllAgents({
    page,
    searchParam,
}: ReturnType<typeof Actions.fetchAllAgentsRequested>) {
    const client = Stitch.defaultAppClient;
    let allAgents;
    try {
        allAgents = yield call(
            callStitchFunction,
            client,
            'fetchAllAgents',
            page, // 1st page
            50,
            searchParam,
        );

        if (allAgents) {
            yield put(Actions.fetchAllAgentsSucceded(allAgents));
        } else {
            throw new Error('Failed to fetch agents');
        }
    } catch (error) {
        // window.alert('Agent fetch failed');
        yield put(Actions.fetchAllAgentsFailed(error));
    }
}

export function* fetchAgentBrokerage({}: ReturnType<typeof Actions.fetchAgentBrokerageRequested>) {
    try {
        const agent = yield select(getSelectedAgent);
        if (agent) {
            const brokerageId = agent?.markets[0]?.brokerageId;
            const brokerage = yield call(findRecord, BROKERAGES, {
                'markets.brokerageId': brokerageId,
            });

            yield put(Actions.fetchAgentBrokerageSucceded(brokerage));
        } else {
            throw new Error('Failed to fetch agents brokerage');
        }
    } catch (error) {
        yield put(Actions.fetchAgentBrokerageFailed(error));
    }
}

export function* getAgentProfileFromStich({
    agentId,
    marketName,
}: ReturnType<typeof Actions.fetchSelectedAgentProfileRequested>) {
    try {
        const agent = yield call(findRecord, AGENTS, {
            markets: { $elemMatch: { agentMlsId: agentId, marketName: marketName } },
        });
        if (agent) {
            yield put(Actions.fetchSelectedAgentProfileSucceeded(agent));
        } else {
            yield put(Actions.fetchSelectedAgentProfileFailed('Agent Not Found'));
        }
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(Actions.fetchSelectedAgentProfileFailed(err));
    }
}

export function* sagaSearch() {
    yield all([
        takeLatest((actions: any) => {
            return (
                actions.type === Actions.AGENT_ACTIONS.fetchAgentBrokerage &&
                actions.status === STATUS.Requested
            );
        }, fetchAgentBrokerage),
        takeLatest((action: any) => {
            return (
                action.type === Actions.AGENT_ACTIONS.GetAgentSubscription &&
                action.status === STATUS.Requested
            );
        }, getAgentSubscription),
        takeLatest((action: any) => {
            return (
                action.type === Actions.AGENT_ACTIONS.GrantFreeTrial &&
                action.status === STATUS.Requested
            );
        }, grantFreetrial),
        takeLatest((action: any) => {
            return (
                action.type === Actions.AGENT_ACTIONS.RemoveFreeTrial &&
                action.status === STATUS.Requested
            );
        }, removeFreeTrial),
        takeLatest((action: any) => {
            return (
                action.type === Actions.AGENT_ACTIONS.FetchAllAgents &&
                action.status === STATUS.Requested
            );
        }, fetchAllAgents),
        takeLatest((action: any) => {
            return (
                action.type === Actions.AGENT_ACTIONS.FetchAgentProfile &&
                action.status === STATUS.Requested
            );
        }, getAgentProfileFromStich),
    ]);
}
