import { LoadingIcon } from 'asset-lib/src/assets/gif';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { Button } from '../../../../../../components';
import {
    agentSearchCrossEnvRequested,
    createLoggedAgentRequested,
    deleteAgentRequested,
} from '../../../actions';
import { getDataError, getDataLoading, getFoundAgents } from '../../../selectors';
import { AgentCard } from '../../Utilities/AgentCard';

import styles from './index.module.css';

const mapDispatchToProps = {
    createLoggedAgent: createLoggedAgentRequested,
    agentSearchCrossEnv: agentSearchCrossEnvRequested,
    deleteAgent: deleteAgentRequested,
};
const mapStateToProps = (state: any) => ({
    dataLoading: getDataLoading(state),
    dataErrors: getDataError(state),
    foundAgents: getFoundAgents(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type DeleteAgentProps = {
    environment: 'dev' | 'staging' | 'production';
    createLoggedAgent: Function;
    agentSearchCrossEnv: Function;
    deleteAgent: Function;
    foundAgents: Array<any>;
    dataLoading: boolean;
    dataErrors: any;
};

const DeleteAgent = (props: DeleteAgentProps) => {
    const { environment, dataLoading, dataErrors, agentSearchCrossEnv, foundAgents, deleteAgent } =
        props;

    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [deletedAgent, setDeletedAgent] = useState<any>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [selectedAgent, setSelectedAgent] = useState<any>(null);
    const [searchType, setSearchType] = useState<string>('Internal');

    useEffect(() => {
        if (searchText.length) {
            agentSearchCrossEnv(searchText, searchType, environment);
        }
    }, [searchText, searchType, environment, showSuccess]);

    useEffect(() => {
        if (
            !dataLoading &&
            deletedAgent !== null &&
            (dataErrors === undefined || dataErrors === null)
        ) {
            setShowSuccess(true);
        }
    }, [dataLoading, deletedAgent, dataErrors]);

    return (
        <div className={styles.root}>
            {/* Success Message */}
            {showSuccess && (
                <div className={styles.successText1}>
                    {deletedAgent.firstName} was deleted from {deletedAgent?.environment}
                </div>
            )}
            {/* Error messaging */}
            {dataErrors && (
                <div className={styles.errorText1}>
                    An error has occurred:{' '}
                    {dataErrors.errorCode || dataErrors.error || dataErrors.message}
                </div>
            )}
            {/* Link to Error if provided */}
            {dataErrors?.link && (
                <div className={styles.errorText1}>
                    {`Give this link to a dev: ${dataErrors.link}`}
                </div>
            )}
            {/* Gimme that personal space */}
            <div className={styles.spacer} />
            {/* Internal External only radio button */}
            <div
                onChange={(event) => {
                    const value: string = (event.target as HTMLInputElement).value;
                    setSearchType(value);
                }}
                className={styles.radioContainer}
            >
                <label>
                    <input
                        type="radio"
                        value="Internal"
                        name="Internal"
                        checked={searchType === 'Internal'}
                    />
                    Internal
                </label>
                <label>
                    <input
                        type="radio"
                        value="External"
                        name="External"
                        checked={searchType === 'External'}
                    />
                    External
                </label>
                <label>
                    <input type="radio" value="both" name="both" checked={searchType === 'both'} />
                    Both
                </label>
                <label>
                    <input type="radio" value="any" name="any" checked={searchType === 'any'} />
                    Non-testing (Careful!)
                </label>
            </div>
            {/* Gotta have that personal space */}
            <div className={styles.spacer} />
            {/* Search for agent in the correct environment */}
            <div>Who do you want to beanis? </div>
            <input value={searchText} onChange={(event) => setSearchText(event.target.value)} />
            <div>
                {dataLoading ? (
                    <img src={LoadingIcon} height={150} />
                ) : (
                    <div>
                        {/* Selected Agent Modal */}
                        {selectedAgent && (
                            <div className={styles.selectedModalRoot}>
                                <div className={styles.selectedModalHeader}>
                                    Are you sure you want to delete this agent from {environment}?
                                </div>
                                <Row>
                                    <Col className={styles.modalText}>
                                        <div>First Name: {selectedAgent.firstName}</div>
                                        <div>Last Name: {selectedAgent.lastName}</div>
                                        <div>Email: {selectedAgent.email}</div>
                                        <div>Phone: {selectedAgent.phoneNumber}</div>
                                    </Col>
                                    <Col className={styles.modalText}>
                                        <div>
                                            {selectedAgent.markets.map((market, index) => {
                                                return `MLSID${index + 1}: ${
                                                    market.agentMlsId
                                                } MLS${index + 1}: ${market.mlsName} `;
                                            })}
                                        </div>
                                        <div> {`Status:  ${selectedAgent.status}`}</div>
                                        <div>{`Last Session: ${
                                            selectedAgent.lastSession?.toString() || 'None'
                                        }`}</div>
                                    </Col>
                                </Row>
                                <div className={styles.modalButtonContainer}>
                                    <Button
                                        buttonText={'Confirm'}
                                        className={styles.greenButton}
                                        onClick={() => {
                                            deleteAgent(selectedAgent._id, environment);
                                            setDeletedAgent({ ...selectedAgent, environment });
                                            setSelectedAgent(null);
                                        }}
                                    />
                                    <Button
                                        buttonText={'Cancel'}
                                        className={styles.redButton}
                                        onClick={() => setSelectedAgent(null)}
                                    />
                                </div>
                            </div>
                        )}
                        {/* List of agents found in the search */}
                        {foundAgents.map((agent, index) => {
                            return (
                                <AgentCard
                                    agent={agent}
                                    index={index}
                                    setSelectedAgent={setSelectedAgent}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default connector(DeleteAgent);
