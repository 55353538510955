import { STATUS } from '../../../store/api/constants';
import { BROKERAGE_ACTIONS } from './actions';

export const initialState = {
    allBrokerages: [],
    loading: false,
    error: null,
    success: null,
    currentPage: 1,

    loadingActivateBSuite: false,
    errorActivateBSuite: null,

    loadingSelectedBrokerage: false,
    selectedBrokerage: {},
    fetchSelectedBrokerageError: '',

    loadingCreateBrokerageUser: false,
    createBrokerageUserError: '',
    createBrokerageUserSuccess: false,

    loadingBrokerageUsers: false,
    selectedBrokerageUsers: {},
    fetchBrokerageUsersError: '',

    loadingBrokerageAgents: false,
    selectedBrokerageAgents: {},
    fetchBrokerageAgentsError: '',

    loadingActivatePlus: false,
    errorActivatePlus: null,

    loadingDeleteUser: false,
    deleteUserError: '',

    loadingUpdateUser: false,
    updateUserError: '',
};

export type Brokerage = typeof initialState;

export function reducerBrokerages(state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case BROKERAGE_ACTIONS.updateBrokerageUser:
            switch (status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loadingUpdateUser: true,
                        updateUserError: '',
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        loadingUpdateUser: false,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        loadingUpdateUser: false,
                        updateUserError: action.error,
                    };
            }
        case BROKERAGE_ACTIONS.activateBrokerageSuite:
            switch (status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loadingActivateBSuite: true,
                        errorActivateBSuite: null,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        loadingActivateBSuite: false,
                        errorActivateBSuite: false,
                        selectedBrokerage: action.response,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        loadingActivateBSuite: false,
                        errorActivateBSuite: action.error,
                    };
            }

        case BROKERAGE_ACTIONS.activateShowinglyPlus: {
            switch (status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loadingActivatePlus: true,
                        errorActivatePlus: null,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        loadingActivatePlus: false,
                        errorActivatePlus: false,
                        selectedBrokerage: action.result,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        loadingActivatePlus: false,
                        errorActivatePlus: action.error,
                    };
            }
        }

        case BROKERAGE_ACTIONS.clearActivateBSuiteStates: {
            return {
                ...state,
                loadingActivateBSuite: false,
                errorActivateBSuite: null,
            };
        }

        case BROKERAGE_ACTIONS.clearActivatePlusStates: {
            return {
                ...state,
                loadingActivatePlus: false,
                errorActivatePlus: null,
            };
        }

        case BROKERAGE_ACTIONS.clearCreateUserStates: {
            return {
                ...state,
                loadingCreateBrokerageUser: false,
                createBrokerageUserError: '',
                createBrokerageUserSuccess: false,
            };
        }

        case BROKERAGE_ACTIONS.createBrokerageUser: {
            switch (status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loadingCreateBrokerageUser: true,
                        createBrokerageUserError: '',
                        createBrokerageUserSuccess: false,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        loadingCreateBrokerageUser: false,
                        createBrokerageUserSuccess: action.user,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        loadingCreateBrokerageUser: false,
                        createBrokerageUserError: action.error,
                    };
            }
        }

        case BROKERAGE_ACTIONS.getAllBrokerageUsers: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingBrokerageUsers: true,
                        fetchBrokerageUsersError: null,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loadingBrokerageUsers: false,
                        selectedBrokerageUsers: action.brokerageUsers,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loadingBrokerageUsers: false,
                        fetchBrokerageUsersError: action.error,
                    };
                }
                default: {
                    return state;
                }
            }
        }

        case BROKERAGE_ACTIONS.getAllBrokerageAgents: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingBrokerageAgents: true,
                        fetchBrokerageAgentsError: null,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loadingBrokerageAgents: false,
                        selectedBrokerageAgents: action.brokerageAgents,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loadingBrokerageAgents: false,
                        fetchBrokerageAgentsError: action.error,
                    };
                }
                default:
                    return state;
            }
        }

        case BROKERAGE_ACTIONS.getAllBrokeragesRequested: {
            switch (status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loading: true,
                    };
                case STATUS.Succeeded:
                    const { brokerages, totalPages } = action;
                    return {
                        ...state,
                        loading: false,
                        allBrokerages: brokerages,
                        totalPages: totalPages,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        loading: false,
                        error: action.error,
                    };
            }
        }
        case BROKERAGE_ACTIONS.setBrokeragePage: {
            const { page } = action;
            return {
                ...state,
                currentPage: page,
            };
        }
        case BROKERAGE_ACTIONS.setSelectedBrokerage: {
            const { brokerage } = action;
            return {
                ...state,
                selectedBrokerage: brokerage,
            };
        }
        case BROKERAGE_ACTIONS.fetchSelectedBrokerage: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingSelectedBrokerage: true,
                        fetchSelectedBrokerageError: null,
                    };
                }
                case STATUS.Succeeded: {
                    const { brokerage } = action;
                    return {
                        ...state,
                        loadingSelectedBrokerage: false,
                        selectedBrokerage: brokerage,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loadingSelectedBrokerage: false,
                        error: action.error,
                    };
                }
            }
        }
        case BROKERAGE_ACTIONS.fetchBrokerageUsers: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingBrokerageUsers: true,
                        fetchBrokerageUsersError: null,
                    };
                }
                case STATUS.Succeeded: {
                    const { brokerageUsers } = action;
                    return {
                        ...state,
                        loadingBrokerageUsers: false,
                        selectedBrokerageUsers: brokerageUsers,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        loadingBrokerageUsers: false,
                        fetchBrokerageUsersError: error,
                    };
                }
            }
        }

        case BROKERAGE_ACTIONS.fetchBrokerageAgents: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingBrokerageAgents: true,
                        fetchBrokerageAgentsError: null,
                    };
                }
                case STATUS.Succeeded: {
                    const { brokerageAgents } = action;
                    return {
                        ...state,
                        loadingBrokerageAgents: false,
                        selectedBrokerageAgents: brokerageAgents,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loadingBrokerageAgents: false,
                        fetchBrokerageAgentsError: action.error,
                    };
                }
            }
        }

        case BROKERAGE_ACTIONS.DeleteUser: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingDeleteUser: true,
                        deleteUserError: '',
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loadingDeleteUser: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        loadingDeleteUser: false,
                        deleteUserError: error,
                    };
                }
            }
        }

        default:
            return state;
    }
}
