import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import styles from './index.module.css';
import { RandomLoader } from '../../../components';
import { Loader, SearchBar, PropertyListingCard } from 'web-lib';
import { useHistory } from 'react-router-dom';
import { getAllBrokeragesRequested, setSelectedBrokerage } from './actions';
import {
    getAllBrokerages,
    getAllBrokeragesLoading,
    getTotalCount,
    getNumberOfPages,
} from './selectors';
import ColoredButton from '../../../components/ColoredButton';
import NewTableHeader from '../../../components/NewTableHeader';
import NewBrokerageCard from '../../../components/NewBrokerageCard';
import Paginator from '../../../components/Paginator';
import { prettifyWords } from '../../../utils/prettifyWords';

const mapDispatchToProps = {
    getAllBrokeragesRequested: getAllBrokeragesRequested,
    setSelectedBrokerage: setSelectedBrokerage,
};
const mapStateToProps = (state: any) => ({
    brokerages: getAllBrokerages(state),
    loading: getAllBrokeragesLoading(state),
    getTotalCount: getTotalCount(state),
    totalPages: getNumberOfPages(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const BrokeragesPage = ({
    brokerages,
    loading,
    getAllBrokeragesRequested,
    getTotalCount,
    setSelectedBrokerage,
    totalPages,
}: any) => {
    const history = useHistory();
    const [searchText, setSearchText] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>();
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        getAllBrokeragesRequested(page, searchText);
    }, [page]);

    useEffect(() => {
        getAllBrokeragesRequested(1, searchText);
    }, [searchText]);

    function showBrokerageCard() {
        if (brokerages && brokerages.length) {
            return (
                <div>
                    {brokerages.map((brokerage: any, index) => {
                        function onClickBrokerage() {
                            setSelectedBrokerage(brokerage);
                            history.push(
                                `brokeragesPage/brokerage/${brokerage.markets[0].brokerageId}`,
                            );
                        }
                        return (
                            <NewBrokerageCard
                                key={index}
                                name={prettifyWords(brokerage.name)}
                                brokerageId={brokerage.markets[0].brokerageId}
                                agentCount={brokerage.agentCount}
                                status={brokerage.mlsStatus}
                                market={brokerage.markets[0].market}
                                onClickViewBrokerage={onClickBrokerage}
                            />
                        );
                    })}
                </div>
            );
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <h3 className={styles.h3Title}>Brokerages</h3>
                <div className={styles.innerContainer}>
                    <SearchBar
                        inputStyles={styles.searchBarInputStyles}
                        onSubmit={(text: string = '') => {
                            setSearchText(text);
                        }}
                        disabled={disabled}
                        placeholderText={'Search Brokerages'}
                    />
                    <ColoredButton text="Filter" onClick={() => {}}></ColoredButton>
                </div>

                {getTotalCount > 0 ? (
                    <div className={styles.pageCounter}>{getTotalCount} Brokerages</div>
                ) : (
                    ''
                )}
            </div>
            <NewTableHeader typeOfHeader={'brokerages'}></NewTableHeader>
            {loading == true ? (
                <div className={styles.loaderContainer}>
                    <RandomLoader />
                </div>
            ) : (
                showBrokerageCard()
            )}
            {totalPages > 1 ? (
                <Paginator
                    totalPages={totalPages}
                    currentPage={page}
                    onPrev={() => setPage(page - 1)}
                    onNext={() => setPage(page + 1)}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default connector(BrokeragesPage);
