import { createSelector } from 'reselect';
import { Development } from './reducers';

export const developmentState = (state: { development: Development }) => state.development || {};

export const getTodaysErrors = createSelector(
    developmentState,
    (development: any) => development.todaysErrors || {},
);

export const getSelectedError = createSelector(
    developmentState,
    (development: any) => development.selectedFunctionError || {},
);

export const getLineGraphData = createSelector(
    developmentState,
    (development: any) => development.errorGraphData || [],
);
export const getObjectIds = createSelector(
    developmentState,
    (development: any) => development.objectIds || [],
);

export const getErrorMarks = createSelector(
    developmentState,
    (development: any) => development.errorMarks || [],
);
