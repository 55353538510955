import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import AuthenticatedApp from './authenticated-app';
import { WebAdminState } from './store/reducer';
import UnauthenticatedApp from './unauthenticated-app';
import { getIsUserAuthenticated, getAuthLoading } from './domains/auth/selectors';
import { RandomLoader } from './components';
import styles from './App.module.css';
import SnackBar from './components/SnackBar';

const mapStateToProps = (state: WebAdminState) => ({
    authenticated: getIsUserAuthenticated(state),
    loading: getAuthLoading(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

function App({ authenticated, loading }: ReduxProps) {
    if (!authenticated && loading) {
        return (
            <div className={styles.loader}>
                <RandomLoader />
                <p className={styles.friendlyMsg}>Fetching data...</p>
            </div>
        );
    }

    if (authenticated && authenticated === true) {
        return <AuthenticatedApp />;
    } else {
        return <UnauthenticatedApp />;
    }
}

export const ConnectedApp = connector(App);
