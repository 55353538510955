import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import { Button, ButtonTabBar } from '../../../components';

import Pages from './Pages';
const ManageAdmins = () => {
    const [page, setPage] = useState('1');
    return (
        <div className={styles.root}>
            <ButtonTabBar
                buttonNames={[{ name: 'Manage Admins', page: '1' }]}
                setPage={setPage}
                currPage={page}
            />
            <Pages page={page} />
        </div>
    );
};

export default ManageAdmins;
