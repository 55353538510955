/**
 * Takes the phone number format from a text input and returns the number in
 * the format `+1 (012) 345-6789`.
 * @param {string} phoneNumber string of numbers or formated numbers.
 * @returns {string}
 */
export function maskPhoneNumber(phoneNumber: string | number): string {
    const cleaned = ('' + phoneNumber).replace('+1', '').replace(/\D/g, '');

    const match1 = cleaned.slice(0, 3);
    const match2 = cleaned.slice(3, 6);
    const match3 = cleaned.slice(6, 10);

    if (cleaned.length) {
        if (match3.length) {
            return `+1 (${match1}) ${match2}-${match3}`;
        } else if (match2.length) {
            return `+1 (${match1}) ${match2}`;
        } else {
            return `+1 ${match1}`;
        }
    }
    return '';
}

export function unmaskPhoneNumber(maskedPhoneNumber: string | number): string {
    return maskedPhoneNumber.toString().replace(/[()\s-]/g, '');
}
