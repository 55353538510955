import React, { useEffect, useRef, useState } from 'react';
import ColoredButton from '../ColoredButton';
import ConfirmActionModal from '../ConfirmActionModal';
import styles from './index.module.css';
import {
    activateShowinglyPlusRequested,
    activateBrokerageSuiteRequested,
    getAllBrokerageUsersRequested,
    clearActivateBSuiteStatesRequested,
    clearActivatePlusStatesRequested,
} from '../../domains/dash/BrokeragesPage/actions';
import {
    getLoadingActivatePlus,
    getErrorActivatePlus,
    getLoadingActivateBSuite,
    getErrorActivateBSuite,
    getCreateBrokerageUserError,
    getCreateBrokerageUserLoading,
    getCreateBrokerageUserSuccess,
} from '../../domains/dash/BrokeragesPage/selectors';
import { connect } from 'react-redux';
import UserForm from '../UserForm';

const mapDispatchToProps = {
    clearActivateBSuiteStatesRequested,
    clearActivatePlusStatesRequested,
    getAllBrokerageUsersRequested,
    activateShowinglyPlusRequested,
    activateBrokerageSuiteRequested,
};
const mapStateToProps = (state: any) => ({
    loadingActivatePlus: getLoadingActivatePlus(state),
    errorActivatePlus: getErrorActivatePlus(state),

    loadingActivateBSuite: getLoadingActivateBSuite(state),
    errorActivateBSuite: getErrorActivateBSuite(state),

    loadingCreateUser: getCreateBrokerageUserLoading(state),
    errorCreateUser: getCreateBrokerageUserError(state),
    successCreateUser: getCreateBrokerageUserSuccess(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const BrokerageManagePlanTab = ({
    Brokerage,
    getAllBrokerageUsersRequested,
    activateBrokerageSuiteRequested,
    activateShowinglyPlusRequested,
    /*.. ACTIVATE BROKERAGE SUITE ..*/
    loadingActivateBSuite,
    errorActivateBSuite,
    clearActivateBSuiteStatesRequested,
    /*.. ACTIVATE SHOWINGLY PLUS ..*/
    loadingActivatePlus,
    errorActivatePlus,
    clearActivatePlusStatesRequested,
    /*.. CREATE BROKERAGE USER ..*/
    loadingCreateUser,
    errorCreateUser,
    successCreateUser,

    userCount,
}) => {
    const [renderModal, setRenderModal] = useState<boolean>(false);
    const [renderBSuiteModal, setRenderBSuiteModal] = useState<boolean>(false);
    const [showForm, setshowForm] = useState<boolean>(false);

    /*.. ACTIVATE SHOWINGLY PLUS ..*/

    useEffect(() => {
        if (errorActivatePlus == false) {
            window.alert('Plan changed succesfully');
        } else if (errorActivatePlus) {
            window.alert(
                'User creation failed, ' +
                    errorActivatePlus.message?.split('"message":"')[1].split('"')[0],
            );
        }
        clearActivatePlusStatesRequested();
        setRenderModal(false);
    }, [errorActivatePlus]);

    /*.. ACTIVATE BROKERAGE SUITE ..*/

    useEffect(() => {
        if (errorActivateBSuite == false) {
            window.alert('Suite plan changed succesfully');
        } else if (errorActivateBSuite) {
            window.alert('Suite plan failed to change, ' + errorActivateBSuite);
        }
        clearActivateBSuiteStatesRequested();
        setRenderBSuiteModal(false);
    }, [errorActivateBSuite]);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.informationContainer}>
                <div className={styles.information}>
                    <div className={styles.subtitle}>
                        <h3>Brokerage Suite Plan </h3>
                    </div>
                    <div>
                        {' '}
                        <strong>Status: </strong> {Brokerage.brokerageSuite ? 'Active' : 'Inactive'}
                    </div>
                    {Brokerage.brokerageSuite ? (
                        <ColoredButton
                            color={'red'}
                            text={'Disable Brokerage Suite'}
                            onClick={() => setRenderBSuiteModal(true)}
                        />
                    ) : userCount < 1 ? (
                        <ColoredButton
                            text={'Activate Brokerage Suit'}
                            onClick={() => setshowForm(true)}
                        />
                    ) : (
                        <ColoredButton
                            colorsReversed={false}
                            text={'Activate Brokerage Suite'}
                            onClick={() => setRenderBSuiteModal(true)}
                        />
                    )}
                </div>

                <div className={styles.information}>
                    <div className={styles.subtitle}>
                        {' '}
                        <h3> Showingly + for agents </h3>
                    </div>
                    <div>
                        {' '}
                        <strong>Status: </strong> {Brokerage.plus ? 'Active' : 'Inactive'}{' '}
                    </div>
                    {Brokerage.plus ? (
                        <ColoredButton
                            text={'Disable Showingly + for agents'}
                            onClick={() => setRenderModal(!renderModal)}
                            color={'red'}
                        />
                    ) : (
                        <ColoredButton
                            text={'Activate Showingly +'}
                            onClick={() => setRenderModal(!renderModal)}
                        />
                    )}
                </div>
            </div>
            {Brokerage.plus ? (
                <ConfirmActionModal
                    title={'Disable Subscription'}
                    description={
                        ' Showingly + for agents will be disabled for all agents in this brokerage. Are you sure you want to continue?'
                    }
                    onConfirm={() => {
                        activateShowinglyPlusRequested(
                            Brokerage.markets[0].brokerageId,
                            Brokerage.markets[0].mlsName,
                            'remove',
                        );
                    }}
                    onCancel={() => setRenderModal(!renderModal)}
                    confirmButtonText={'Confirm'}
                    cancelButtonText={'Cancel'}
                    show={renderModal}
                    loading={loadingActivatePlus}
                />
            ) : (
                <ConfirmActionModal
                    title={'Confirm Subscription'}
                    description={
                        ' Showingly + for agents will be activated for all agents in this brokerage. This will allow agents to use Showingly + features.'
                    }
                    onConfirm={() =>
                        activateShowinglyPlusRequested(
                            Brokerage.markets[0].brokerageId,
                            Brokerage.markets[0].mlsName,
                            'add',
                        )
                    }
                    onCancel={() => setRenderModal(!renderModal)}
                    confirmButtonText={'Confirm'}
                    cancelButtonText={'Cancel'}
                    show={renderModal}
                    loading={loadingActivatePlus}
                />
            )}
            {Brokerage.brokerageSuite ? (
                <ConfirmActionModal
                    title={'Disable Brokerage Suite'}
                    description={
                        'Brokerage Suite will be disabled for all users. Are you sure you want to continue?'
                    }
                    onConfirm={() => {
                        activateBrokerageSuiteRequested(Brokerage._id, 'remove');
                    }}
                    onCancel={() => setRenderBSuiteModal(!renderBSuiteModal)}
                    confirmButtonText={'Confirm'}
                    cancelButtonText={'Cancel'}
                    show={renderBSuiteModal}
                    loading={loadingActivateBSuite}
                />
            ) : (
                <ConfirmActionModal
                    title={'Enable Brokerage Suite'}
                    description={
                        'Brokerage Suite will be enabled for all users. Are you sure you want to continue?'
                    }
                    onConfirm={() => {
                        activateBrokerageSuiteRequested(Brokerage._id, 'add');
                    }}
                    onCancel={() => setRenderBSuiteModal(!renderBSuiteModal)}
                    confirmButtonText={'Confirm'}
                    cancelButtonText={'Cancel'}
                    show={renderBSuiteModal}
                    loading={loadingActivateBSuite}
                />
            )}
            <UserForm
                typeOfForm={'Create'}
                show={showForm}
                closeForm={() => setshowForm(false)}
                brokerageId={Brokerage?._id}
                loading={loadingCreateUser}
                callBack={() => {
                    window.alert('User created succesfully');
                    setshowForm(false);
                    activateBrokerageSuiteRequested(Brokerage._id, 'add');
                    getAllBrokerageUsersRequested(Brokerage?._id, 1);
                }}
            />
        </div>
    );
};
export default connector(BrokerageManagePlanTab);
