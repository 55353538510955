import React from 'react';
import { Col, Row } from 'react-grid-system';

import { Dropdown } from '../../../../components';
import { AccountOperationType, EnvironmentType } from '../constants';
import CreateAgent from './Accounts/CreateAgent';
import DeleteAgent from './Accounts/DeleteAgent';

import styles from './index.module.css';
import AttributeListingPage from './Listings/Attribute';
import ConnectListingPage from './Listings/Connect';

type PagesProps = {
    page: string;
    accountOperation: AccountOperationType;
    setAccountOperation: Function;
    environment: EnvironmentType;
    setEnvironment: Function;
    allowedEnvironments: Array<EnvironmentType>;
};

const Pages = (props: PagesProps) => {
    const {
        page,
        setAccountOperation,
        accountOperation,
        environment,
        setEnvironment,
        allowedEnvironments,
    } = props;

    const EnvironmentPicker = () => {
        return (
            <Col>
                <div>Environment</div>
                <div className={styles.spacer} />
                <Dropdown
                    options={allowedEnvironments}
                    handleClick={(option) => {
                        setEnvironment(option);
                    }}
                    className={''}
                    selectedOption={environment}
                />
            </Col>
        );
    };

    switch (page) {
        case '1':
            return (
                <div className={styles.root}>
                    <Col>
                        <Row>
                            <Col>
                                <div>Create or Delete?</div>
                                <div className={styles.spacer} />
                                <Dropdown
                                    options={['Create', 'Delete']}
                                    handleClick={(option) => {
                                        setAccountOperation(option);
                                    }}
                                    className={''}
                                    selectedOption={accountOperation}
                                />
                            </Col>
                            <EnvironmentPicker />
                        </Row>
                        <Col>
                            {accountOperation === 'Create' ? (
                                <CreateAgent environment={environment} />
                            ) : (
                                <DeleteAgent environment={environment} />
                            )}
                        </Col>
                    </Col>
                </div>
            );
        case '2':
            return (
                <div className={styles.root}>
                    <EnvironmentPicker />
                    <div style={{ marginBottom: 25 }} />
                    <AttributeListingPage environment={environment} />
                </div>
            );
        case '3':
            return (
                <div className={styles.root}>
                    <EnvironmentPicker />
                    <div style={{ marginBottom: 25 }} />
                    <ConnectListingPage environment={environment} />
                </div>
            );
        default:
            return <>{`How did you even get here?`}</>;
    }
};

export default Pages;
