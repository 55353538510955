import React from 'react';
import { Row } from 'react-grid-system';
import styles from './index.module.css';

export const AgentCard = (props: { agent: any; index: number; setSelectedAgent: Function }) => {
    const { agent, index, setSelectedAgent } = props;
    return (
        <div className={styles.agentCardRoot} onClick={() => setSelectedAgent(agent)} key={index}>
            <Row>
                {agent.firstName} {agent.lastName}
            </Row>
            <Row>
                {agent.markets?.map((market) => {
                    return `AgentMlsId: ${market.agentMlsId} - MLS: ${market.mlsName}   `;
                })}
            </Row>
        </div>
    );
};
