import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';

type parameters = {
    firstName: string;
    lastName: string;
    brokerageName: string;
};
export const ProfileHeader = ({ firstName, lastName, brokerageName }: parameters) => {
    const initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;

    return (
        <div className={styles.height}>
            <div className={styles.gradientHeader} />
            <div className={styles.initialsContainer}>
                <div className={styles.initialsIcon}>{initials}</div>
            </div>
            <div className={styles.nameAndBrokerage}>
                {firstName} {lastName}
                <p>{brokerageName}</p>
            </div>
        </div>
    );
};

export default ProfileHeader;
