import { createSelector } from 'reselect';
import { SearchableDropdown } from 'web-lib';
import { search } from '../sagas';
import { Searches } from './reducers';

export const getSearchState = (state: { searches: Searches }) => state.searches || {};

export const getLoading = createSelector(getSearchState, (searches: any) => searches.loading);
export const getAgents = createSelector(getSearchState, (searches: any) => searches.agents || {});
export const getListings = createSelector(
    getSearchState,
    (searches: any) => searches.listings || {},
);
export const getBrokerages = createSelector(
    getSearchState,
    (searches: any) => searches.brokerages || {},
);
export const getUnavailableDates = createSelector(
    getSearchState,
    (searches: any) => searches.unavailableDates,
);
export const getScheduledShowing = createSelector(
    getSearchState,
    (searches: any) => searches.showingCreated,
);
export const getShowingSuccessfullyCreated = createSelector(
    getSearchState,
    (searches: any) => searches.showingSuccessfullyCreated,
);
export const getSelectedBrokerage = createSelector(
    getSearchState,
    (searches: any) => searches.selectedViewingBrokerage,
);
export const getBrokerageRoster = createSelector(
    getSearchState,
    (searches: any) => searches.brokerageRoster,
);
export const getPasswordReset = createSelector(
    getSearchState,
    (searches: any) => searches.passwordReset,
);
export const getBrokerageAdmins = createSelector(
    getSearchState,
    (searches: any) => searches.brokerageAdmins,
);
export const getAgentLoading = createSelector(
    getSearchState,
    (searches: any) => searches.loadingAgent,
);
export const getSelectedAgent = createSelector(
    getSearchState,
    (searches: any) => searches.selectedViewingAgent,
);

export const getListingLoading = createSelector(
    getSearchState,
    (searches: any) => searches.lisitingLoading,
);
export const getSelectedViewingListing = createSelector(
    getSearchState,
    (searches: any) => searches.selectedViewingListing,
);
export const getFormattedMarkets = createSelector(
    getSearchState,
    (searches: any) => searches.formattedMarkets,
);
export const getAgentListings = createSelector(
    getSearchState,
    (searches: any) => searches.agentListings,
);
export const getListingShowings = createSelector(
    getSearchState,
    (searches: any) => searches.listingShowings,
);

export const getPaymentSuccessful = createSelector(
    getSearchState,
    (searches: any) => searches.paymentSuccessful,
);
export const getAgentListingLoading = createSelector(
    getSearchState,
    (searches: any) => searches.loadingAgentListings,
);

export const getStats = createSelector(getSearchState, (searches: any) => searches.stats);
export const getStatsFilters = createSelector(
    getSearchState,
    (searches: any) => searches.statsFilterButtons,
);
export const getListingAgents = createSelector(
    getSearchState,
    (searches: any) => searches.listingAgents,
);

export const getLoadingListingAgents = createSelector(
    getSearchState,
    (searches: any) => searches.loadingListingAgents,
);

export const getPlusPrices = createSelector(getSearchState, (searches: any) => searches.plusPrices);
