import React, { useEffect, useState } from 'react';
import styles from './index.module.css';

type parameters = {
    typeOfHeader: 'agents' | 'brokerages';
};

export const NewTableHeader = ({ typeOfHeader }: parameters) => {
    return typeOfHeader == 'agents' ? (
        <div className={styles.tableHead}>
            <div className={styles.name}>Name</div>
            <div className={styles.id}>Agent ID</div>
            <div className={styles.status}>Status</div>
            <div className={styles.listings}>Listings</div>
            <div className={styles.avgsold}>Avg. sold price</div>
            <div className={styles.prodVol}>Prod. Volume</div>
            <div className={styles.market}>Market</div>
        </div>
    ) : typeOfHeader == 'brokerages' ? (
        <div className={styles.tableHead}>
            <div className={styles.brokerageName}>Name</div>
            <div className={styles.brokerageId}>Brokerage ID</div>
            <div className={styles.agentCount}>Agent Count</div>
            <div className={styles.brokerageStatus}>Status</div>
            <div className={styles.brokerageMarket}>Market</div>
        </div>
    ) : (
        <div></div>
    );
};

export default NewTableHeader;
