import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';

type parameters = {
    Agent: any;
};
export const AgentInformationTab = ({ Agent }: parameters) => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.subtitle}>
                {' '}
                <h3>Agent Information </h3>
            </div>
            <div className={styles.informationContainer}>
                <div className={styles.information}>
                    {' '}
                    <strong>Name</strong> {Agent.firstName} {Agent.lastName}{' '}
                </div>
                <div className={styles.information}>
                    {' '}
                    <strong>E-mail</strong> {Agent.email}{' '}
                </div>
                <div className={styles.information}>
                    {' '}
                    <strong>Brokerage</strong> {Agent.brokerageName}{' '}
                </div>
                <div className={styles.information}>
                    {' '}
                    <strong>Agent ID</strong> {Agent.markets[0].agentMlsId}{' '}
                </div>
                <div className={styles.information}>
                    {' '}
                    <strong>Phone </strong> {Agent.markets[0].phoneNumber}{' '}
                </div>
                <div className={styles.information}>
                    {' '}
                    <strong>Market</strong> {Agent.markets[0].marketName}{' '}
                </div>
            </div>
            <div className={styles.subtitle}>
                {' '}
                <h3>Payment Method </h3>
            </div>

            <div className={styles.informationContainer}>
                <div className={styles.information}> No payment method added </div>
            </div>
        </div>
    );
};
export default AgentInformationTab;
