import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.css';
import GeneralInfo from '../Common/GeneralInfo';
import ListingCards from './ListingCards';
import { fetchPasswordChangeRequested } from '../actions';
import { ButtonV4 } from 'web-lib';
import { useHistory } from 'react-router-dom';

import {
    getLoading,
    getAgentListings,
    getAgentLoading,
    getFormattedMarkets,
    getPasswordReset,
    getSelectedAgent,
    getAgentListingLoading,
    getStats,
    getStatsFilters,
} from '../selectors';
import {
    formatPhoneNumberForDisplay,
    formatEmailForDisplay,
    timeSince,
} from '../../../../../src/utils';
import MarketsInfo from '../Common/MarketsInfo';
import StatsInfo from '../Common/StatsInfo';
import { RandomLoader } from '../../../../components';

const mapDispatchToProps = {
    fetchPasswordChangeRequested: fetchPasswordChangeRequested,
};

const mapStateToProps = (state: any) => ({
    loading: getLoading(state),
    agentLoading: getAgentLoading(state),
    agent: getSelectedAgent(state),
    agentMarkets: getFormattedMarkets(state),
    agentListings: getAgentListings(state),
    resetPassword: getPasswordReset(state),
    loadingAgentListings: getAgentListingLoading(state),
    agentStats: getStats(state),
    statsFilters: getStatsFilters(state),
});

type AgentViewerProps = {
    agent: any;
    agentLoading: boolean;
    loading: boolean;
    loadingAgentListings: boolean;
    agentMarkets: any;
    agentListings: any[];
    fetchPasswordChangeRequested: any;
    agentStats: any[];
    statsFilters: any[];
};
const connector = connect(mapStateToProps, mapDispatchToProps);
//end Redux

export const AgentViewer = ({
    agent,
    agentLoading,
    agentMarkets,
    loading,
    agentListings,
    loadingAgentListings,
    agentStats,
    statsFilters,
    fetchPasswordChangeRequested,
}: AgentViewerProps) => {
    // used for redirect upon refresh
    const history = useHistory();
    sessionStorage.setItem('redirectUponRefresh', 'true');

    return (
        <div className={styles.root}>
            <div className={styles.row}>
                <div className={styles.generalInfoDiv}>
                    <div>
                        <ButtonV4
                            text={'Password Reset'}
                            className={styles.button}
                            onClick={() => {
                                if (
                                    window.confirm(
                                        "You're about to reset this user's password.\nClick OK to continue",
                                    )
                                ) {
                                    fetchPasswordChangeRequested(agent.stitchUserId);
                                    window.alert('success');
                                } else {
                                    window.alert('Password was not changed');
                                }
                            }}
                        />
                    </div>
                    <div className={styles.genInfo}>
                        <GeneralInfo
                            name={agent?.firstName + ' ' + agent?.lastName}
                            status={agent?.status ? agent?.status : ''}
                            image={
                                agent?.profilePhotoUpload?.uri === undefined
                                    ? ''
                                    : agent?.profilePhotoUpload?.uri
                            }
                            loading={agentLoading}
                            phone={formatPhoneNumberForDisplay(agent?.phoneNumber)}
                            email={formatEmailForDisplay(agent?.email)}
                            hasSubscription={agent?.subscription?.status === 'active'}
                            lastSession={timeSince(agent?.lastSession)}
                        />
                    </div>
                    <div className={styles.mlsInfo}>
                        <MarketsInfo markets={agentMarkets} />
                    </div>
                </div>
                {agentListings && !loadingAgentListings ? (
                    <div className={styles.cards}>
                        <ListingCards listings={agentListings} loading={loadingAgentListings} />
                    </div>
                ) : (
                    <div className={styles.cards}>
                        <RandomLoader />
                    </div>
                )}
            </div>
            {!loading && agentStats.length ? (
                <StatsInfo stats={agentStats} mlsOptions={statsFilters} type={'agent'} />
            ) : (
                <RandomLoader />
            )}
        </div>
    );
};

export default connector(AgentViewer);
