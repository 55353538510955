import { ErrorObject, serializeError } from 'serialize-error';
import { STATUS } from '../../../store/api/constants';
import { DataActions, DATA_ACTIONS } from './actions';

export type DataState = {
    loading: boolean;
    foundAgents: Array<any>;
    foundListings: Array<any>;
    error: ErrorObject | null;
};

export const initialState: DataState = {
    loading: false,
    foundAgents: [],
    foundListings: [],
    error: null,
};

export function reducerData(state = initialState, action: DataActions): DataState {
    if (!Object.values(DATA_ACTIONS).includes(action.type)) {
        return state;
    }
    switch (action.status) {
        case STATUS.Succeeded:
            switch (action.type) {
                case DATA_ACTIONS.SearchAgent:
                    return {
                        ...state,
                        loading: false,
                        error: null,
                        foundAgents: action.foundAgents,
                    };
                case DATA_ACTIONS.SearchListing:
                    return {
                        ...state,
                        loading: false,
                        error: null,
                        foundListings: action.foundListings,
                    };
                default:
                    return {
                        ...state,
                        loading: false,
                        error: null,
                    };
            }
        case STATUS.Requested:
            switch (action.type) {
                case DATA_ACTIONS.ClearSearchResults:
                    return {
                        ...state,
                        foundAgents: [],
                        foundListings: [],
                    };
                default:
                    return {
                        ...state,
                        loading: true,
                        error: null,
                    };
            }

        case STATUS.Failed:
            return {
                ...state,
                loading: false,
                error: serializeError(action.error),
            };
        default:
            return state;
    }
}
