import { ErrorObject, serializeError } from 'serialize-error';
import { STATUS } from '../../store/api/constants';
import { DashActions, DASH_ACTIONS } from './actions';

export type DashState = {
    loading: boolean;
    agents: any[];
    pageViewing: any[];
    markets: any[];
    error: ErrorObject | null;
};

export const initialState: DashState = {
    loading: false,
    agents: [],
    pageViewing: [],
    markets: [],
    error: null,
};

export function reducerDash(state = initialState, action: DashActions): DashState {
    if (!Object.values(DASH_ACTIONS).includes(action.type)) {
        return state;
    }
    switch (action.status) {
        case STATUS.Succeeded:
            switch (action.type) {
                case DASH_ACTIONS.FetchAgents:
                    return {
                        ...state,
                        agents: action.agents,
                    };
                case DASH_ACTIONS.FetchMarkets:
                    return {
                        ...state,
                        markets: action.markets,
                    };
                default:
                    return state;
            }

        case STATUS.Requested:
            return {
                ...state,
                loading: true,
            };
        case STATUS.Failed:
            return {
                ...state,
                loading: false,
                error: serializeError(action.error),
            };
        default:
            return state;
    }
}
