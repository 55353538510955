import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { routerRef } from './utils';

import { ConnectedSideBar } from 'web-lib';
import SearchIcon from './Images/SideBar/searchIcon.svg';
import HouseIcon from './Images/SideBar/houseIcon.svg';
import TopHatIcon from './Images/TopHat.png';
import PhoneIcon from './Images/SideBar/phone.svg';
import SearchIconSelected from './Images/SideBar/searchIconSelected.svg';
import HouseIconSelected from './Images/SideBar/houseIconSelected.svg';
import PhoneIconSelected from './Images/SideBar/phoneSelected.svg';
import CodeIcon from './Images/SideBar/codeIcon.svg';
import CodeIconSelected from './Images/SideBar/codeIconSelected.svg';
import crmIcon from './Images/SideBar/crm.svg';
import crmIconSelected from './Images/SideBar/crmSelected.svg';
import curlyBracketIcon from './Images/SideBar/curlyBrackets.svg';
import curlyBracketIconSelected from './Images/SideBar/curlyBracketsSelected.svg';
import logo from './Images/logo.svg';

import AllSearches from './domains/dash/AllSearches';
import Dashboard from './domains/dash/Dashboard';
import Development from './domains/dash/Development';
import FunctionErrorScreen from './domains/dash/Development/FunctionErrorScreen';
import ManageAdmins from './domains/dash/ManageAdmins';
import Support from './domains/dash/Support';
import BrokerageViewer from './domains/dash/AllSearches/BrokerageViewer';
import AgentViewer from './domains/dash/AllSearches/AgentViewer';
import AgentsPage from './domains/dash/AgentsPage';
import BrokeragesPage from './domains/dash/BrokeragesPage';
import Profile from './domains/dash/Profile';
import ListingViewer from './domains/dash/AllSearches/ListingViewer';
import Data from './domains/dash/Data';

import brokeragesLogo from './Images/Admin2.0/Brokerages.svg';
import brokeragesLogoSelected from './Images/Admin2.0/Blue_Brokerages.svg';

import agentIcon from './Images/Admin2.0/Agents.svg';
import agentIconSelected from './Images/Admin2.0/Blue_Agents.svg';

import styles from './utils/commonStyles.module.css';
import { getAdminAccount, getAllowedEnvironments } from './domains/auth/selectors';
import SnackBar from './components/SnackBar';
import AgentDetails from './domains/dash/AgentsPage/AgentDetails';
import BrokeragesDetails from './domains/dash/BrokeragesPage/BrokeragesDetails';

const mapStateToProps = (state: any) => ({
    account: getAdminAccount(state),
});

const connector = connect(mapStateToProps);

function AuthenticatedApp({ account }: any) {
    const dataPage = account?.adminKeys
        ? [
              {
                  link: '/data',
                  icon: curlyBracketIcon,
                  iconSelected: curlyBracketIconSelected,
                  pageTitle: 'Data',
              },
          ]
        : [];
    const adminPrivilegedPages = account?.isAdmin
        ? [
              {
                  link: '/manageadmins',
                  icon: crmIcon,
                  iconSelected: crmIconSelected,
                  pageTitle: 'Manage Admins',
              },
          ]
        : [];

    const Sidebar = () => {
        let listItems = [
            // {
            //     link: '/dashboard',
            //     icon: HouseIcon,
            //     iconSelected: HouseIconSelected,
            //     pageTitle: 'Dashboard',
            // },
            {
                link: '/agentsPage',
                icon: agentIcon,
                iconSelected: agentIconSelected,
                pageTitle: 'Agents',
            },
            {
                link: '/brokeragesPage',
                icon: brokeragesLogo,
                iconSelected: brokeragesLogoSelected,
                pageTitle: 'Brokerages',
            },
            {
                link: '/allsearches',
                icon: SearchIcon,
                iconSelected: SearchIconSelected,
                pageTitle: 'Search',
            },
            {
                link: '/development',
                icon: CodeIcon,
                iconSelected: CodeIconSelected,
                pageTitle: 'Development',
            },
            {
                link: '/support',
                icon: PhoneIcon,
                iconSelected: PhoneIconSelected,
                pageTitle: 'Support',
            },
            ...dataPage,
            ...adminPrivilegedPages,
        ];

        return (
            <ConnectedSideBar
                ListItems={listItems}
                userPhotoUri={account?.profileImage ? account.profileImage : TopHatIcon}
                userProfileLink={'/profile'}
                iconStyling={styles.icons}
                iconTextStyling={styles.iconText}
                iconTextStylingSelected={styles.iconTextSelected}
                loggedUser={`${account?.firstName}`}
                userPhotoContainerStyling={styles.photoContainerStyling}
                userNameStyling={styles.userNameStyling}
                userNameStylingSelected={styles.userNameStylingSelected}
                userPhotoStyling={styles.userPhotoStyling}
            />
        );
    };

    // used to determine if redirect is necessary
    const redirect = sessionStorage.getItem('redirectUponRefresh');
    console.log('redirect', redirect);
    sessionStorage.removeItem('redirectUponRefresh');
    return (
        <BrowserRouter
            ref={(e) => {
                (routerRef as any).current = e;
            }}
        >
            <SnackBar firstName={account?.firstName} lastName={account?.lastName} />

            <Switch>
                {/* <Route
                    exact
                    path="/auth"
                    render={() => {
                        return <Redirect to="/dashboard" />;
                    }}
                /> */}
                {/* <Route exact path="/dashboard" render={() => <Dashboard />} /> */}
                <Route exact path="/allsearches" render={() => <AllSearches />} />
                <Route exact path="/agentsPage" render={() => <AgentsPage />} />
                <Route exact path="/brokeragesPage" render={() => <BrokeragesPage />} />

                <Route
                    exact
                    path="/brokeragesPage/brokerage/:id"
                    render={() =>
                        redirect !== 'true' ? (
                            <BrokeragesDetails />
                        ) : (
                            <Redirect to="/brokeragesPage" />
                        )
                    }
                />

                <Route
                    exact
                    path="/allsearches/brokerage/:id"
                    render={() =>
                        redirect !== 'true' ? <BrokerageViewer /> : <Redirect to="/allsearches" />
                    }
                />
                <Route
                    exact
                    path="/agentsPage/brokerage/:id"
                    render={() =>
                        redirect !== 'true' ? <BrokerageViewer /> : <Redirect to="/agentsPage" />
                    }
                />

                <Route
                    exact
                    path="/allsearches/agent/:id"
                    render={() =>
                        redirect !== 'true' ? <AgentViewer /> : <Redirect to="/allsearches" />
                    }
                />
                <Route
                    exact
                    path="/agentsPage/agent/:id/:marketName"
                    render={() =>
                        redirect !== 'true' ? <AgentDetails /> : <Redirect to="/agentsPage" />
                    }
                />
                <Route
                    exact
                    path="/allsearches/listing/:id"
                    render={() =>
                        redirect !== 'true' ? <ListingViewer /> : <Redirect to="/allsearches" />
                    }
                />
                <Route
                    exact
                    path="/agentsPage/listing/:id"
                    render={() =>
                        redirect !== 'true' ? <ListingViewer /> : <Redirect to="/agentsPage" />
                    }
                />
                <Route exact path="/development" render={() => <Development />} />
                <Route
                    exact
                    path="/development/errorLogs/:id"
                    render={() => <FunctionErrorScreen />}
                />
                {/* <Route exact path="/sales" render={() => <Sales />} />
                <Route exact path="/marketing" render={() => <Marketing />} /> */}
                <Route exact path="/support" render={() => <Support />} />
                <Route
                    exact
                    path="/data"
                    render={() => (account?.adminKeys ? <Data /> : <Redirect to="/allsearches" />)}
                />
                <Route
                    exact
                    path="/manageadmins"
                    render={() =>
                        account?.isAdmin ? <ManageAdmins /> : <Redirect to="/allsearches" />
                    }
                />
                <Route exact path="/profile" render={() => <Profile />} />

                <Route
                    exact
                    path="*"
                    render={() => {
                        return <Redirect to="/allsearches" />;
                    }}
                />
            </Switch>
            <Sidebar />
        </BrowserRouter>
    );
}

export default connector(AuthenticatedApp);
