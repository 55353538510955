import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { formatPhoneNumberForDisplay, formatEmailForDisplay } from '../../utils';
import { Assets } from 'asset-lib/src/index';
import classnames from 'classnames';
import InitialsIcon from '../InitialsIcon';
import ColoredButton from '../ColoredButton';

type PersonCardProps = {
    firstName: string;
    lastName: string;
    ID: string;
    status: string;
    listings: number;
    avgSoldPrice: number;
    prodVolume: number;
    market: string;
    onClickViewProfile: Function;
};

export const NewPersonCard = ({
    firstName = 'N/A',
    lastName = 'N/A',
    ID,
    status,
    listings,
    avgSoldPrice,
    prodVolume,
    market,
    onClickViewProfile,
}: PersonCardProps) => {
    const [isLargeScreen, setIsLargeScreen] = useState(true);

    window.onresize = resize;
    function resize() {
        if (window.innerWidth > 965) {
            setIsLargeScreen(true);
        } else {
            setIsLargeScreen(false);
        }
    }

    useEffect(() => {
        resize();
    }, []);

    return (
        <div className={styles.tableItem}>
            <div className={styles.iconContainer}>
                <InitialsIcon firstName={firstName} lastName={lastName}></InitialsIcon>
            </div>
            <div className={styles.nameContainer}>{firstName + ' ' + lastName}</div>
            <div className={styles.agentIdContainer}>{ID}</div>
            <div className={styles.emailContainer}>{status}</div>
            <div className={styles.agentIdContainer}>{listings}</div>
            <div className={styles.soldPriceContainer}> - </div> {/* sold price */}
            <div className={styles.ProdVolumeContainer}> - </div> {/* Prod volume */}
            <div className={styles.MarketContainer}> {market} </div> {/* Market */}
            <ColoredButton text={'View Profile'} onClick={() => onClickViewProfile()} />
        </div>
    );
};
export default NewPersonCard;
