import React from 'react';
import styles from './index.module.css';

type LegalInfoProps = {
    disclaimer: string;
    source: string;
};
const LegalInfo = ({ disclaimer, source }: LegalInfoProps) => {
    return (
        <div className={styles.legalInfo}>
            <div className={styles.textSpacing}>
                <b>Disclaimer: </b> {disclaimer ? disclaimer : 'None provided'}
            </div>
            <div className={styles.textSpacing}>
                <b>Source: </b> {source ? source : 'None  provided'}
            </div>
        </div>
    );
};

export default LegalInfo;
