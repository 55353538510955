import React from 'react';
import styles from './index.module.css';
import defHomeImage from 'web-lib/images/defaultHomeImage.png';
import { UnconnectedIcon } from 'asset-lib/src/assets/svg';
import { timeSince } from '../../utils';
import { ItemCarousel } from '../ItemCarousel';
import { formatPhoneNumberForDisplay, formatEmailForDisplay } from '../../utils';

type listingAgent = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    agentMlsId: string;
    mlsName: string;
    brokerageId: string;
};

type listingData = {
    listingId: string;
    mlsName: string;
    connected: number;
};

type ListingAgentCardProps = {
    agent: listingAgent;
    listingInfo: Array<listingData>;
    connectedListings: number;
    totalListings: number;
};
export const ListingAgentCard = ({
    agent,
    listingInfo,
    connectedListings,
    totalListings,
}: ListingAgentCardProps) => {
    const DetailsLabel = ({ label, value }: { label?: string; value?: any }) => {
        return (
            <div className={styles.detailsLabel}>
                <div className={styles.labelconstant}>{label}&nbsp;</div>
                <div className={styles.labeltext}>{value}</div>
            </div>
        );
    };
    return (
        <div className={styles.root}>
            <div className={styles.row}>
                <div className={styles.agentInfo}>
                    <div className={styles.name}>
                        {agent.firstName} {agent.lastName}
                    </div>
                    <div className={styles.contact}>
                        <div>{formatPhoneNumberForDisplay(agent.phoneNumber)}</div>
                        <div>{formatEmailForDisplay(agent.email)}</div>
                    </div>
                </div>

                <div className={styles.info}>
                    <DetailsLabel label={'Total Listings'} value={totalListings} />
                    <DetailsLabel label={'Connected Listings'} value={connectedListings} />
                </div>
                <div className={styles.listingInfo}>
                    <ItemCarousel
                        cards={listingInfo?.map((listing: any, index: number) => (
                            <div key={index} className={styles.mlsDetails}>
                                <div className={styles.mlsText}>
                                    <b>Mls Name: </b>
                                    {listing?.mlsName ?? 'None listed'}
                                </div>

                                <div className={styles.mlsText}>
                                    <b>Listing ID: </b>
                                    {listing?.listingId ?? 'None listed'}
                                </div>
                                {!listing.connected && (
                                    <div className={styles.notConnectedDiv}>
                                        <img
                                            className={styles.notConnectedIcon}
                                            src={UnconnectedIcon}
                                            alt={'icon'}
                                            referrerPolicy={'no-referrer'}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                        displaySize={1}
                        overlayImage={false}
                        cardWidth={220}
                    />
                </div>
            </div>
        </div>
    );
};

export default ListingAgentCard;
