import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';

type parameters = {
    Name: string;
    ID: string;
    Status: string;
};
export const BrokerageHeader = ({ Name, ID, Status }: parameters) => {
    return (
        <div className={styles.height}>
            <div className={styles.gradientHeader} />
            <div className={styles.brokerageName}>
                {Name}
                <p>#{ID}</p>
            </div>
            <div className={styles.brokerageStatus}>
                {Status == 'Active' ? (
                    <div className={styles.greenDot} />
                ) : (
                    <div className={styles.redDot} />
                )}

                {Status == 'Active' ? 'Active' : 'Inactive'}
            </div>
        </div>
    );
};

export default BrokerageHeader;
