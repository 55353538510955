import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import {
    STATUS,
    LISTINGS_COLLECTION,
    SHOWING_REQUESTS,
    AGENTS,
    BROKERAGES,
    BROKERAGE_USERS,
} from '../../../store/api/constants';
import * as Actions from './actions';
import { BSON, Stitch, StitchUser } from 'mongodb-stitch-browser-sdk';
import { generateSignature } from '../../../utils';
import {
    callStitchFunction,
    createLoggedAgentInDB,
    deleteAgentInDB,
    getRealmClient,
} from '../../../store/api/sagas';

export function* getAdminUsers() {
    try {
        const client = Stitch.defaultAppClient;
        const admins = yield call(
            callStitchFunction,
            client,
            'runAggregationAdmins',
            'adminRoster',
        );
        yield put(Actions.fetchAdminUsersSucceeded(admins));
    } catch (error) {
        yield put(Actions.fetchAdminUsersFailed(error));
        window.alert('Unable to fetch brokerage admins.');
    }
}

export function* sagaManageAdmins() {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === Actions.ADMIN_ACTIONS.FetchAdminUsers &&
                action.status === STATUS.Requested,
            getAdminUsers,
        ),
    ]);
}
