import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import classnames from 'classnames';

type ErrorLogCardProps = {
    functionName: string;
    application: string;
    errorCount: number;
    onClickError?: Function;
};
const ErrorLogCard = ({
    functionName,
    application,
    errorCount,
    onClickError,
}: ErrorLogCardProps) => {
    // get card background color based on how many errors there are
    const getBackgroundColor = () => {
        if (errorCount === 0) {
            return 'zeroErrors';
        } else if (errorCount > 0 && errorCount <= 10) {
            return 'tenErrors';
        } else if (errorCount > 10 && errorCount <= 50) {
            return 'fiftyErrors';
        } else if (errorCount > 50 && errorCount <= 100) {
            return 'oneHundredErrors';
        } else if (errorCount > 100 && errorCount <= 500) {
            return 'fiveHundredErrors';
        } else if (errorCount > 500 && errorCount <= 1000) {
            return 'oneThousandErrors';
        } else {
            return 'yikesErrors';
        }
    };
    const rootStyle = classnames(styles.root, styles[application], styles[getBackgroundColor()]);
    return (
        <div className={rootStyle} onClick={() => onClickError()}>
            <div className={styles.row}>
                <div className={classnames(styles.text, styles.firstText)}>{functionName}</div>
                <div className={classnames(styles.text, styles.secondText)}>{application}</div>
                <div className={classnames(styles.text, styles.secondText)}>{errorCount}</div>
            </div>
        </div>
    );
};

export default ErrorLogCard;
