import { ObjectID } from 'mongodb';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { string } from 'prop-types';
import { STATUS } from '../../../store/api/constants';
import { AgentsType } from './types';

export enum AGENT_ACTIONS {
    ClearSelectedAgent = '[Agents] Clear Agent',
    GrantFreeTrial = '[Agents] Grant Free Trial',
    RemoveFreeTrial = '[Agents] Remove Free Trial',

    SetSelectedAgent = '[Agents] Set Selected Agent',
    SetAgentSelected = '[Agents] Set Agent',
    SetSearchText = '[Agents] SetSearchText',
    SetCurrentPage = '[Agents] Set current page',

    FetchAgentProfile = '[Agents] Fetch Agent Profile',
    FetchAllAgents = '[Agents] Fetch All Agents',
    FetchSearchText = '[Agents] FetchSearchText',
    GetSelectedAgent = '[Agents] Get Selected Agent',
    GetAgentSubscription = '[Agents] Get Agent Subscription',

    fetchAgentBrokerage = '[Agents] Fetch Agent Market',
}

export function fetchAgentBrokerageRequested() {
    return {
        type: AGENT_ACTIONS.fetchAgentBrokerage,
        status: STATUS.Requested,
    };
}

export function fetchAgentBrokerageSucceded(brokerage: any) {
    return {
        type: AGENT_ACTIONS.fetchAgentBrokerage,
        status: STATUS.Succeeded,
        brokerage,
    };
}

export function fetchAgentBrokerageFailed(error: any) {
    return {
        type: AGENT_ACTIONS.fetchAgentBrokerage,
        status: STATUS.Failed,
        error,
    };
}

export type FetchAgentDetails =
    | ReturnType<typeof fetchAgentBrokerageRequested>
    | ReturnType<typeof fetchAgentBrokerageSucceded>
    | ReturnType<typeof fetchAgentBrokerageFailed>;

export function getAgentSubscriptionRequested(agentId: string) {
    return {
        type: AGENT_ACTIONS.GetAgentSubscription,
        status: STATUS.Requested,
        agentId,
    };
}
export function getAgentSubscriptionSucceded(agentId: string) {
    return {
        type: AGENT_ACTIONS.GetAgentSubscription,
        status: STATUS.Succeeded,
        agentId,
    };
}
export function getAgentSubscriptionFailed(error: string) {
    return {
        type: AGENT_ACTIONS.GetAgentSubscription,
        status: STATUS.Failed,
        error,
    };
}
export type GetAgentSubscription =
    | ReturnType<typeof getAgentSubscriptionRequested>
    | ReturnType<typeof getAgentSubscriptionSucceded>
    | ReturnType<typeof getAgentSubscriptionFailed>;
export function grantFreetrialRequested(agentId: any) {
    return {
        type: AGENT_ACTIONS.GrantFreeTrial,
        status: STATUS.Requested,
        agentId: agentId,
    } as const;
}

export function grantFreetrialSucceded(agent: any) {
    return {
        type: AGENT_ACTIONS.GrantFreeTrial,
        status: STATUS.Succeeded,
        agent: agent,
    } as const;
}
export function grantFreetrialFailed(error: any) {
    return {
        type: AGENT_ACTIONS.GrantFreeTrial,
        status: STATUS.Failed,
        error: error,
    } as const;
}
export type GrantFreeTrial =
    | ReturnType<typeof grantFreetrialRequested>
    | ReturnType<typeof grantFreetrialSucceded>
    | ReturnType<typeof grantFreetrialFailed>;

export function removeFreeTrialRequested(agentId: any) {
    return {
        type: AGENT_ACTIONS.RemoveFreeTrial,
        status: STATUS.Requested,
        agentId: agentId,
    } as const;
}

export function removeFreeTrialSucceded(agentId: any) {
    return {
        type: AGENT_ACTIONS.RemoveFreeTrial,
        status: STATUS.Succeeded,
        agentId: agentId,
    } as const;
}

export function removeFreeTrialFailed(error: any) {
    return {
        type: AGENT_ACTIONS.RemoveFreeTrial,
        status: STATUS.Failed,
        error: error,
    } as const;
}

export type RemoveFreeTrial =
    | ReturnType<typeof removeFreeTrialRequested>
    | ReturnType<typeof removeFreeTrialSucceded>
    | ReturnType<typeof removeFreeTrialFailed>;

export function getSelectedAgent() {
    return {
        type: AGENT_ACTIONS.GetSelectedAgent,
    } as const;
}

export function clearSelectedAgentData() {
    return {
        type: AGENT_ACTIONS.ClearSelectedAgent,
    } as const;
}

export function setSelectedAgent(agent: any) {
    return {
        type: AGENT_ACTIONS.SetAgentSelected,
        status: STATUS.Requested,
        agent,
    };
}

export function setCurrentPage(page: number) {
    return {
        type: AGENT_ACTIONS.SetCurrentPage,
        status: STATUS.Requested,
        page,
    };
}

export type GetSelectedAgent = ReturnType<typeof getSelectedAgent>;
export type ClearSelectedAgents = ReturnType<typeof clearSelectedAgentData>;
export type SetSelectedAgent = ReturnType<typeof setSelectedAgent>;
export type SetCurrentPage = ReturnType<typeof setCurrentPage>;
//==========================================================

export function fetchAllAgentsRequested(page: number, searchParam?: any) {
    return {
        type: AGENT_ACTIONS.FetchAllAgents,
        status: STATUS.Requested,
        page,
        searchParam,
    } as const;
}

export function fetchAllAgentsSucceded(allAgents) {
    return {
        type: AGENT_ACTIONS.FetchAllAgents,
        status: STATUS.Succeeded,
        allAgents,
    } as const;
}

export function fetchAllAgentsFailed(error) {
    return {
        type: AGENT_ACTIONS.FetchAllAgents,
        status: STATUS.Failed,
        error,
    } as const;
}
export type FetchAllAgentsActions =
    | ReturnType<typeof fetchAllAgentsRequested>
    | ReturnType<typeof fetchAllAgentsSucceded>
    | ReturnType<typeof fetchAllAgentsFailed>;
//==========================================================

//==========================================================
export const fetchSelectedAgentProfileRequested = (agentId: string, marketName: string) =>
    <const>{
        type: AGENT_ACTIONS.FetchAgentProfile,
        status: STATUS.Requested,
        agentId,
        marketName,
    };
export const fetchSelectedAgentProfileSucceeded = (agent: AgentsType) =>
    <const>{
        type: AGENT_ACTIONS.FetchAgentProfile,
        status: STATUS.Succeeded,
        agent,
    };

export const fetchSelectedAgentProfileFailed = (error: string) =>
    <const>{
        type: AGENT_ACTIONS.FetchAgentProfile,
        status: STATUS.Failed,
        error,
    };

export type FetchAgentProfile =
    | ReturnType<typeof fetchSelectedAgentProfileRequested>
    | ReturnType<typeof fetchSelectedAgentProfileSucceeded>
    | ReturnType<typeof fetchSelectedAgentProfileFailed>;
//==========================================================
