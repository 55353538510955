import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import TopHat from '../../../../../Images/TopHat.png';
import classnames from 'classnames';
import { formatPhoneNumberForDisplay, formatEmailForDisplay } from '../../../../../utils/';
import { Button } from 'web-lib';

type UserCardProps = {
    name: string;
    phoneNumber: string;
    email: string;
    status: string;
    signUpDate?: string;
    lastSession?: string;
    isSuperAdmin?: boolean;
    image?: string;
    jobTitle?: string;
    agentMlsId?: string;
    onClickVerify?: Function;
    onClickUnverify?: Function;
    onClickMakeSuperAdmin?: Function;
    onClickMakeNotSuperAdmin?: Function;
};

export const UserCard = ({
    name,
    phoneNumber,
    email,
    status,
    signUpDate,
    lastSession,
    isSuperAdmin,
    image,
    jobTitle,
    agentMlsId,
    onClickVerify,
    onClickUnverify,
    onClickMakeSuperAdmin,
    onClickMakeNotSuperAdmin,
}: UserCardProps) => {
    const profileImage = !image || image === '' ? TopHat : image;
    const color =
        status === 'verified' || status === 'active'
            ? styles.verified
            : status === 'pending' || status === 'logged'
            ? styles.pending
            : status === 'unverified'
            ? styles.unverified
            : status === 'suspended'
            ? styles.suspended
            : status === 'denied'
            ? styles.denied
            : styles.status;
    const rootStyle = classnames(styles.status, color);
    return (
        <div className={styles.root}>
            <div className={styles.leftColumn}>
                {image && <img src={image} />}
                {jobTitle && <div>{jobTitle}</div>}
            </div>
            <div className={styles.middleColumn}>
                <div className={styles.name}>{name}</div>
                <div className={styles.bold}>{formatPhoneNumberForDisplay(phoneNumber)}</div>
                <div className={styles.bold}>{formatEmailForDisplay(email)}</div>
                {signUpDate && <div>Sign Up Date: {signUpDate}</div>}
                {lastSession && <div>Last Session: {lastSession}</div>}
            </div>
            <div className={styles.rightColumn}>
                <div className={rootStyle}>{status.toUpperCase()}</div>
                <div className={styles.rowStyle}>
                    {status !== 'verified' ? (
                        <Button
                            buttonText={'Verify'}
                            className={styles.verifyButton}
                            onClick={onClickVerify}
                        />
                    ) : (
                        status === 'verified' && (
                            <Button
                                buttonText={'Unverify'}
                                className={styles.unverifyButton}
                                onClick={onClickUnverify}
                            />
                        )
                    )}
                    {!isSuperAdmin ? (
                        <Button
                            buttonText={'Make Super Admin'}
                            onClick={onClickMakeSuperAdmin}
                            className={styles.superAdminButton}
                        />
                    ) : (
                        <Button
                            buttonText={'Make not super admin'}
                            onClick={onClickMakeNotSuperAdmin}
                            className={styles.notSuperAdminButton}
                        />
                    )}
                </div>
            </div>
            <div className={styles.flags}>{isSuperAdmin && <div>Super Admin</div>}</div>
        </div>
    );
};

export default UserCard;
