import React, { useEffect, useState, useRef } from 'react';
import { useOutsideClick } from '../../utils/index';
import styles from './index.module.css';
import ColoredButton from '../ColoredButton';
import {
    createBrokerageUserRequested,
    fetchSelectedBrokerageUsersRequested,
    updateBrokerageUserRequested,
} from '../../domains/dash/BrokeragesPage/actions';
import { connect, useDispatch } from 'react-redux';
import { maskPhoneNumber, unmaskPhoneNumber } from '../../utils/mask';
import RandomLoader from '../RandomLoader';
import { getErrors, getSuccess } from '../../domains/dash/BrokeragesPage/selectors';

const mapDispatchToProps = {
    fetchSelectedBrokerageUsersRequested,
    createBrokerageUserRequested,
    updateBrokerageUserRequested,
};
const mapStateToProps = (state: any) => ({
    error: getErrors(state),
    success: getSuccess(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type user = {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: 'Admin' | 'Super Admin';
};
type formParams = {
    typeOfForm: 'Edit' | 'Create';
    brokerageId?: string;
    show: boolean;
    closeForm: Function;
    loading: boolean;
    callBack: Function;
    user?: user;

    createBrokerageUserRequested: Function;
    updateBrokerageUserRequested: Function;
};

const UserForm = ({
    typeOfForm,
    brokerageId,
    show,
    closeForm,
    loading,
    callBack,
    user,

    createBrokerageUserRequested,
    updateBrokerageUserRequested,
}: formParams) => {
    const ref = useRef(null);
    const [invalidName, setInvalidName] = useState(false);
    const [invalidLastName, setInvalidLastName] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(false);
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        role: 'Admin',
    });
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    function isValidPhone(phone) {
        return /^\+1[0-9]{10}$/.test(phone);
    }
    function isValidName(name) {
        return /^[\dA-Za-z]+[\dA-Za-z\s]{1,50}$/.test(name);
    }
    const handleChange = (event) => {
        let val =
            event.target.name === 'phoneNumber'
                ? maskPhoneNumber(event.target.value ?? '')
                : event.target.value;
        switch (event.target.name) {
            case 'firstName':
                isValidName(val) ? setInvalidName(false) : setInvalidName(true);
                break;
            case 'lastName':
                isValidName(val) ? setInvalidLastName(false) : setInvalidLastName(true);
                break;
            case 'email':
                isValidEmail(val) ? setInvalidEmail(false) : setInvalidEmail(true);
                break;
            case 'phoneNumber':
                isValidPhone(unmaskPhoneNumber(val))
                    ? setInvalidPhone(false)
                    : setInvalidPhone(true);
                break;
            default:
                break;
        }
        setForm({ ...form, [event.target.name]: val });
    };

    useOutsideClick(ref, () => {});
    useEffect(() => {
        if (user) {
            setForm({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: maskPhoneNumber(user.phoneNumber),
                role: user.role,
            });
        }
        if (show == false) {
            setForm({
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                role: 'Admin',
            });
        }
    }, [show]);

    return loading ? (
        <div className={styles.modalContainer} style={{ display: show ? 'flex' : 'none' }}>
            <div className={styles.modal} ref={ref}>
                <RandomLoader />
            </div>
        </div>
    ) : (
        <div className={styles.modalContainer} style={{ display: show ? 'flex' : 'none' }}>
            <div className={styles.modal} ref={ref}>
                <div className={styles.title}>{'Create Brokerage User'}</div>
                <div className={styles.inputContainer}>
                    <div className={styles.inputTitle}>{'First Name'}</div>
                    <input
                        name="firstName"
                        className={styles.input}
                        value={form.firstName}
                        onChange={handleChange}
                    ></input>
                    {invalidName && <div className={styles.errorPrompt}>{'Invalid name'}</div>}
                    <div className={styles.inputTitle}>{'Last Name'}</div>
                    <input
                        name="lastName"
                        className={styles.input}
                        value={form.lastName}
                        onChange={handleChange}
                    ></input>
                    {invalidLastName && (
                        <div className={styles.errorPrompt}>{'Invalid last name'}</div>
                    )}
                    <div className={styles.inputTitle}>{'Email'}</div>
                    <input
                        name="email"
                        className={typeOfForm == 'Edit' ? styles.inputDisabled : styles.input}
                        type="email"
                        value={form.email}
                        onChange={handleChange}
                        disabled={typeOfForm == 'Edit'}
                    ></input>
                    {invalidEmail && <p className={styles.errorPrompt}>{'Invalid Email'}</p>}
                    <div className={styles.inputTitle}>{'Phone'}</div>
                    <input
                        name="phoneNumber"
                        className={styles.input}
                        type="tel"
                        value={form.phoneNumber}
                        onChange={handleChange}
                    ></input>
                    {invalidPhone && <p className={styles.errorPrompt}>{'Invalid Phone'}</p>}
                    <div className={styles.inputTitle}>{'Role'}</div>
                    <select
                        name="role"
                        className={styles.input}
                        value={form.role}
                        onChange={handleChange}
                    >
                        <option value="Admin">Admin</option>
                        <option value="Super Admin">Super Admin</option>
                    </select>
                </div>
                <div className={styles.buttonContainer}>
                    <ColoredButton
                        colorsReversed={true}
                        text={'Cancel'}
                        onClick={() => {
                            closeForm();
                        }}
                    />
                    <ColoredButton
                        text={'Save'}
                        onClick={() => {
                            if (
                                !invalidName &&
                                !invalidLastName &&
                                !invalidEmail &&
                                !invalidPhone
                            ) {
                                if (typeOfForm == 'Create') {
                                    createBrokerageUserRequested(brokerageId, form, callBack);
                                } else {
                                    updateBrokerageUserRequested(form, user._id, callBack);
                                }
                            } else {
                                window.alert('Please fill all fields correctly');
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default connector(UserForm);
