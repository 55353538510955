import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { ConnectedApp } from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { RootReducer } from './store/reducer';
import { rootSaga } from './store/saga';

const sagaMiddleware = createSagaMiddleware();

var middleware = [sagaMiddleware];
// if in dev environment, redux dev tools
let devTools;
if (process.env.REACT_APP_STITCH_APP_ID === 'showingly-development-fndeq') {
    devTools = composeWithDevTools(applyMiddleware(...middleware));
} else {
    devTools = applyMiddleware(...middleware);
}

export const store = createStore(RootReducer, devTools);

sagaMiddleware.run(rootSaga);
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConnectedApp />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
