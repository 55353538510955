import { ObjectID } from 'mongodb';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { STATUS } from '../../../store/api/constants';

export enum DEVELOPMENT_ACTION {
    FetchErrorLogs = '[Development Action] Fetch Error Logs',
    SetSelectedError = '[Development Action] Set  Selected Error',
    FetchErrorGraph = '[Development Action] Fetch Error Graph',
    FetchOtherData = '[Development Action] Fetch Other Data',
}

export function fetchErrorLogsRequested() {
    return {
        type: DEVELOPMENT_ACTION.FetchErrorLogs,
        status: STATUS.Requested,
    } as const;
}

export function fetchErrorLogsSucceeded(todaysErrors: Array<any>) {
    return {
        type: DEVELOPMENT_ACTION.FetchErrorLogs,
        status: STATUS.Succeeded,
        todaysErrors,
    } as const;
}

export function fetchErrorLogsFailed(error: Error) {
    return {
        type: DEVELOPMENT_ACTION.FetchErrorLogs,
        status: STATUS.Failed,
        error,
    } as const;
}
export function setErrorFunctionSelected(selectedError: any) {
    return {
        type: DEVELOPMENT_ACTION.SetSelectedError,
        status: STATUS.Requested,
        selectedError,
    } as const;
}

export function fetchErrorGraphRequested(functionName: string, application: string) {
    return {
        type: DEVELOPMENT_ACTION.FetchErrorGraph,
        status: STATUS.Requested,
        functionName,
        application,
    } as const;
}

export function fetchErrorGraphSucceeded(errorData: Array<any>) {
    return {
        type: DEVELOPMENT_ACTION.FetchErrorGraph,
        status: STATUS.Succeeded,
        errorData,
    } as const;
}

export function fetchErrorGraphFailed(error: Error) {
    return {
        type: DEVELOPMENT_ACTION.FetchErrorGraph,
        status: STATUS.Failed,
        error,
    } as const;
}
export function fetchOtherErrorDataRequested(functionName: string, application: string) {
    return {
        type: DEVELOPMENT_ACTION.FetchOtherData,
        status: STATUS.Requested,
        functionName,
        application,
    } as const;
}
export function fetchOtherErrorDataSucceeded(objectIds: Array<string>, errorMarks: Array<any>) {
    return {
        type: DEVELOPMENT_ACTION.FetchOtherData,
        status: STATUS.Succeeded,
        objectIds,
        errorMarks,
    } as const;
}
export function fetchOtherErrorDataFailed(error: Error) {
    return {
        type: DEVELOPMENT_ACTION.FetchOtherData,
        status: STATUS.Succeeded,
        error,
    } as const;
}

export type DevelopmentActions =
    | ReturnType<typeof fetchErrorLogsRequested>
    | ReturnType<typeof fetchErrorLogsSucceeded>
    | ReturnType<typeof fetchErrorLogsFailed>
    | ReturnType<typeof setErrorFunctionSelected>
    | ReturnType<typeof fetchErrorGraphRequested>
    | ReturnType<typeof fetchErrorGraphSucceeded>
    | ReturnType<typeof fetchErrorGraphFailed>
    | ReturnType<typeof fetchOtherErrorDataRequested>
    | ReturnType<typeof fetchOtherErrorDataSucceeded>
    | ReturnType<typeof fetchOtherErrorDataFailed>;
