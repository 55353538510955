import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { formatPhoneNumberForDisplay, formatEmailForDisplay } from '../../utils';
import { Assets } from 'asset-lib/src/index';
import classnames from 'classnames';
import InitialsIcon from '../InitialsIcon';
import ColoredButton from '../ColoredButton';

type BrokerageCardProps = {
    name: string;
    brokerageId: string;
    agentCount: number;
    status: string;
    market: string;
    onClickViewBrokerage: Function;
};

export const NewBrokerageCard = ({
    name,
    brokerageId,
    agentCount,
    status,
    market,
    onClickViewBrokerage,
}: BrokerageCardProps) => {
    const [isLargeScreen, setIsLargeScreen] = useState(true);

    window.onresize = resize;
    function resize() {
        if (window.innerWidth > 965) {
            setIsLargeScreen(true);
        } else {
            setIsLargeScreen(false);
        }
    }

    useEffect(() => {
        resize();
    }, []);

    return (
        <div className={styles.tableItem}>
            <div className={styles.name}>{name}</div>
            <div className={styles.brokerageId}>{brokerageId}</div>
            <div className={styles.agentCount}>{agentCount}</div>
            <div className={styles.status}>{status}</div>
            <div className={styles.market}>{market}</div>

            <ColoredButton text={'View Profile'} onClick={() => onClickViewBrokerage()} />
        </div>
    );
};
export default NewBrokerageCard;
