import React, { useEffect, useState, useRef } from 'react';
import { useOutsideClick } from '../../utils/index';
import styles from './index.module.css';
import ColoredButton from '../ColoredButton';
import RandomLoader from '../RandomLoader';

interface ConfirmActionModalProps {
    title: string;
    description: string;
    onConfirm: Function;
    onCancel: any;
    confirmButtonText: string;
    cancelButtonText: string;
    show: boolean;
    loading: boolean;
}

const ConfirmActionModal = ({
    title,
    description,
    onConfirm,
    onCancel,
    confirmButtonText,
    cancelButtonText,
    show,
    loading,
}: ConfirmActionModalProps) => {
    const [isShowing, setIsShowing] = useState(false);
    const ref = useRef(null);
    useOutsideClick(ref, () => {
        if (isShowing) {
            onCancel();
        }
    });

    useEffect(() => {
        setIsShowing(show);
    }, [show]);

    return !loading ? (
        <div className={styles.modalContainer} style={{ display: isShowing ? 'flex' : 'none' }}>
            <div className={styles.modal} ref={ref}>
                <div className={styles.title}>{title}</div>
                <div className={styles.description}>{description}</div>
                <div className={styles.buttonContainer}>
                    <ColoredButton
                        colorsReversed={true}
                        text={cancelButtonText}
                        onClick={() => {
                            onCancel();
                        }}
                    />
                    <ColoredButton
                        text={confirmButtonText}
                        onClick={() => {
                            onConfirm();
                        }}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className={styles.modalContainer} style={{ display: isShowing ? 'flex' : 'none' }}>
            <div className={styles.modal} ref={ref}>
                <RandomLoader />
            </div>
        </div>
    );
};
export default ConfirmActionModal;
