import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import rightArrow from 'web-lib/images/rightArrow.svg';
import leftArrow from 'web-lib/images/leftArrow.svg';
import 'swiper/swiper-bundle.css';

type ItemCarouselProps = {
    cards: JSX.Element[];
    displaySize?: number;
    overlayImage?: boolean;
    cardWidth?: number;
    arrowWidth?: number;
    nextButtonId?: string;
    prevButtonId?: string;
};

SwiperCore.use([Navigation]);

export const ItemCarousel = ({
    cards,
    displaySize = 1,
    overlayImage = false,
    cardWidth = 355,
    arrowWidth,
    nextButtonId,
    prevButtonId,
}: ItemCarouselProps) => {
    const shouldOverlayImage = overlayImage ? 1 : 0;
    const percentOfPartialPic = 0.33;
    const partialPic = percentOfPartialPic * cardWidth;
    const spaceBetween = 15;
    const rootWidth =
        cardWidth * displaySize +
        spaceBetween * (displaySize - 1) +
        shouldOverlayImage * partialPic;
    const slidesPerView = displaySize + shouldOverlayImage * percentOfPartialPic;

    const rootStyle = {
        width: `${rootWidth}px`,
    };
    const swiperStyles = {
        width: `${(cardWidth * 34) / 450}px !important`,
    };

    const arrowStyles = {
        width: arrowWidth ? `${arrowWidth}px` : '50%',
    };

    return (
        <div style={rootStyle}>
            <Swiper
                navigation={{
                    nextEl: nextButtonId ? nextButtonId : '#next',
                    prevEl: prevButtonId ? prevButtonId : '#prev',
                }}
                slidesPerView={slidesPerView}
                spaceBetween={spaceBetween}
                watchOverflow={true}
                onClick={(event: any) => {
                    // console.log(event);
                }}
                className={styles.swiper}
            >
                {nextButtonId ? null : (
                    <div className="swiper-button-next" id="next" style={swiperStyles}>
                        <img src={rightArrow} className={styles.rightArrow} style={arrowStyles} />
                    </div>
                )}
                {prevButtonId ? null : (
                    <div className="swiper-button-prev" id="prev" style={swiperStyles}>
                        <img src={leftArrow} className={styles.leftArrow} style={arrowStyles} />
                    </div>
                )}
                {cards?.map((card: any, key: any) => {
                    return (
                        <SwiperSlide className={styles.swiperSlide} key={`slide-${key}`}>
                            {card}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};

SwiperCore.use([Navigation]);

export default ItemCarousel;
