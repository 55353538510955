import { ErrorObject, serializeError } from 'serialize-error';
import { STATUS } from '../../../store/api/constants';
import { AGENT_ACTIONS } from './actions';

export const initialState = {
    allAgents: [],
    agentSubscription: {},
    loading: false,
    selectedListing: null,
    error: null,
    currentPage: 1,

    fetchAgentBrokerageSuccess: false,
    fetchAgentBrokerageError: null,
    fetchAgentBrokerageLoading: false,
    selectedAgent: null,
};
export type Agents = typeof initialState;

export function reducerSearch(state = initialState, action: any) {
    const { type, status } = action;

    switch (type) {
        case AGENT_ACTIONS.fetchAgentBrokerage: {
            switch (status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        fetchAgentBrokerageLoading: true,
                        fetchAgentBrokerageSuccess: false,
                        fetchAgentBrokerageError: null,
                    };
                case STATUS.Succeeded:
                    return {
                        ...state,
                        fetchAgentBrokerageLoading: false,
                        fetchAgentBrokerageSuccess: true,
                        fetchAgentBrokerageError: null,
                        selectedAgent: {
                            ...state.selectedAgent,
                            brokerageName: action.brokerage.name,
                        },
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        fetchAgentBrokerageLoading: false,
                        fetchAgentBrokerageSuccess: false,
                        fetchAgentBrokerageError: action.error,
                    };
                default:
                    return state;
            }
        }
        case AGENT_ACTIONS.GetAgentSubscription: {
            switch (status) {
                case STATUS.Requested:
                    return {
                        ...state,
                        loading: true,
                    };
                case STATUS.Succeeded:
                    const { subscription } = action;
                    return {
                        ...state,
                        loading: false,
                        agentSubscription: subscription,
                    };
                case STATUS.Failed:
                    return {
                        ...state,
                        loading: false,
                        error: action.error,
                    };

                default:
                    return state;
            }
        }
        case AGENT_ACTIONS.GrantFreeTrial: {
            if (status === STATUS.Requested) {
                return {
                    ...state,
                };
            } else if (status === STATUS.Succeeded) {
                return {
                    ...state,
                    selectedAgent: action.agent,
                };
            } else if (status === STATUS.Failed) {
                const { error } = action;
                return {
                    ...state,
                    error,
                };
            } else {
                return { ...state };
            }
        }

        case AGENT_ACTIONS.RemoveFreeTrial: {
            if (status === STATUS.Requested) {
                return {
                    ...state,
                };
            } else if (status === STATUS.Succeeded) {
                return {
                    ...state,
                };
            } else if (status === STATUS.Failed) {
                const { error } = action;
                return {
                    ...state,
                    error,
                };
            } else {
                return { ...state };
            }
        }

        case AGENT_ACTIONS.GetSelectedAgent: {
            return {
                ...state,
            };
        }

        case AGENT_ACTIONS.ClearSelectedAgent: {
            return {
                ...state,
                selectedAgent: {},
            };
        }

        case AGENT_ACTIONS.SetAgentSelected: {
            const { agent } = action;
            return {
                ...state,
                selectedAgent: agent,
            };
        }

        case AGENT_ACTIONS.SetCurrentPage: {
            const { page } = action;
            return {
                ...state,
                currentPage: page,
            };
        }

        case AGENT_ACTIONS.FetchAllAgents: {
            const { page } = action;
            if (status === STATUS.Requested) {
                return {
                    ...state,
                    page,
                    loading: true,
                };
            } else if (status === STATUS.Succeeded) {
                const { allAgents } = action;
                return {
                    ...state,
                    allAgents,
                    loading: false,
                };
            } else if (status === STATUS.Failed) {
                const { error } = action;
                return {
                    ...state,
                    error,
                    loading: false,
                };
            } else {
                return { ...state };
            }
        }
        case AGENT_ACTIONS.FetchAgentProfile: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        fetchAgentProfileLoader: true,
                        fetchAgentProfileError: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { agent } = action;
                    return {
                        ...state,
                        fetchAgentProfileLoader: false,
                        selectedAgent: agent,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        fetchAgentProfileLoader: false,
                        fetchAgentProfileError: error,
                    };
                }
            }
        }

        default:
            return state;
    }
}
