import React from 'react';
import styles from './index.module.css';

type MoreInfoProps = {
    homeType: string;
    yearBuilt: string;
    lotArea: number;
    region: string;
    elementary: string;
    middle: string;
    high: string;
    district: string;
    hoaName: string;
    hoaFee: number;
    hoaFeeFreq: string;
    taxes: number;
    parking: number;
};
const MoreInfo = ({
    homeType,
    yearBuilt,
    lotArea,
    region,
    elementary,
    middle,
    high,
    district,
    hoaName,
    hoaFee,
    hoaFeeFreq,
    taxes,
    parking,
}: MoreInfoProps) => {
    return (
        <div className={styles.belowPicsInfo}>
            <div className={styles.infoSubColumns}>
                <div className={styles.textSpacing}>
                    <b>Home Type: </b>
                    {homeType ? homeType : 'N/A'}
                </div>
                <div className={styles.textSpacing}>
                    <b>Year Built: </b>
                    {yearBuilt ? yearBuilt : 'N/A'}
                </div>
                <div className={styles.textSpacing}>
                    <b>Lot Area: </b>
                    {lotArea ? lotArea + ' ' + 'ac' : 'N/A'}
                </div>
                <div className={styles.textSpacing}>
                    <b>Region: </b>
                    {region ? region : 'N/A'}
                </div>
            </div>
            <div className={styles.infoSubColumns}>
                <div className={styles.textSpacing}>
                    <b>Elementary: </b>
                    {elementary ? elementary : 'N/A'}
                </div>
                <div className={styles.textSpacing}>
                    <b>Middle: </b>
                    {middle ? middle : 'N/A'}
                </div>
                <div className={styles.textSpacing}>
                    <b>High: </b>
                    {high ? high : 'N/A'}
                </div>
                <div className={styles.textSpacing}>
                    <b>District: </b>
                    {district ? district : 'N/A'}
                </div>
            </div>
            <div className={styles.infoSubColumns}>
                <div className={styles.textSpacing}>
                    <b>HOA: </b>
                    {hoaName ? hoaName : 'N/A'}
                </div>
                <div className={styles.textSpacing}>
                    <b>HOA Fee: </b>
                    {hoaFee ? hoaFee + ' ' + hoaFeeFreq : 'N/A'}
                </div>
                <div className={styles.textSpacing}>
                    <b>Yearly Taxes: </b>
                    {taxes ? taxes : 'N/A'}
                </div>
                <div className={styles.textSpacing}>
                    <b>Parking: </b>
                    {parking ? parking + ' ' + 'spaces' : 'N/A'}
                </div>
            </div>
        </div>
    );
};

export default MoreInfo;
