import { createSelector } from 'reselect';
import { WebAdminState } from '../../../store/reducer';

export const getAdminState = (state: WebAdminState) => state.adminManagement || {};

export const getAdminUsers = createSelector(
    getAdminState,
    (adminManagement: any) => adminManagement.adminUsers,
);
export const getLoading = createSelector(
    getAdminState,
    (adminManagement: any) => adminManagement.loading,
);
