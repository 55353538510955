import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styles from './index.module.css';
import { getListingLoading, getSelectedViewingListing, getListingShowings } from '../selectors';
import { PictureCarousel, ShowingRequestCard, ListingScreenData } from 'web-lib';
import MoreInfo from './MoreInfo';
import LegalInfo from './LegalInfo';
import ListingHeader from './listingHeader';
import { updateShowingStatusRequested } from '../actions';
import { RandomLoader } from '../../../../components';

const mapStateToProps = (state: any) => ({
    loading: getListingLoading(state),
    listing: getSelectedViewingListing(state),
    showings: getListingShowings(state),
});

const mapDispatchToProps = {
    updateShowingStatus: updateShowingStatusRequested,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ListingViewer = ({
    listing,
    loading,
    showings,
    updateShowingStatus,
}: ConnectedProps<typeof connector>) => {
    const [selectedMenuItem, setSelectedMenuItem] = useState<string>('Home Info');

    function showingHistory() {
        return (
            <div className={styles.rightCol}>
                {showings?.length ? (
                    showings.map((showingRequest: any, index: number) => {
                        return (
                            <div key={index}>
                                {index === 0 && <div className={styles.line}></div>}
                                <ShowingRequestCard
                                    showingRequest={showingRequest}
                                    confirmShowingRequest={(
                                        newStatus: any,
                                        showingId: any,
                                        sendInfo: boolean,
                                        isUnv: boolean,
                                    ) => {
                                        updateShowingStatus(showingId, newStatus);
                                    }}
                                    updateShowingStatus={(showingId: any) => {
                                        updateShowingStatus(showingId, 'cancelled');
                                    }}
                                />
                                <div className={styles.line}></div>
                            </div>
                        );
                    })
                ) : (
                    <span>Looks like there's no showing history on this listing...</span>
                )}
            </div>
        );
    }

    function homeInfo() {
        return (
            <div className={styles.rightCol}>
                <div className={styles.pictures}>
                    <PictureCarousel
                        cards={listing.photos.map((image: any) => (
                            <img
                                src={image}
                                className={styles.image}
                                referrerPolicy={'no-referrer'}
                            />
                        ))}
                        displaySize={2}
                        overlayImage={true}
                        pixelWidth={365}
                    />
                </div>
                <MoreInfo
                    homeType={listing?.property?.type}
                    yearBuilt={listing?.property?.yearBuilt}
                    lotArea={listing?.property?.lotSize}
                    region={listing?.address?.city}
                    elementary={listing?.school?.elementarySchool}
                    middle={listing?.school?.middleSchool}
                    high={listing?.school?.highSchool}
                    district={listing?.school?.district}
                    hoaName={listing?.association?.name}
                    hoaFee={listing?.association?.fee}
                    hoaFeeFreq={listing?.association?.frequency}
                    taxes={listing?.tax?.taxAnnualAmount}
                    parking={listing?.property?.parking?.spaces}
                />
                <div>
                    {listing.mlsList.map((legal: any) => (
                        <LegalInfo disclaimer={legal.disclaimer} source={legal.source} />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.root}>
            {!loading && listing ? (
                <div className={styles.box}>
                    <ListingHeader
                        selectedMenuItem={selectedMenuItem}
                        setSelectedMenuItem={setSelectedMenuItem}
                    />
                    <div className={styles.infoContainer}>
                        <div className={styles.sideInfo}>
                            <ListingScreenData
                                address={listing?.address?.full}
                                city={listing?.address?.city}
                                state={listing?.address?.state}
                                includePropertyInfo={false}
                                zip={listing?.address?.postalCode}
                                price={listing?.listPrice}
                                beds={listing?.property?.bedrooms}
                                baths={listing?.property?.bathrooms}
                                sqft={listing?.property?.area}
                                mlsList={listing?.mlsList}
                                listingAgent={
                                    listing?.agent?.firstName + ' ' + listing?.agent?.lastName
                                }
                                phone={listing?.agent?.contact?.cell}
                                office={listing?.office?.name}
                                showingType={listing?.agentListing?.type || null}
                                noticeRequired={listing?.agentListing?.noticeRequired || null}
                                maxShowingDuration={listing?.agentListing?.maxDuration || null}
                                showingInstructions={
                                    listing?.agentListing?.showingInstructions || null
                                }
                            />
                            {listing.agentListing && (
                                <div className={styles.listingInfo}>
                                    <div>
                                        <span className={styles.label}>Lock Box Code: </span>
                                        <span className={styles.value}>
                                            {listing?.agentListing?.lockCombo ?? 'Not Listed'}
                                        </span>
                                    </div>
                                    <div>
                                        <span className={styles.label}>Lock Box Type: </span>
                                        <span className={styles.value}>
                                            {listing?.agentListing?.lockboxType ?? 'Not Listed'}
                                        </span>
                                    </div>
                                    <div>
                                        <span className={styles.label}>Location: </span>
                                        <span className={styles.value}>
                                            {listing?.agentListing?.lockboxLocation ?? 'Not Listed'}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>

                        {
                            {
                                'Home Info': homeInfo(),
                                'Showing History': showingHistory(),
                            }[selectedMenuItem]
                        }
                    </div>
                </div>
            ) : (
                <RandomLoader />
            )}
        </div>
    );
};

export default connector(ListingViewer);
