import { ErrorObject, serializeError } from 'serialize-error';
import { STATUS } from '../../../store/api/constants';
import { DEVELOPMENT_ACTION } from './actions';

export const initialState = {
    todaysErrors: [],
    selectedFunctionError: null,
    errorGraphData: [],
    loading: false,
    error: null,
    errorMarks: [],
    objectIds: [],
};
export type Development = typeof initialState;

export default function reducerDevelopment(state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case DEVELOPMENT_ACTION.FetchErrorLogs: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { todaysErrors } = action;
                    return {
                        ...state,
                        todaysErrors,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case DEVELOPMENT_ACTION.SetSelectedError: {
            const { selectedError } = action;
            return {
                ...state,
                selectedFunctionError: selectedError,
            };
        }
        case DEVELOPMENT_ACTION.FetchErrorGraph: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { errorData } = action;
                    return {
                        ...state,
                        errorData,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case DEVELOPMENT_ACTION.FetchOtherData: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { objectIds, errorMarks } = action;
                    return {
                        ...state,
                        objectIds,
                        errorMarks,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        default:
            return state;
    }
}
