import { createSelector } from 'reselect';
import { WebAdminState } from '../../store/reducer';

export const getDashState = (state: WebAdminState) => state.dash || {};

export const getAgentList = createSelector(getDashState, (dash: any) => dash.agents || {});

export const getMarkets = createSelector(getDashState, (dashState: any) =>
    dashState.markets
        .map((market: any) => ({
            ...market,
            id: market._id.toString(),
        }))
        // Alphabetize by market name
        .sort((m1, m2) => Intl.Collator('en').compare(m1.name, m2.name)),
);
