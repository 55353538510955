import React from 'react';
import classnames from 'classnames';

import styles from './index.module.css';

interface SwitchProps {
    checked: boolean;
    containerStyling?: string;
    onToggle: Function;
    switchStyling?: string;
}

const Switch = ({ checked, containerStyling, onToggle, switchStyling }: SwitchProps) => {
    return (
        <div
            className={containerStyling}
            onClick={() => {
                onToggle();
            }}
        >
            <label className={styles.switch}>
                <input type="checkbox" checked={checked} disabled={true} />
                <span className={classnames(styles.slider, styles.round, switchStyling)}></span>
            </label>
        </div>
    );
};

export default Switch;
