export function prettifyWords(string: string = '') {
    try {
        return string
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    } catch {
        return string;
    }
}
