import React from 'react';
import styles from './index.module.css';
import { PopUpMenu, ModalConfirm } from 'web-lib';
import ClickableWithFeedback from '../ClickableWithFeedback';

import DotsIcon from '../../Images/dots.svg';
import EditIcon from '../../Images/Admin2.0/Edit.svg';
import UserForm from '../UserForm';
import RemoveIcon from '../../Images/remove.svg';
import { useSelector } from 'react-redux';
import { ObjectID } from 'mongodb';

export const BrokerageUsers = ({
    user,
    userMenu,
    setUserMenu,
    deletingUser,
    setDeletingUser,
    isDeletingUser,
    handleDeleteUser,
    loadingUpdateUser,
    refreshBrokerageUsers,
}: any) => {
    const deleteUserLoading = useSelector<any, boolean>(isDeletingUser);
    const [showUserForm, setShowUserForm] = React.useState(false);

    const PopUp = () => (
        <PopUpMenu
            visible={user._id === userMenu}
            close={() => setUserMenu(null)}
            horizontalPosition="Right"
            verticalPosition="Top"
        >
            <ul className={styles.userMenuOptions}>
                <li
                    className={`${styles.userMenuItem}`}
                    onClick={() => {
                        setDeletingUser(user._id);
                        setUserMenu(null);
                    }}
                >
                    Remove <img src={RemoveIcon} className={styles.right} />
                </li>
                <li
                    className={`${styles.userMenuItem}`}
                    onClick={() => {
                        setShowUserForm(true);
                    }}
                >
                    Edit <img src={EditIcon} className={styles.right} />
                </li>
            </ul>
        </PopUpMenu>
    );

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.entry}>
                    <div className={styles.field}>
                        {user.firstName} {user.lastName}
                    </div>
                    <div className={styles.field}> {user.email}</div>
                    {/* <div> {agent.phone}</div> */}
                    <div className={styles.field}>
                        {' '}
                        {user.phoneNumber || user.otherPhoneNumber || 'No phone number provided'}
                    </div>
                    <div className={styles.field}>
                        {' '}
                        {user.signUpCompleted ? 'Signed Up' : 'Not Signed Up'}
                    </div>
                    <div className={styles.field}>
                        <>
                            <ClickableWithFeedback
                                onClick={() => {
                                    setUserMenu((val: ObjectID) =>
                                        val == user._id ? null : user._id,
                                    );
                                }}
                            >
                                <img src={DotsIcon} />
                                <PopUp />
                            </ClickableWithFeedback>
                        </>
                    </div>
                    <UserForm
                        user={user}
                        typeOfForm={'Edit'}
                        show={showUserForm}
                        closeForm={() => setShowUserForm(false)}
                        loading={loadingUpdateUser}
                        callBack={() => {
                            window.alert('User updated succesfully');
                            setShowUserForm(false);
                            refreshBrokerageUsers();
                        }}
                    />
                    <ModalConfirm
                        open={deletingUser !== null}
                        onClose={() => setDeletingUser(null)}
                        title="Are you sure?"
                        text="Proceed to remove this user permanently."
                        loadingYes={deleteUserLoading}
                        onClickNo={() => setDeletingUser(null)}
                        onClickYes={() => handleDeleteUser(deletingUser)}
                    />
                </div>
            </div>
        </div>
    );
};
export default BrokerageUsers;
