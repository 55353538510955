import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { Button, RandomLoader } from '../../../../../../components';
import {
    agentSearchCrossEnvRequested,
    attributeListingRequested,
    listingSearchCrossEnvRequested,
} from '../../../actions';
import { AgentSearchType, EnvironmentType, ListingSearchType } from '../../../constants';
import { getDataError, getDataLoading, getFoundAgents, getFoundListings } from '../../../selectors';
import { AgentCard } from '../../Utilities/AgentCard';
import { ListingCard } from '../../Utilities/ListingCard';
import styles from './index.module.css';

type AttributeListingPageProps = { environment: EnvironmentType };

const AttributeListingPage = (props: AttributeListingPageProps) => {
    const { environment } = props;
    const [searchTextAgent, setSearchTextAgent] = useState<string>('');
    const [searchTextListing, setSearchTextListing] = useState<string>('');
    const [searchTypeAgent, setSearchTypeAgent] = useState<AgentSearchType>('Internal');
    const [searchTypeListing, setSearchTypeListing] = useState<ListingSearchType>('Address');
    const [selectedAgent, setSelectedAgent] = useState<any>(null);
    const [selectedListing, setSelectedListing] = useState<any>(null);
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
    const [submittedData, setSubmittedData] = useState<{
        name: string;
        listingId: string;
        environment: EnvironmentType;
    }>(null);

    const dispatch = useDispatch();
    const agentSearch = useCallback(
        (searchText: string, searchType: AgentSearchType, environment: EnvironmentType) => {
            dispatch(agentSearchCrossEnvRequested(searchText, searchType, environment));
        },
        [],
    );
    const listingSearch = useCallback(
        (searchText: string, searchType: ListingSearchType, environment: EnvironmentType) =>
            dispatch(listingSearchCrossEnvRequested(searchText, searchType, environment)),
        [],
    );
    const attributeListing = useCallback(
        (listing: any, agent: any, environment: EnvironmentType) =>
            dispatch(attributeListingRequested(listing, agent, environment)),
        [],
    );

    const foundAgents = useSelector(getFoundAgents);
    const foundListings = useSelector(getFoundListings);
    const dataErrors = useSelector(getDataError);
    const dataLoading = useSelector(getDataLoading);

    useEffect(() => {
        setSelectedAgent(null);
        if (searchTextAgent.length) {
            agentSearch(searchTextAgent, searchTypeAgent, environment);
        }
    }, [searchTextAgent, searchTypeAgent, environment]);
    useEffect(() => {
        setSelectedListing(null);
        if (searchTextListing.length) {
            listingSearch(searchTextListing, searchTypeListing, environment);
        }
    }, [searchTextListing, searchTypeListing, environment]);

    return (
        <Col>
            {dataErrors && (
                <div className={styles.errorText1}>
                    An error has occurred:{' '}
                    {dataErrors.errorCode || dataErrors.error || dataErrors.message}
                </div>
            )}
            {dataErrors?.link && (
                <div className={styles.errorText1}>
                    {`Give this link to a dev: ${dataErrors.link}`}
                </div>
            )}
            {hasSubmitted && !dataErrors && !dataLoading && (
                <div className={styles.successText1}>
                    {`The listing with id ${submittedData.listingId} has been attributed to ${submittedData.name}. Great Success!!!`}
                </div>
            )}
            {hasSubmitted && dataLoading && (
                <div style={{ height: 100, width: 100 }}>
                    <RandomLoader />
                </div>
            )}
            <Button
                buttonText={'Info'}
                onClick={() => {
                    setShowMoreInfo(!showMoreInfo);
                }}
            />
            {showMoreInfo && (
                <div style={{ fontWeight: 900, paddingBottom: 50 }}>
                    <Row>{`PAY CLOSE ATTENTION!`}</Row>
                    <Row>
                        {`When attributed, the listing will be duplicated and made unique to the selected agent as well as given a testListing flag. `}
                    </Row>
                    <Row>
                        {`When attributing a listing, be aware of the market and mls, the address will remain the same but mls and market information will be changed to match that of the agent.`}
                    </Row>
                </div>
            )}
            <Row>
                <Col>
                    {/* Internal External only radio button */}
                    <div className={styles.colContainer}>
                        Choose an agent:
                        <div
                            onChange={(event) => {
                                const value: any = (event.target as HTMLInputElement).value;
                                setSearchTypeAgent(value);
                            }}
                            className={styles.radioContainer}
                        >
                            <label>
                                <input
                                    type="radio"
                                    value="Internal"
                                    name="Internal"
                                    checked={searchTypeAgent === 'Internal'}
                                />
                                Internal
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="External"
                                    name="External"
                                    checked={searchTypeAgent === 'External'}
                                />
                                External
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Both"
                                    name="Both"
                                    checked={searchTypeAgent === 'Both'}
                                />
                                Both
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Any"
                                    name="Any"
                                    checked={searchTypeAgent === 'Any'}
                                />
                                Non-testing (Careful!)
                            </label>
                        </div>
                    </div>
                    <input
                        value={searchTextAgent}
                        onChange={(event) => setSearchTextAgent(event.target.value)}
                        placeholder={'Name'}
                    />
                    {/* Selected Agent modal */}
                    {selectedAgent && (
                        <div className={styles.selectedModalRoot}>
                            <Col>
                                <div className={styles.selectedModalHeader}>
                                    Agent in {environment}:
                                </div>
                                <Row>
                                    <Col className={styles.modalText}>
                                        <div>First Name: {selectedAgent.firstName}</div>
                                        <div>Last Name: {selectedAgent.lastName}</div>
                                        <div>Email: {selectedAgent.email}</div>
                                        <div>Phone: {selectedAgent.phoneNumber}</div>
                                    </Col>
                                    <Col className={styles.modalText}>
                                        <div>
                                            {selectedAgent.markets.map((market, index) => {
                                                return `MLSID: ${market.agentMlsId} MLS: ${market.mlsName} `;
                                            })}
                                        </div>
                                        <div> {`Status:  ${selectedAgent.status}`}</div>
                                        <div>{`Last Session: ${
                                            selectedAgent.lastSession?.toString() || 'None'
                                        }`}</div>
                                    </Col>
                                </Row>
                                <Button
                                    buttonText={'Cancel'}
                                    className={styles.redButton}
                                    onClick={() => setSelectedAgent(null)}
                                />
                            </Col>
                        </div>
                    )}
                    {foundAgents.length !== 0 ? (
                        <div className={styles.cardContainer}>
                            {foundAgents.map((agent, index) => {
                                return (
                                    <AgentCard
                                        agent={agent}
                                        index={index}
                                        setSelectedAgent={
                                            selectedAgent ? () => {} : setSelectedAgent
                                        }
                                    />
                                );
                            })}
                        </div>
                    ) : null}
                </Col>
                <Col>
                    <div className={styles.colContainerListing}>
                        Choose a listing:
                        <div
                            onChange={(event) => {
                                const value: any = (event.target as HTMLInputElement).value;
                                setSearchTypeListing(value);
                            }}
                            className={styles.radioContainer}
                        >
                            <label>
                                <input
                                    type="radio"
                                    value="Address"
                                    name="Address"
                                    checked={searchTypeListing === 'Address'}
                                />
                                Address
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="ListingId"
                                    name="ListingId"
                                    checked={searchTypeListing === 'ListingId'}
                                />
                                Listing Id
                            </label>
                        </div>
                    </div>
                    <input
                        value={searchTextListing}
                        onChange={(event) => setSearchTextListing(event.target.value)}
                        placeholder={searchTypeListing}
                    />
                    {/* Selected Listing Modal */}
                    {selectedListing && (
                        <div className={styles.selectedModalRoot}>
                            <Col>
                                <div className={styles.selectedModalHeader}>
                                    Listing in {environment}:
                                </div>
                                <Row>
                                    <Col className={styles.modalText}>
                                        <div>Address: {selectedListing.address.full}</div>
                                        <div>City: {selectedListing.address.city}</div>
                                        <div>State: {selectedListing.address.state}</div>
                                    </Col>
                                    <Col className={styles.modalText}>
                                        <div>
                                            {selectedListing.mlsList.map((mls, index) => {
                                                return (
                                                    <Col key={index}>
                                                        <div>{`Listing Id: ${mls.listingId}`}</div>
                                                        <div>{`MLS Name: ${mls.mlsName}`}</div>
                                                    </Col>
                                                );
                                            })}
                                        </div>
                                    </Col>
                                </Row>
                                <Button
                                    buttonText={'Cancel'}
                                    className={styles.redButton}
                                    onClick={() => setSelectedListing(null)}
                                />
                            </Col>
                        </div>
                    )}
                    {/* Listing Cards */}
                    {foundListings.length !== 0 ? (
                        <div className={styles.cardContainer}>
                            {foundListings.map((listing, index) => {
                                return (
                                    <ListingCard
                                        listing={listing}
                                        index={index}
                                        setSelectedListing={setSelectedListing}
                                    />
                                );
                            })}
                        </div>
                    ) : null}
                </Col>
            </Row>
            {/* Confirm button if an agent and a listing are selected */}
            {selectedAgent && selectedListing ? (
                <div className={styles.confirmText}>
                    {`Attribute listing at ${selectedListing.address.full} to ${
                        selectedAgent.firstName
                    } ${selectedAgent.lastName} in ${environment.toUpperCase()}`}
                    <Button
                        onClick={() => {
                            attributeListing(selectedListing, selectedAgent, environment);
                            // Store the data for success display
                            setSubmittedData({
                                name: selectedAgent.firstName,
                                listingId: selectedListing.mlsList[0].listingId,
                                environment,
                            });
                            setHasSubmitted(true);
                            setSelectedAgent(null);
                            setSelectedListing(null);
                        }}
                        buttonText={'Confirm'}
                        className={styles.confirmButton}
                    />
                </div>
            ) : null}
        </Col>
    );
};

export default AttributeListingPage;
