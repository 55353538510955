/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './index.module.css';

const Button = ({ buttonText, className, buttonAlias, loading, disabled, type, onClick }) => {
    if (buttonAlias === 'Primary') {
        const primaryBtn = classnames(styles.primaryBtn, className);
        return (
            <>
                <button type={type} className={primaryBtn} disabled={disabled} onClick={onClick}>
                    {loading ? <p>Sample Loader goes here something similar</p> : buttonText}
                </button>
            </>
        );
    }

    if (buttonAlias === 'PrimaryOutline') {
        const primaryOutline = classnames(styles.primaryOutline, className);

        return (
            <button type={type} onClick={onClick} className={primaryOutline} disabled={disabled}>
                {loading ? <p>Sample Loader goes here something similar</p> : buttonText}
            </button>
        );
    }
    if (buttonAlias === 'pcPrimary') {
        const pcPrimaryBtn = classnames(styles.pcPrimaryBtn, className);

        return (
            <button type={type} onClick={onClick} className={pcPrimaryBtn} disabled={disabled}>
                {loading ? <p>Sample Loader goes here something similar</p> : buttonText}
            </button>
        );
    }

    if (buttonAlias === 'pcClicked') {
        const pcClickedBtn = classnames(styles.pcClickedBtn, className);

        return (
            <button type={type} onClick={onClick} className={pcClickedBtn} disabled={disabled}>
                {loading ? <p>Sample Loader goes here something similar</p> : buttonText}
            </button>
        );
    }

    return <></>;
};

Button.propTypes = {
    buttonText: PropTypes.string.isRequired,
    buttonAlias: PropTypes.oneOf(['Primary', 'PrimaryOutline', 'pcClicked', 'pcPrimary']),
    className: PropTypes.string,

    type: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    buttonAlias: 'Primary',
    loading: false,
    disabled: false,
    onClick: () => {},
    className: '',
    type: 'submit',
};

export default Button;
