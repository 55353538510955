import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';

type parameters = {
    firstName: string;
    lastName: string;
};
export const InitialsIcon = ({ firstName, lastName }: parameters) => {
    const initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;

    return <div className={styles.initialsIcon}>{initials}</div>;
};

export default InitialsIcon;
