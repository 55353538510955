import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import { Button, ButtonTabBar, RandomLoader } from '../../../components';
import AdminInfo from './AdminInfo';
import TopHat from '../../../Images/TopHat.png';
import { connect } from 'react-redux';
import { getAdminAccount } from '../../auth/selectors';
import { formatPhoneNumberForDisplay } from '../../../utils';

const mapStateToProps = (state: any) => ({
    account: getAdminAccount(state),
});

const connector = connect(mapStateToProps);

const Profile = ({ account }: any) => {
    // This use effect makes it so that there is a 5% change the user gets Rick Rolled
    useEffect(() => {
        const random = Math.floor(Math.random() * 100);
        if (random > 95) {
            window.open('https://youtu.be/dQw4w9WgXcQ?t=1');
        }
    }, ['Call Once']);

    return (
        <div className={styles.root}>
            <h1>Admin Boi</h1>
            <AdminInfo
                profileImage={account.profileImage ? account.profileImage : TopHat}
                firstName={account.firstName}
                lastName={account.lastName}
                department={account.department}
                phoneNumber={formatPhoneNumberForDisplay(account.phoneNumber)}
                email={account.email}
                devAgentId={account.agentMlsIds.devAgentId}
                stagingAgentId={account.agentMlsIds.stagingAgentId}
                prodAgentId={account.agentMlsIds.prodAgentId}
            />
        </div>
    );
};

export default connector(Profile);
