import { ObjectID } from 'mongodb';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { string } from 'prop-types';
import { STATUS } from '../../../store/api/constants';

export enum SEARCH_ACTION {
    TextSearchAgents = '[Search Action] Text Search Agents',
    TextSearchListings = '[Search Action] Text Search Listings',
    TextSearchBrokerages = '[Search Action] Text Search Brokerages',
    // TextSearchConsumers = '[Search Action] Text Search Consumers',
    FetchShowingAvailability = '[Search Action], Fetch Showing Availability',
    ClearShowingAvailability = '[Search Action], Clear Showing Availability',
    ClearShowingScheduled = '[Search Action], Clear Scheduled Showing ',
    // FetchBadges = '[All Searches] Fetch Badges',
    // UpdateAgent = '[All Searches] Badge Interaction',
    // CreateShowing = '[Search Action] Create Showing',
    // FetchAgentClients = '[All Searches] Fetch Agent Clients',
    ScheduleShowing = '[Search Action] Schedule Showing',
    SetSelectedBrokerage = '[Search Action] Set Selected Brokerage',
    UpdateSelectedBrokerage = '[Search Action] Updated Selected Brokerage',
    FetchBrokerageRoster = '[Search Action] Fetch Brokerage Roster',
    FetchBrokerageAdmins = '[Search Action] Fetch Brokerage Admins',
    ClearSelectedBrokerage = '[Search Action] Clear Brokerage',

    SetAgentSelected = '[Search Action] Set Agent',
    FetchSelectedListing = '[Search Action] Fetch Selected Listing',
    FetchFormattedMarkets = '[Search Action] Fetch Formatted Markets',
    FetchAgentListings = '[Search Action] Fetch Agent Listings',
    FetchListingShowings = '[Search Action] Fetch Listing Showings',
    ClearSelectedAgent = '[Search Action] Clear Agent',
    FetchBrokeragePlusPrices = '[Search Action] Fetch Brokerage Plus Prices',
    FetchStats = '[Search Action] Fetch Stats',
    FetchBrokerageListingAgents = '[Search Action] Fetch Listing Agents',

    UpdateShowingStatus = '[Search Action] Update Showing Status',
    CreateShowingManagementSubscription = '[Search Action] Create Showing Management Subscription',
    CreateBrokeragePlusSubscription = '[Search Action] Create Brokerage Plus Subscription',
    RefetchSelectedBrokerage = '[Search Action] Refetch Selected Brokerage',
    ChangeBrokerageUserStatus = '[Search Action] Change Brokerage User Status',
    FetchPasswordChange = '[Search Action] Change User Password',
}

export function searchAgentsRequested(searchText: string, marketName: string) {
    return {
        type: SEARCH_ACTION.TextSearchAgents,
        status: STATUS.Requested,
        searchText,
        marketName,
    } as const;
}

export function searchAgentsSucceeded(agents: any[]) {
    return {
        type: SEARCH_ACTION.TextSearchAgents,
        status: STATUS.Succeeded,
        agents,
    } as const;
}

export function searchAgentsFailed(error: Error) {
    return {
        type: SEARCH_ACTION.TextSearchAgents,
        status: STATUS.Failed,
        error,
    } as const;
}

export type SearchAgentActions =
    | ReturnType<typeof searchAgentsRequested>
    | ReturnType<typeof searchAgentsSucceeded>
    | ReturnType<typeof searchAgentsFailed>;

// ==============================================================

export function searchListingsRequested(
    searchText: string,
    marketId: string,
    mlsIdSearch: boolean,
) {
    return {
        type: SEARCH_ACTION.TextSearchListings,
        status: STATUS.Requested,
        searchText,
        marketId,
        mlsIdSearch,
    } as const;
}

export function searchListingsSucceeded(listings: any[]) {
    return {
        type: SEARCH_ACTION.TextSearchListings,
        status: STATUS.Succeeded,
        listings,
    } as const;
}

export function searchListingsFailed(error: Error) {
    return {
        type: SEARCH_ACTION.TextSearchListings,
        status: STATUS.Failed,
        error,
    } as const;
}

export type SearchListingActions =
    | ReturnType<typeof searchListingsRequested>
    | ReturnType<typeof searchListingsSucceeded>
    | ReturnType<typeof searchListingsFailed>;

// ==============================================================
export function searchBrokeragesRequested(searchText: string, marketId: string) {
    return {
        type: SEARCH_ACTION.TextSearchBrokerages,
        status: STATUS.Requested,
        searchText,
        marketId,
    } as const;
}
export function searchBrokeragesSucceeded(brokerages: any[]) {
    return {
        type: SEARCH_ACTION.TextSearchBrokerages,
        status: STATUS.Succeeded,
        brokerages,
    } as const;
}

export function searchBrokeragesFailed(error: Error) {
    return {
        type: SEARCH_ACTION.TextSearchBrokerages,
        status: STATUS.Failed,
        error,
    } as const;
}

export type SearchBrokeragesAction =
    | ReturnType<typeof searchBrokeragesRequested>
    | ReturnType<typeof searchBrokeragesSucceeded>
    | ReturnType<typeof searchBrokeragesFailed>;
// ==============================================================

export function fetchShowingAvailabilityRequested(listingId: BSON.ObjectId) {
    return {
        type: SEARCH_ACTION.FetchShowingAvailability,
        status: STATUS.Requested,
        listingId,
    } as const;
}
export function fetchShowingAvailabilitySucceeded(unavailableDates: any[] = []) {
    return {
        type: SEARCH_ACTION.FetchShowingAvailability,
        status: STATUS.Succeeded,
        unavailableDates,
    } as const;
}
export function fetchShowingAvailabilityFailed(error: string) {
    return {
        type: SEARCH_ACTION.FetchShowingAvailability,
        status: STATUS.Failed,
        error,
    } as const;
}
export type ShowingAvailabilityActions =
    | ReturnType<typeof fetchShowingAvailabilityRequested>
    | ReturnType<typeof fetchShowingAvailabilitySucceeded>
    | ReturnType<typeof fetchShowingAvailabilityFailed>;

//==============================================================
//below is for succeeded/failed/requested password change
//==============================================================

export function fetchPasswordChangeRequested(stitchUserId: string) {
    return {
        type: SEARCH_ACTION.FetchPasswordChange,
        status: STATUS.Requested,
        stitchUserId,
    } as const;
}
export function fetchPasswordChangeSucceeded(
    userStatus: string,
    email: string,
    stitchUserID: BSON.ObjectID,
) {
    return {
        type: SEARCH_ACTION.FetchPasswordChange,
        status: STATUS.Succeeded,
        userStatus,
        email,
        stitchUserID,
    } as const;
}
export function fetchPasswordChangeFailed(error: string) {
    return {
        type: SEARCH_ACTION.FetchPasswordChange,
        status: STATUS.Failed,
        error,
    } as const;
}
export type PasswordChangeActions =
    | ReturnType<typeof fetchPasswordChangeRequested>
    | ReturnType<typeof fetchPasswordChangeSucceeded>
    | ReturnType<typeof fetchPasswordChangeFailed>;

// ==============================================================

export function scheduleShowingRequested(
    listingId: any,
    startTime: Date,
    endTime: Date,
    agent: any,
    offset: any,
) {
    return {
        type: SEARCH_ACTION.ScheduleShowing,
        status: STATUS.Requested,
        listingId,
        startTime,
        endTime,
        agent,
        offset,
    } as const;
}
export function scheduleShowingSucceeded(showingCreated: any) {
    return {
        type: SEARCH_ACTION.ScheduleShowing,
        status: STATUS.Succeeded,
        showingCreated,
    } as const;
}
export function scheduleShowingFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.ScheduleShowing,
        status: STATUS.Failed,
        error,
    } as const;
}
export type ScheduleShowingActions =
    | ReturnType<typeof scheduleShowingRequested>
    | ReturnType<typeof scheduleShowingSucceeded>
    | ReturnType<typeof scheduleShowingFailed>;

export function clearShowingAvailability() {
    return {
        type: SEARCH_ACTION.ClearShowingAvailability,
    } as const;
}
export function clearShowingScheduled() {
    return {
        type: SEARCH_ACTION.ClearShowingScheduled,
    } as const;
}

// ==============================================================
export function setSelectedBrokerage(brokerage: any) {
    return {
        type: SEARCH_ACTION.SetSelectedBrokerage,
        brokerage,
    } as const;
}

export function updateSelectedBrokerageRequested(
    brokerageId: BSON.ObjectId,
    brokerageStatus: any,
    superAdminId: BSON.ObjectID,
    adminsToUpdate: Array<BSON.ObjectID>,
) {
    return {
        type: SEARCH_ACTION.UpdateSelectedBrokerage,
        status: STATUS.Requested,
        brokerageId,
        brokerageStatus,
        superAdminId,
        adminsToUpdate,
    } as const;
}
export function clearSelectedBrokerageData() {
    return {
        type: SEARCH_ACTION.ClearSelectedBrokerage,
    } as const;
}
export function updateSelectedBrokerageSucceeded(newStatus: string) {
    if (newStatus === 'verified') {
        window.alert('Lets`s get this 🍞 🥖');
    }
    return {
        type: SEARCH_ACTION.UpdateSelectedBrokerage,
        status: STATUS.Succeeded,
    } as const;
}
export function updateSelectedBrokerageFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.UpdateSelectedBrokerage,
        status: STATUS.Failed,
        error,
    } as const;
}

export function fetchBrokerageRosterRequested(brokerageId: BSON.ObjectId) {
    return {
        type: SEARCH_ACTION.FetchBrokerageRoster,
        status: STATUS.Requested,
        brokerageId,
    } as const;
}
export function fetchBrokerageRosterSucceeded(roster: Array<any>) {
    return {
        type: SEARCH_ACTION.FetchBrokerageRoster,
        status: STATUS.Succeeded,
        roster,
    } as const;
}
export function fetchBrokerageRosterFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.FetchBrokerageRoster,
        status: STATUS.Failed,
        error,
    } as const;
}

export function fetchBrokerageAdminsRequested(brokerageId: BSON.ObjectId) {
    return {
        type: SEARCH_ACTION.FetchBrokerageAdmins,
        status: STATUS.Requested,
        brokerageId,
    } as const;
}
export function fetchBrokerageAdminsSucceeded(admins: any[]) {
    return {
        type: SEARCH_ACTION.FetchBrokerageAdmins,
        status: STATUS.Succeeded,
        admins,
    } as const;
}
export function fetchBrokerageAdminsFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.FetchBrokerageAdmins,
        status: STATUS.Failed,
        error,
    } as const;
}

export function setAgentSelected(agent) {
    return {
        type: SEARCH_ACTION.SetAgentSelected,
        status: STATUS.Requested,
        agent,
    } as const;
}

export function clearSelectedAgentData() {
    return {
        type: SEARCH_ACTION.ClearSelectedAgent,
    } as const;
}
export function fetchListingRequested(listingObjId: BSON.ObjectId) {
    return {
        type: SEARCH_ACTION.FetchSelectedListing,
        status: STATUS.Requested,
        listingObjId,
    } as const;
}
export function fetchListingSucceeded(listing: any) {
    return {
        type: SEARCH_ACTION.FetchSelectedListing,
        status: STATUS.Succeeded,
        listing,
    } as const;
}
export function fetchListingFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.FetchSelectedListing,
    };
}
export function fetchFormattedMarketsRequested(objId: BSON.ObjectID, collection: string) {
    return {
        type: SEARCH_ACTION.FetchFormattedMarkets,
        status: STATUS.Requested,
        objId,
        collection,
    } as const;
}
export function fetchFormattedMarketsSucceeded(markets) {
    return {
        type: SEARCH_ACTION.FetchFormattedMarkets,
        status: STATUS.Succeeded,
        markets,
    } as const;
}
export function fetchFormattedMarketsFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.FetchFormattedMarkets,
        status: STATUS.Failed,
        error,
    } as const;
}
export function fetchBrokeragePlusPricesRequested(marketId: any, agentCount: number) {
    return {
        type: SEARCH_ACTION.FetchBrokeragePlusPrices,
        status: STATUS.Requested,
        marketId,
        agentCount,
    } as const;
}
export function fetchBrokeragePlusPricesSucceeded(prices: any) {
    return {
        type: SEARCH_ACTION.FetchBrokeragePlusPrices,
        status: STATUS.Succeeded,
        prices,
    } as const;
}
export function fetchBrokeragePlusPricesFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.FetchBrokeragePlusPrices,
        status: STATUS.Failed,
        error,
    } as const;
}

export function fetchAgentListingsRequested(agentObjId: BSON.ObjectId) {
    return {
        type: SEARCH_ACTION.FetchAgentListings,
        status: STATUS.Requested,
        agentObjId,
    } as const;
}
export function fetchAgentListingsSucceeded(listings) {
    return {
        type: SEARCH_ACTION.FetchAgentListings,
        status: STATUS.Succeeded,
        listings,
    } as const;
}
export function fetchAgentListingsFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.FetchAgentListings,
        status: STATUS.Failed,
        error,
    } as const;
}
export function fetchListingShowingsRequested(listingObjId: BSON.ObjectId) {
    return {
        type: SEARCH_ACTION.FetchListingShowings,
        status: STATUS.Requested,
        listingObjId,
    } as const;
}
export function fetchListingShowingsSucceeded(showings) {
    return {
        type: SEARCH_ACTION.FetchListingShowings,
        status: STATUS.Succeeded,
        showings,
    } as const;
}
export function fetchListingShowingsFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.FetchListingShowings,
        status: STATUS.Failed,
        error,
    } as const;
}
export function updateShowingStatusRequested(showingId: string, newShowingStatus: any) {
    return {
        type: SEARCH_ACTION.UpdateShowingStatus,
        status: STATUS.Requested,
        showingId,
        newShowingStatus,
    } as const;
}

export function updateShowingStatusSucceeded(showing: string) {
    return {
        type: SEARCH_ACTION.UpdateShowingStatus,
        status: STATUS.Succeeded,
        showing,
    } as const;
}

export function updateShowingStatusFailed(showingId: string, error: string) {
    return {
        type: SEARCH_ACTION.UpdateShowingStatus,
        status: STATUS.Failed,
        showingId,
        error,
    } as const;
}

export const createShowingManagementSubscriptionRequested = (
    paymentMethodId: string,
    showingManagement: any,
    brokerage: any,
) =>
    <const>{
        type: SEARCH_ACTION.CreateShowingManagementSubscription,
        status: STATUS.Requested,
        paymentMethodId,
        showingManagement,
        brokerage,
    };

export const createShowingManagementSubscriptionSucceeded = (brokerageId: any) =>
    <const>{
        type: SEARCH_ACTION.CreateShowingManagementSubscription,
        status: STATUS.Succeeded,
        brokerageId,
    };

export const createShowingManagementSubscriptionFailed = (errors: string) =>
    <const>{
        type: SEARCH_ACTION.CreateShowingManagementSubscription,
        status: STATUS.Failed,
        errors,
    };

export const createBrokeragePlusSubscriptionRequested = (
    customerId: any,
    paymentMethodId: string,
    priceId: any,
    brokerageObjectId: any,
    newCustomerDetails: any,
) =>
    <const>{
        type: SEARCH_ACTION.CreateBrokeragePlusSubscription,
        status: STATUS.Requested,
        customerId,
        paymentMethodId,
        priceId,
        brokerageObjectId,
        newCustomerDetails,
    };

export const createBrokeragePlusSubscriptionSucceeded = (plusSubscription: any) =>
    <const>{
        type: SEARCH_ACTION.CreateBrokeragePlusSubscription,
        status: STATUS.Succeeded,
        plusSubscription,
    };

export const createBrokeragePlusSubscriptionFailed = (errors: string) =>
    <const>{
        type: SEARCH_ACTION.CreateBrokeragePlusSubscription,
        status: STATUS.Failed,
        errors,
    };

export const updateBrokerageUserDocument = (
    brokerageObjectId: string,
    brokerageUserId: string,
    setData: any,
) =>
    <const>{
        type: SEARCH_ACTION.ChangeBrokerageUserStatus,
        status: STATUS.Requested,
        brokerageObjectId,
        brokerageUserId,
        setData,
    };

export const fetchStatsRequested = (userObjectId: string, userType: 'agent' | 'brokerage') =>
    <const>{
        type: SEARCH_ACTION.FetchStats,
        status: STATUS.Requested,
        userObjectId,
        userType,
    };
export const fetchStatsSucceeded = (stats: Array<any>, statsFilterButtons: Array<any>) =>
    <const>{
        type: SEARCH_ACTION.FetchStats,
        status: STATUS.Succeeded,
        stats,
        statsFilterButtons,
    };
export const fetchStatsFailed = (errors: string) =>
    <const>{
        type: SEARCH_ACTION.FetchStats,
        status: STATUS.Failed,
        errors,
    };

export const fetchBrokerageListingAgentsRequested = (brokerage: any) =>
    <const>{
        type: SEARCH_ACTION.FetchBrokerageListingAgents,
        status: STATUS.Requested,
        brokerage,
    };
export const fetchBrokerageListingAgentsSucceeded = (listingAgents: Array<any>) =>
    <const>{
        type: SEARCH_ACTION.FetchBrokerageListingAgents,
        status: STATUS.Succeeded,
        listingAgents,
    };
export const fetchBrokerageListingAgentsFailed = (errors: string) =>
    <const>{
        type: SEARCH_ACTION.FetchBrokerageListingAgents,
        status: STATUS.Failed,
        errors,
    };

export type ViewingActions =
    | ReturnType<typeof updateShowingStatusRequested>
    | ReturnType<typeof updateShowingStatusSucceeded>
    | ReturnType<typeof updateShowingStatusFailed>
    | ReturnType<typeof setSelectedBrokerage>
    | ReturnType<typeof setAgentSelected>
    | ReturnType<typeof fetchAgentListingsSucceeded>
    | ReturnType<typeof fetchAgentListingsRequested>
    | ReturnType<typeof fetchAgentListingsFailed>
    | ReturnType<typeof fetchListingRequested>
    | ReturnType<typeof fetchListingSucceeded>
    | ReturnType<typeof fetchListingFailed>
    | ReturnType<typeof fetchListingShowingsRequested>
    | ReturnType<typeof fetchListingShowingsSucceeded>
    | ReturnType<typeof fetchListingShowingsFailed>
    | ReturnType<typeof fetchFormattedMarketsRequested>
    | ReturnType<typeof fetchFormattedMarketsSucceeded>
    | ReturnType<typeof fetchFormattedMarketsFailed>
    | ReturnType<typeof fetchBrokerageRosterRequested>
    | ReturnType<typeof fetchBrokerageRosterSucceeded>
    | ReturnType<typeof fetchBrokerageRosterFailed>
    | ReturnType<typeof fetchBrokerageAdminsRequested>
    | ReturnType<typeof fetchBrokerageAdminsSucceeded>
    | ReturnType<typeof fetchBrokerageAdminsFailed>
    | ReturnType<typeof updateSelectedBrokerageRequested>
    | ReturnType<typeof updateSelectedBrokerageSucceeded>
    | ReturnType<typeof updateSelectedBrokerageFailed>
    | ReturnType<typeof fetchBrokeragePlusPricesRequested>
    | ReturnType<typeof fetchStatsRequested>
    | ReturnType<typeof fetchStatsSucceeded>
    | ReturnType<typeof fetchStatsFailed>
    | ReturnType<typeof fetchBrokerageListingAgentsRequested>
    | ReturnType<typeof fetchBrokerageListingAgentsSucceeded>
    | ReturnType<typeof fetchBrokerageListingAgentsFailed>;
