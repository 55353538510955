import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { formatPhoneNumberForDisplay, formatEmailForDisplay } from '../../utils';
import { Assets } from 'asset-lib/src/index';
import classnames from 'classnames';

type PersonCardProps = {
    name: string;
    firstElement: string;
    secondElement?: string;
    thirdElement: string;
    status?: string;
    hasSubscription?: boolean;
    type: string;
    onClickViewProfile: Function;
};

export const PersonCard = ({
    name,
    firstElement,
    secondElement,
    thirdElement,
    hasSubscription,
    status,
    type,
    onClickViewProfile,
}: PersonCardProps) => {
    const [isLargeScreen, setIsLargeScreen] = useState(true);

    window.onresize = resize;
    function resize() {
        if (window.innerWidth > 965) {
            setIsLargeScreen(true);
        } else {
            setIsLargeScreen(false);
        }
    }

    useEffect(() => {
        resize();
    }, []);

    const agentStatus =
        status === 'suspended'
            ? Assets.Svgs.XIconRed
            : status === 'logged' || status == 'hidden'
            ? Assets.Svgs.XIconGrey
            : Assets.Svgs.VerifiedClientIcon;
    const brokerageStatus =
        status === 'suspended'
            ? Assets.Svgs.XIconRed
            : status === 'unverified'
            ? Assets.Svgs.XIconGrey
            : Assets.Svgs.VerifiedClientIcon;
    const color =
        status === 'verified' || status === 'active'
            ? styles.verified
            : status === 'unverified'
            ? styles.unverified
            : status === 'suspended'
            ? styles.suspended
            : status === 'denied'
            ? styles.denied
            : styles.statusContainer;
    const statusStyle = classnames(color, styles.statusContainer);

    return (
        <div className={styles.tableItem}>
            {type === 'agent' && (
                <div className={styles.statusImageContainer}>
                    <img className={styles.statusImage} src={agentStatus} />
                </div>
            )}

            <div className={styles.nameContainer}>{name}</div>
            <div className={styles.phoneContainer}>
                {type === 'agent' ? formatPhoneNumberForDisplay(firstElement) : firstElement}
            </div>
            {type === 'agent' && (
                <div className={styles.emailContainer}>{formatEmailForDisplay(secondElement)}</div>
            )}
            {type === 'brokerage' && (
                <div className={styles.emailContainer}>
                    <div className={statusStyle}>{status.toUpperCase()}</div>
                </div>
            )}

            <div className={styles.agentIdContainer}>{thirdElement}</div>

            <div className={styles.profileContainer} onClick={() => onClickViewProfile()}>
                View Profile
            </div>
        </div>
    );
};
export default PersonCard;
