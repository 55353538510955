import React from 'react';
import styles from './index.module.css';

export const BrokerageAgents = ({ user }) => {
    return (
        <div>
            <div className={styles.container}>
                <div className={styles.entry}>
                    <div className={styles.field}>
                        {user.firstName} {user.lastName}
                    </div>
                    <div className={styles.field}> {user.email}</div>
                    {/* <div> {agent.phone}</div> */}
                    <div className={styles.field}>
                        {' '}
                        {user.status ? user.status : user.phoneNumber}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default BrokerageAgents;
