import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedLoginPage } from './domains/auth/Login';
import { routerRef } from './utils';
function UnauthenticatedApp() {
    return (
        <BrowserRouter
            basename={process.env.PUBLIC_URL}
            ref={(e) => {
                (routerRef as any).current = e;
            }}
        >
            <Switch>
                <Route exact path="/auth">
                    <ConnectedLoginPage />
                </Route>
                <Route
                    exact
                    path="*"
                    render={() => {
                        return <Redirect to="/auth" />;
                    }}
                />
            </Switch>
        </BrowserRouter>
    );
}

export default UnauthenticatedApp;
