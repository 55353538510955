import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import {
    STATUS,
    LISTINGS_COLLECTION,
    SHOWING_REQUESTS,
    AGENTS,
    BROKERAGES,
    BROKERAGE_USERS,
} from '../../../store/api/constants';
import * as Actions from './actions';
import { BSON, Stitch, StitchUser } from 'mongodb-stitch-browser-sdk';
import {
    callStitchFunction,
    findRecord,
    findRecords,
    parseStitchServiceError,
} from '../../../store/api/sagas';
import { getClient, getStitchUser, getStitchUserId } from '../../../redux';

export function* fetchErrorLogs({}: ReturnType<typeof Actions.fetchErrorLogsRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const logs = yield call(callStitchFunction, client, 'adminErrorLogs');
        yield put(Actions.fetchErrorLogsSucceeded(logs));
    } catch (error) {
        yield put(Actions.fetchErrorLogsFailed(error));
        window.alert('Unable to fetch error logs.');
    }
}

export function* fetchErrorGraphData({
    functionName,
    application,
}: ReturnType<typeof Actions.fetchErrorGraphRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const graphData = yield call(
            callStitchFunction,
            client,
            'adminErrorGraph',
            functionName,
            application,
        );
        yield put(Actions.fetchErrorGraphSucceeded(graphData));
    } catch (error) {
        yield put(Actions.fetchErrorGraphFailed(error));
        window.alert('Unable to fetch error log graph data.');
    }
}

export function* fetchOtherErrorData({
    functionName,
    application,
}: ReturnType<typeof Actions.fetchOtherErrorDataRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const otherData = yield call(
            callStitchFunction,
            client,
            'adminErrorInfo',
            functionName,
            application,
        );
        if (otherData) {
            yield put(
                Actions.fetchOtherErrorDataSucceeded(otherData?.objectIds, otherData?.errorMarks),
            );
        }
    } catch (error) {
        yield put(Actions.fetchOtherErrorDataFailed(error));
        window.alert('Unable to fetch other error log data.');
    }
}

export function* developmentSaga() {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === Actions.DEVELOPMENT_ACTION.FetchErrorLogs &&
                action.status === STATUS.Requested,
            fetchErrorLogs,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.DEVELOPMENT_ACTION.FetchErrorGraph &&
                action.status === STATUS.Requested,
            fetchErrorGraphData,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.DEVELOPMENT_ACTION.FetchOtherData &&
                action.status === STATUS.Requested,
            fetchOtherErrorData,
        ),
    ]);
}
