import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
    grantFreetrialRequested,
    removeFreeTrialRequested,
} from '../../domains/dash/AgentsPage/actions';
import ColoredButton from '../ColoredButton';

const mapDispatchToProps = {
    grantFreetrialRequested: grantFreetrialRequested,
    removeFreeTrialRequested: removeFreeTrialRequested,
};
const mapStateToProps = (state: any) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type parameters = {
    agent: any;
    grantFreetrialRequested: Function;
    removeFreeTrialRequested: Function;
};
export const ManagePlanTab = ({
    agent,
    grantFreetrialRequested,
    removeFreeTrialRequested,
}: parameters) => {
    const [doubleCheckVisible, setdoubleCheckVisible] = React.useState(false);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.subtitle}>
                <h3>Manage Plan </h3>
                <p>Here you can view and manage the current plan</p>
            </div>
            <div className={styles.plansContainer}>
                <div className={styles.plan}>
                    <h4>
                        Starter Plan{' '}
                        <div
                            className={
                                agent.subscription?.isTrial
                                    ? styles.unmarkedsquare
                                    : styles.markedsquare
                            }
                        />
                    </h4>
                    <p>
                        Best suite for beginners and new agents. Excludes some features included in
                        Premium.
                    </p>
                </div>
                <div className={styles.plan}>
                    <h4>
                        Premium Plan{' '}
                        <div
                            className={
                                agent.subscription?.isTrial
                                    ? styles.markedsquare
                                    : styles.unmarkedsquare
                            }
                        />
                    </h4>
                    <p>
                        Best suite for producers. Access to all the Showingly features and included
                        analytics.
                    </p>
                </div>
            </div>
            <div className={styles.subtitle}>
                <h3>Free trial subscription </h3>
                <p>
                    Here you can give a free trial to this agent. Select the amount of time that you
                    wish to give access to the Premium Plan.
                </p>
            </div>

            <div className={styles.plansContainer}>
                {!agent.subscription?.isTrial ? (
                    <div className={styles.subscriptionBox}>
                        <h4>+1 Month</h4>
                        <div
                            className={
                                agent.subscription?.isTrial
                                    ? styles.markedsquare
                                    : styles.unmarkedsquare
                            }
                            onClick={() => {
                                setdoubleCheckVisible(true);
                            }}
                        />
                    </div>
                ) : !doubleCheckVisible ? (
                    <div>
                        <div className={styles.expirationDate}>
                            <h4>Free Trial Ends: </h4>{' '}
                            {agent.subscription?.expirationDate.toDateString()}
                        </div>
                        <div className={styles.subtitle}>
                            <h3>Cancel a subscription </h3>
                            <p>
                                Here you can downgrade this plan to the Starter Plan. Do you want to
                                do this?
                            </p>
                        </div>
                        <ColoredButton
                            color="red"
                            text={'Cancel this subscription'}
                            onClick={() => {
                                setdoubleCheckVisible(true);
                            }}
                        ></ColoredButton>
                    </div>
                ) : null}
                {doubleCheckVisible ? (
                    <div>
                        {agent.subscription?.isTrial ? (
                            <div className={styles.subtitle}>
                                {' '}
                                <h3>Cancel this subscription </h3>
                                <p>
                                    Are you sure that you want to cancel this subscription? If you
                                    confirm, this agent will lose their “Premium Plan”.
                                </p>
                            </div>
                        ) : null}
                        <ColoredButton
                            text={'Confirm'}
                            onClick={() => {
                                agent.subscription?.isTrial == true
                                    ? removeFreeTrialRequested(agent.stitchUserId)
                                    : grantFreetrialRequested(agent.stitchUserId);
                                setdoubleCheckVisible(false);
                            }}
                        ></ColoredButton>
                        <ColoredButton
                            text={'Cancel'}
                            onClick={() => {
                                setdoubleCheckVisible(false);
                            }}
                            colorsReversed={true}
                        ></ColoredButton>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
export default connector(ManagePlanTab);
