import { BSON } from 'mongodb-stitch-browser-sdk';

export enum ListingScheduleType {
    ByAppointment = 'appt_required',
    GoNShow = 'go_and_show',
    Accompanied = 'accompanied',
}

export enum ListingScheduleTypeDisplay {
    go_and_show = 'Go and Show',
    appt_required = 'Appointment Required',
    accompanied = 'Accompanied Showing',
}

export enum LockBoxType {
    supra = 'Supra',
    sentrilock = 'Sentrilock',
    other = 'Other',
}

export enum STATUS {
    Requested,
    Succeeded,
    Failed,
    Ignored,
    Clear,
}

export enum ShowingType {
    Agent = 'agent',
    Delegated = 'delegated',
    Lead = 'lead',
}

export enum ShowingStatus {
    Active = 'active',
    Cancelled = 'cancelled',
    Completed = 'completed',
    ConfirmedNonDescript = 'confirmed',
    ConfirmedInternal = 'confirmed_internal',
    ConfirmedExternal = 'confirmed_external',
    Denied = 'denied',
    PendingInternal = 'pending_internal',
    PendingExternal = 'pending_external',
    Queued = 'queued',
    Requested = 'requested',
}

export const STATUS_DISPLAY_LABELS = [
    { status: 'requested', label: 'Requested' },
    { status: 'pending_external', label: 'Pending' },
    { status: 'pending_internal', label: 'Pending' },
    { status: 'confirmed', label: 'Confirmed' },
    { status: 'cancelled', label: 'Cancelled' },
    { status: 'denied', label: 'Denied' },
    { status: 'completed', label: 'Completed' },
    { status: 'queued', label: 'Queued' },
];

export type User = {
    _id?: BSON.ObjectId;
    stitchUserId: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    profilePhotoUpload?: {
        as: string;
        path: string;
    };
};

export type Agent = User & {
    _id: BSON.ObjectId;
    brokerageName: string;
};

export type Consumer = User & {
    _id: BSON.ObjectId;
    brokerageName: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    profilePhotoUpload: {
        as: string;
        path: string;
    };
    stitchUserId: string;
};

// incomplete
export type Listing = {
    _id: BSON.ObjectId;
    photos: string[];
    address: {
        city: string;
        country: string;
        full: string;
        postalCode: string;
        state: string;
        streetName: string;
        streetNumber: number;
        streetNumberText: string;
        unit?: string;
    };
    listPrice?: number;
    property?: {
        bedrooms: number;
        bathrooms: number;
        area: number;
        subTypeText: string;
        yearBuilt: string;
        lotSizeArea: number;
        parking: {
            spaces?: number;
        };
    };
    mls?: {
        status: string;
    };
    school?: {
        elementarySchool: string;
        middleSchool: string;
        highSchool: string;
        district: string;
    };
    office?: {
        contact?: {
            office: string;
            cell?: string;
        };
        name: string;
    };
    association?: {
        name: string;
        fee: number;
        frequency: string;
    };
    tax?: {
        taxAnnualAmount: number;
    };
    source: string;
    disclaimer: string;
    listingId?: string;
    agent: Agent;
    agentListing: AgentListing;
};

export type Showing = {
    _id: BSON.ObjectId;
    id: string;
    consumerId: string;
    consumer?: Consumer;
    agent?: Agent;
    agentId?: string;
    showingAssistant: Agent;
    listing?: Listing;
    listingId: BSON.ObjectId;
    pricing?: {
        delegation: {
            cost: number;
            currency: string;
            earnings?: number;
        };
    };
    status: ShowingStatus;
    type: ShowingType;
    start: Date;
    end?: Date;

    cancelled?: Date;
    confirmed?: Date;
    update?: Date;
    feedback?: {
        ratings: {
            cleanliness: number;
            layout: number;
            curbAppeal: number;
        };
        showingRequestId: BSON.ObjectId;
        review: string;
    };

    // used to display time on multi showing property modal
    startTime?: string | Date;
    showingDuration?: number;
};

export type FEEDBACK = {
    ratings: {
        cleanliness: number;
        layout: number;
        curbAppeal: number;
    };
    showingRequestId: BSON.ObjectId;
    review: string;
};

export type ShowingRequest = {
    listingId: string;
};

type ClientPhoto = {
    as: string;
    path: string;
};

export type Client = {
    _id: string | BSON.ObjectId;
    agentId: string | BSON.ObjectId;
    connectionType: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    profilePhotoUpload: ClientPhoto;
    signUpCompleted: boolean;
    status: string;
    stitchUserId: string;
    twilioVerification: object;
    title?: string;
};

export enum SHOWING_ACTION {
    Fetch = '[Showing] Fetch',
    Create = '[Showing] Create',
    UpdateStatus = '[Showing] Update Status',
    UpdateClient = '[Showing] Update Client',
    Delegate = '[Showing] Delegate',
    AcceptPickUp = '[Showing] Accept Pickup',
    PurchaseLead = '[Showing] Purchase Lead',
    FetchPrices = '[Showing] Fetch D/L/P Prices',
    SetShowingClient = '[Showing] Set Current Client',
}

export type UserOnAgentListing = {
    _id: BSON.ObjectId;
    canApprove: boolean;
    notificationSettings: Array<string>;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    profilePhotoUpload?: {
        as?: string;
        path?: string;
        uri?: string;
    };
    type?: 'Listing Agent' | 'Co-Listing Agent';
};

export enum ApprovalType {
    Agent = 'agent',
    Client = 'client',
    Both = 'both',
    Either = 'either',
    None = 'none',
}

export enum Approvals {
    Agent = 'agent',
    Client = 'client',
}

export type RecurringAvailability = {
    0: {
        startTime: Date | '';
        endTime: Date | '';
    };
    1: {
        startTime: Date | '';
        endTime: Date | '';
    };
    2: {
        startTime: Date | '';
        endTime: Date | '';
    };
    3: {
        startTime: Date | '';
        endTime: Date | '';
    };
    4: {
        startTime: Date | '';
        endTime: Date | '';
    };
    5: {
        startTime: Date | '';
        endTime: Date | '';
    };
    6: {
        startTime: Date | '';
        endTime: Date | '';
    };
};

export type AgentListing = {
    agents: Array<UserOnAgentListing>;
    consumers?: Array<UserOnAgentListing>;
    listingId: BSON.ObjectId;
    approvalSettings: {
        unverified: {
            allowed: boolean;
            hiddenId?: string;
        };
        approvalType: ApprovalType;
    };
    noticeRequired?: number;
    maxDuration?: number;
    allowOverlap?: boolean;
    availability: {
        recurring: RecurringAvailability;
        rules?: Array<{
            start: Date;
            end: Date;
        }>;
    };
    approvals?: Array<Approvals>;
    type: ListingScheduleType;
    lockboxLocation: string;
    lockboxType: LockBoxType;
    lockCombo: string;
    lockComboExpiration?: Date;
    messageBlast?: string;
    showingInstructions?: string;
};

export const formatMaxShowingDuration = {
    0: '15 Minutes',
    30: '30 Minutes',
    60: '1 Hour',
    120: '2 Hours',
};
export const formatNoticeRequired = {
    0: 'None',
    60: '1 Hour',
    120: '2 Hours',
    1440: '1 Day',
};

export type functionError = {
    _id: {
        functionName: string;
        application: string;
    };
    errorCount: number;
};
