import { RemoteMongoReadOperation, Stitch } from 'mongodb-stitch-browser-sdk';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AGENTS, COLLECTION, STATUS } from '../../store/api/constants';
import { findRecords, getDatabase, getPhoto } from '../../store/api/sagas';
import * as Actions from './actions';

export function* fetchAgents({
    first,
    last,
    agentStatus,
}: ReturnType<typeof Actions.fetchAgentsRequested>) {
    try {
        const searchRESP = yield call(search, Stitch.defaultAppClient, first, last, agentStatus);
        if (searchRESP) {
            yield put(Actions.fetchAgentsSucceeded(searchRESP));
        } else {
            throw new Error('Failed to fetch agents');
        }
    } catch (error) {
        yield put(Actions.fetchAgentsFailed(error));
    }
}

export function* fetchMarkets() {
    try {
        const marketsCollection = getDatabase().collection(COLLECTION.Markets);
        const marketsCursor: RemoteMongoReadOperation<any> = yield call([
            marketsCollection,
            marketsCollection.find,
        ]);
        const markets = yield call([marketsCursor, marketsCursor.toArray]);
        if (markets) {
            yield put(Actions.fetchMarketsSucceeded(markets));
        } else {
            throw new Error('No markets were found');
        }
    } catch (error) {
        yield put(Actions.fetchMarketsFailed(error));
    }
}

export function* search(client: any, firstName: string, lastName: string, agentStatus: any) {
    try {
        //DO NOT REMOVE
        // if (firstName === 'Lightning' && lastName === 'McQueen') {
        //     return defaultAgents;
        // }

        //The variables below are used to create a pipeline. This will need to be
        //moved to a stich function, however I do not have the permisions to do so,
        //so I left it in the code for now
        const firstPipeline = {
            $match: {
                firstName: {
                    $regex: `^${firstName}`,
                    $options: 'im',
                },
            },
        };
        const lastPipeline = {
            $match: {
                lastName: {
                    $regex: `^${lastName}`,
                    $options: 'im',
                },
            },
        };
        //This limit stage was my attempt to load a smaller number of agents from the
        //collection. It is at 25 currently because Andrew Coca wants 25 agents per page
        const limitPipeline = {
            $limit: 25,
        };

        //agentsPipline holds the total pipeline used to search the agents collection
        const agentsPipeline = [firstPipeline, lastPipeline, limitPipeline];

        //Calls a function in store/api/sagas.js to generate a Pipeline asynchronously
        let response = yield getDatabase().collection(AGENTS).aggregate(agentsPipeline);

        //The below map function is used to add a new field to the response called photo
        //To do this, the path of the photo connected to the agent is sent to the function
        //getPhoto, found in store/api/sagas.js, which returns an object containing
        //the mime of the photo and the base64 representation. The list with the new field
        //is Photoreturned to the Search page in /dash/Search/index.js to be loaded into
        //ProfileCard components visa a map function.
        response = yield all(
            response.map(async (agent: any) => {
                const { profilePhotoUpload: { path = null } = {} } = agent;
                if (path) {
                    agent.photo = await getPhoto(path);
                    return agent;
                }
                return agent;
            }),
        );
        return response;
    } catch (error) {
        console.log(error);
    }
}

export function* fetchMls({}) {
    // try {
    //     const mlsList = yield call(findRecords, 'mls', { _id: { $exists: true } });
    //     console.log(error);
    // } catch (error) {
    //     console.log(error);
    // }
}

export function* sagaDash() {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === Actions.DASH_ACTIONS.FetchMarkets &&
                action.status === STATUS.Requested,
            fetchMarkets,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === Actions.DASH_ACTIONS.FetchAgents &&
                action.status === STATUS.Requested,
            fetchAgents,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === Actions.DASH_ACTIONS.FetchMls && action.status === STATUS.Requested,
            fetchMls,
        ),
    ]);
}
