import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import styles from './index.module.css';
import classnames from 'classnames';
import { functionError } from '../../../../utils/constants';
import { getSelectedError, getLineGraphData, getObjectIds, getErrorMarks } from '../selectors';
import { LineGraph } from '../../../../components';
import ErrorMarkCard from '../ErrorMarkCard';

const mapStateToProps = (state: any) => ({
    selectedFunction: getSelectedError(state),
    graphData: getLineGraphData(state),
    objectIds: getObjectIds(state),
    errorMarks: getErrorMarks(state),
});

const connector = connect(mapStateToProps);

type errorDataPoint = {
    date: Date;
    stringDate: string;
    value: number;
};
type FunctionErrorScreenProps = {
    selectedFunction: functionError;
    graphData: Array<errorDataPoint>;
    objectIds: Array<string>;
    errorMarks: Array<any>;
};
const FunctionErrorScreen = ({
    selectedFunction,
    graphData,
    objectIds,
    errorMarks,
}: FunctionErrorScreenProps) => {
    const rootStyle = classnames(styles.root);
    return (
        <div className={rootStyle}>
            <div className={styles.row}>
                <div className={styles.graph}>
                    <LineGraph
                        dataSet={graphData}
                        functionName={selectedFunction?._id?.functionName}
                    />
                </div>
                <div className={styles.errorMark}>
                    {errorMarks.length > 0 && (
                        <>
                            <div className={styles.header}>
                                <div className={styles.row}>
                                    <div className={styles.text}>Error Mark</div>
                                    <div className={styles.text}>Count</div>
                                </div>
                            </div>
                            {errorMarks.map((location) => {
                                return (
                                    <ErrorMarkCard
                                        errorMark={location.errorMark}
                                        count={location.count}
                                    />
                                );
                            })}
                        </>
                    )}
                    {objectIds.length > 0 && (
                        <div>
                            <div>Object Ids</div>
                            {objectIds.map((objectId) => {
                                return <div>{objectId}</div>;
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default connector(FunctionErrorScreen);
