import { ErrorObject, serializeError } from 'serialize-error';
import { STATUS } from '../../../store/api/constants';
import { AdminActions, ADMIN_ACTIONS } from './actions';

export type AdminState = {
    loading: boolean;
    adminUsers: Array<any>;
    error: ErrorObject | null;
};

export const initialState: AdminState = {
    loading: false,
    adminUsers: [],
    error: null,
};

export function reducerAdminManagement(state = initialState, action: AdminActions): AdminState {
    switch (action.type) {
        case ADMIN_ACTIONS.FetchAdminUsers: {
            switch (action.status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { adminUsers } = action;
                    return {
                        ...state,
                        loading: false,
                        adminUsers,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        default:
            return state;
    }
}
