import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getAccount } from '../../../redux';
import { STATUS } from '../../../store/api/constants';
import * as Actions from './actions';
import * as CONSTANTS from './constants';
import {
    callStitchFunctionRealm,
    connectListingInDB,
    createLoggedAgentInDB,
    deleteAgentInDB,
    getAdminCreds,
    getRealmClient,
    insertListingInDB,
    queryForAgent,
    queryForBrokerage,
    queryForListing,
} from '../../../store/api/sagas';
import { AgentListing, UserOnAgentListing } from '../../../utils/constants';

export function* createLoggedAgent(params) {
    try {
        const { agentData, environment } = params;

        // Validate email
        // Should probably be done before form submission
        let re = /\S+@\S+\.\S+/;
        if (!re.test(agentData.email)) {
            throw new Error('Invalid email address');
        }

        // Validate phone
        // Should probably be done before form submission
        if (agentData.phone.substring(0, 2) !== '+1' || agentData.phone.length !== 12) {
            throw new Error('Illegal phone format');
        }

        // Get the users admin creds
        const account = yield select(getAccount);
        const { appId, apiKey } = getAdminCreds(account, environment);

        // Create the agentData
        const agentDoc = CONSTANTS.agentDocument(agentData);
        if (agentDoc === undefined || agentDoc === null) {
            throw new Error('Agent could not be formatted as provided');
        }

        //Insert the agent
        yield call(createLoggedAgentInDB, appId, apiKey, agentDoc);

        // No Errors? Success!
        yield put(Actions.createLoggedAgentSucceeded());
    } catch (e) {
        yield put(Actions.createLoggedAgentFailed(e));
    }
}

export function* searchAgents(params) {
    try {
        const { searchText, searchType, environment } = params;

        // Format match stage based on searchType
        // This call is going to leverage the brokerageMatch param that
        // exists in textSearchAgents. It requires a list of clauses
        // that are inserted into an $or stage
        // Not sure why it was set up that way...
        var matchStage = null;
        switch (searchType) {
            case 'Internal':
            case 'External':
                matchStage = [{ testingType: searchType }];
                break;
            case 'both':
                matchStage = [{ testingType: 'Internal' }, { testingType: 'External' }];
                break;
            // Leave match stage as null for any
        }

        // Get the users admin creds
        const account = yield select(getAccount);
        const { appId, apiKey } = getAdminCreds(account, environment);

        // Get the client for text search
        const client = yield call(getRealmClient, appId, apiKey);

        const agents = yield call(
            callStitchFunctionRealm,
            client,
            'textSearchAgents',
            searchText,
            null,
            matchStage,
            '',
            false,
        );

        // No Errors? Great Success!
        yield put(Actions.agentSearchCrossEnvSucceeded(agents));
    } catch (e) {
        yield put(Actions.agentSearchCrossEnvFailed(e));
    }
}

export function* searchListings(params) {
    try {
        const { searchText, searchType, environment } = params;

        // Get the users admin creds
        const account = yield select(getAccount);
        const { appId, apiKey } = getAdminCreds(account, environment);

        var listings = [];
        // If the search type is address, use textSearchListings,
        // other wise simply match by the listingId
        if (searchType === 'Address') {
            // Get the client for text search
            const client = yield call(getRealmClient, appId, apiKey);
            listings = yield call(
                callStitchFunctionRealm,
                client,
                'textSearchListings',
                searchText,
                true,
            );
        } else {
            listings = yield call(queryForListing, appId, apiKey, searchText);
        }

        // No Errors? Great Success!
        yield put(Actions.listingSearchCrossEnvSucceeded(listings));
    } catch (e) {
        yield put(Actions.listingSearchCrossEnvFailed(e));
    }
}

export function* deleteAgent(params) {
    try {
        const { agentObjectId, environment } = params;

        if (!agentObjectId) {
            throw new Error('Missing agent object id');
        }

        // Get the users admin creds
        const account = yield select(getAccount);
        const { appId, apiKey } = getAdminCreds(account, environment);

        yield call(deleteAgentInDB, appId, apiKey, agentObjectId);

        // No Errors? Great Success!
        yield put(Actions.deleteAgentSucceeded());
    } catch (e) {
        yield put(Actions.deleteAgentFailed(e));
    }
}

export function* attributeListings(params) {
    try {
        // Param extraction and validation
        const { listingData, agentData, environment } = params;
        if (!listingData) {
            throw new Error('Missing listing data');
        }
        if (!agentData) {
            throw new Error('Missing agent data');
        }

        // Get the users admin creds
        const account = yield select(getAccount);
        const { appId, apiKey } = getAdminCreds(account, environment);

        // Get the full listing
        var listing = yield call(
            queryForListing,
            appId,
            apiKey,
            listingData.mlsList[0].listingId,
            listingData.mlsList[0].mlsName,
        );

        // extract from array and remove object id
        listing = listing[0];
        const { _id, ...rest } = listing;
        listing = rest;

        // Get the brokerage in order to put the
        // correct name on the listing
        var brokerage = yield call(
            queryForBrokerage,
            appId,
            apiKey,
            agentData.markets[0].brokerageId,
            agentData.markets[0].mlsName,
        );
        brokerage = brokerage[0];

        // Format the new listing
        listing = CONSTANTS.listingDocument(listing, agentData, brokerage);

        //  Insert the listing
        yield call(insertListingInDB, appId, apiKey, listing);

        // No Errors? Great Success!
        yield put(Actions.attributeListingSucceeded());
    } catch (e) {
        yield put(Actions.attributeListingFailed(e));
    }
}
export function* connectListing(params: ReturnType<typeof Actions.connectListingRequested>) {
    try {
        // Param extraction and validation
        var { agentListingData, environment } = params;

        // Param check
        if (!agentListingData) {
            throw new Error('Missing agentListing data');
        }

        // Get the users admin creds
        const account = yield select(getAccount);
        const { appId, apiKey } = getAdminCreds(account, environment);

        // Format the agentListing
        // Fetch some more info about the agent for the agents field
        var agentListing: AgentListing = {
            ...agentListingData,
            agents: yield all(
                agentListingData.agentsData.map(
                    async (agentData: any, index: number): Promise<UserOnAgentListing> => {
                        const result = await queryForAgent(
                            appId,
                            apiKey,
                            agentData.agent.agentMlsId,
                            agentData.agent.mlsName,
                        );
                        return {
                            _id: result._id,
                            canApprove: agentData.canApprove,
                            notificationSettings: agentData.notificationSettings,
                            firstName: result.firstName,
                            lastName: result.lastName,
                            phoneNumber: result.phoneNumber,
                            email: result.email,
                            type: index === 0 ? 'Listing Agent' : 'Co-Listing Agent',
                        };
                    },
                ),
            ),
        };

        // Clean up some things that were spread in and no longer needed
        const { agentsData, ...rest } = agentListing as any;
        agentListing = rest;

        // Update the listing
        yield call(connectListingInDB, appId, apiKey, agentListing);

        // No Errors? Great Success!
        yield put(Actions.connectListingSucceeded());
    } catch (e) {
        yield put(Actions.connectListingFailed(e));
    }
}

export function* sagaData() {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === Actions.DATA_ACTIONS.CreateLoggedAgent &&
                action.status === STATUS.Requested,
            createLoggedAgent,
        ),
    ]);
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === Actions.DATA_ACTIONS.SearchAgent &&
                action.status === STATUS.Requested,
            searchAgents,
        ),
    ]);

    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === Actions.DATA_ACTIONS.SearchListing &&
                action.status === STATUS.Requested,
            searchListings,
        ),
    ]);

    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === Actions.DATA_ACTIONS.DeleteAgent &&
                action.status === STATUS.Requested,
            deleteAgent,
        ),
    ]);

    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === Actions.DATA_ACTIONS.AttributeListing &&
                action.status === STATUS.Requested,
            attributeListings,
        ),
    ]);
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === Actions.DATA_ACTIONS.ConnectListing &&
                action.status === STATUS.Requested,
            connectListing,
        ),
    ]);
}
