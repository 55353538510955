import styles from './index.module.css';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, RandomLoader } from '../../../../../../components';
import {
    AgentListing,
    ApprovalType,
    ListingScheduleType,
    LockBoxType,
    RecurringAvailability,
} from '../../../../../../utils/constants';
import { connectListingRequested, listingSearchCrossEnvRequested } from '../../../actions';
import { EnvironmentType, ListingSearchType, defaultStartEnd } from '../../../constants';
import { getDataError, getDataLoading, getFoundListings } from '../../../selectors';
import { ListingCard } from '../../Utilities/ListingCard';

type ConnectListingPageProps = { environment: EnvironmentType };

const ConnectListingPage = (props: ConnectListingPageProps) => {
    const { environment } = props;

    const [searchTextListing, setSearchTextListing] = useState<string>('');
    const [searchTypeListing, setSearchTypeListing] = useState<ListingSearchType>('Address');
    const [selectedListing, setSelectedListing] = useState<any>(null);
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
    const [hasSubmitted, setHasSubmitted] = useState<any>(null);
    const [submittedData, setSubmittedData] = useState<any>(null);
    const [allowOverlap, setAllowOverlap] = useState<boolean>(false);
    const [approvalType, setApprovalType] = useState<ApprovalType>(Object.values(ApprovalType)[0]);
    const [allowUnverified, setAllowUnverified] = useState<boolean>(false);
    const [lockboxLocation, setLockboxLocation] = useState<string>('');
    const [lockboxType, setLockboxType] = useState<LockBoxType>(Object.values(LockBoxType)[0]);
    const [lockCombo, setLockCombo] = useState<string>('');
    const [maxDuration, setMaxDuration] = useState<number>(30);
    const [noticeRequired, setNoticeRequired] = useState<number>(0);
    const [showingInstructions, setShowingInstructions] = useState<string>('');
    const [type, setType] = useState<ListingScheduleType>(Object.values(ListingScheduleType)[0]);
    const [weeklyAvailability, setWeeklyAvailability] =
        useState<RecurringAvailability>(defaultStartEnd);

    const dispatch = useDispatch();
    const listingSearch = useCallback(
        (searchText: string, searchType: ListingSearchType, environment: EnvironmentType) =>
            dispatch(listingSearchCrossEnvRequested(searchText, searchType, environment)),
        [],
    );
    const connectListing = useCallback(
        (
            selectedListing,
            noticeRequired,
            allowOverlap,
            approvalType,
            allowUnverified,
            weeklyAvailability,
            lockboxType,
            lockCombo,
            lockboxLocation,
            maxDuration,
            showingInstructions,
            type,
            environment: EnvironmentType,
        ) => {
            dispatch(
                connectListingRequested(
                    {
                        agentsData: [
                            {
                                agent: {
                                    agentMlsId: selectedListing.mlsList[0].agentMlsId,
                                    mlsName: selectedListing.mlsList[0].mlsName,
                                },
                                canApprove: true,
                                notificationSettings: ['text', 'email'],
                            },
                        ],
                        noticeRequired,
                        allowOverlap,
                        approvalSettings: {
                            approvalType,
                            unverified: {
                                allowed: allowUnverified,
                                hiddenId: allowUnverified
                                    ? selectedListing.agentListing?.approvalSettings?.unverified
                                          ?.hiddenId ||
                                      Math.floor(Math.random() * 10000000000000000000).toString()
                                    : undefined,
                            },
                        },
                        availability: { recurring: weeklyAvailability },
                        lockboxLocation,
                        lockboxType,
                        lockCombo: lockCombo,
                        maxDuration,
                        showingInstructions,
                        type,
                        listingId: selectedListing._id,
                    },
                    environment,
                ),
            );
        },
        [],
    );
    const foundListings = useSelector(getFoundListings);
    const dataErrors = useSelector(getDataError);
    const dataLoading = useSelector(getDataLoading);

    // If anything regarding the search changes, search again
    useEffect(() => {
        setSelectedListing(null);
        if (searchTextListing.length) {
            listingSearch(searchTextListing, searchTypeListing, environment);
        }
    }, [searchTextListing, searchTypeListing, environment]);

    // If the listing is already connected, load in that data
    useEffect(() => {
        if (selectedListing?.agentListing) {
            const { agentListing }: { agentListing: AgentListing } = selectedListing;
            setType(agentListing.type);
            setMaxDuration(agentListing.maxDuration);
            setNoticeRequired(agentListing.noticeRequired);
            setAllowOverlap(agentListing.allowOverlap);
            setShowingInstructions(agentListing.showingInstructions || '');
            setWeeklyAvailability(agentListing.availability.recurring);
            setApprovalType(agentListing.approvalSettings.approvalType);
            setAllowUnverified(agentListing.approvalSettings.unverified.allowed);
            setLockboxType(agentListing.lockboxType);
            setLockboxLocation(agentListing.lockboxLocation);
            setLockCombo(agentListing.lockCombo);
        } else if (selectedListing === null) {
            setType(Object.values(ListingScheduleType)[0]);
            setMaxDuration(30);
            setNoticeRequired(0);
            setAllowOverlap(false);
            setShowingInstructions('');
            setWeeklyAvailability(defaultStartEnd);
            setApprovalType(Object.values(ApprovalType)[0]);
            setAllowUnverified(false);
            setLockboxType(Object.values(LockBoxType)[0]);
            setLockboxLocation('');
            setLockCombo('');
        }
    }, [selectedListing]);

    return (
        <Col>
            {dataErrors && (
                <div className={styles.errorText1}>
                    An error has occurred:{' '}
                    {dataErrors.errorCode || dataErrors.error || dataErrors.message}
                </div>
            )}
            {dataErrors?.link && (
                <div className={styles.errorText1}>
                    {`Give this link to a dev: ${dataErrors.link}`}
                </div>
            )}
            {hasSubmitted && !dataErrors && !dataLoading && (
                <div className={styles.successText1}>
                    {`The listing at ${submittedData.address.full} has been connected in ${submittedData.environment}. Great Success!!!`}
                </div>
            )}
            {hasSubmitted && dataLoading && (
                <div style={{ height: 100, width: 100 }}>
                    <RandomLoader />
                </div>
            )}
            <Button
                buttonText={'Info'}
                onClick={() => {
                    setShowMoreInfo(!showMoreInfo);
                }}
            />
            {showMoreInfo && (
                <div style={{ fontWeight: 900, paddingBottom: 50 }}>
                    <Row>
                        {`Connecting a listing will connect the listing to the listing agent currently on the listing. `}
                    </Row>
                    <Row>
                        {`Listings must be attributed to an agent by the mls to connect in production. `}
                    </Row>
                    <Row>
                        {`If you want to connect it to a specific agent, you need to attribute a test listing to another agent and connect it that way. `}
                    </Row>
                    <Row>{`Availability will use a default 8am to 8pm `}</Row>
                </div>
            )}
            <Row>
                <Col>
                    <div className={styles.colContainerListing}>
                        Choose a listing:
                        <div
                            onChange={(event) => {
                                const value: any = (event.target as HTMLInputElement).value;
                                setSearchTypeListing(value);
                            }}
                            className={styles.radioContainer}
                        >
                            <label>
                                <input
                                    type="radio"
                                    value="Address"
                                    name="Address"
                                    checked={searchTypeListing === 'Address'}
                                />
                                Address
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="ListingId"
                                    name="ListingId"
                                    checked={searchTypeListing === 'ListingId'}
                                />
                                Listing Id
                            </label>
                        </div>
                    </div>
                    <input
                        value={searchTextListing}
                        onChange={(event) => setSearchTextListing(event.target.value)}
                        placeholder={searchTypeListing}
                    />
                    {/* Selected Listing Modal */}
                    {selectedListing && (
                        <div className={styles.selectedModalRoot}>
                            <Col>
                                <div className={styles.selectedModalHeader}>
                                    {`Connect ${selectedListing.address.full} in ${environment}?`}
                                </div>
                                <Row>
                                    <Col className={styles.modalText}>
                                        <div>Address: {selectedListing.address.full}</div>
                                        <div>City: {selectedListing.address.city}</div>
                                        <div>State: {selectedListing.address.state}</div>
                                    </Col>
                                    <Col className={styles.modalText}>
                                        <div>
                                            {selectedListing.mlsList.map((mls, index) => {
                                                return (
                                                    <Col key={index}>
                                                        <div>{`Listing Id: ${mls.listingId}`}</div>
                                                        <div>{`MLS Name: ${mls.mlsName}`}</div>
                                                        <div>
                                                            {`Agent Mls Id: ${mls.agentMlsId}`}
                                                        </div>
                                                        <div>
                                                            {`Brokerage Id: ${mls.brokerageId}`}
                                                        </div>
                                                    </Col>
                                                );
                                            })}
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{ fontWeight: 900 }}>Showing Options:</div>
                                <div className={styles.optionsContainer}>
                                    <Row>
                                        <Col>
                                            <div>{`Scheduling Type:`}</div>
                                            <Dropdown
                                                options={Object.values(ListingScheduleType)}
                                                handleClick={(value) => {
                                                    setType(value);
                                                }}
                                                className={''}
                                                selectedOption={type}
                                            />
                                            <div>{`Max Duration (mins.):`}</div>
                                            <Dropdown
                                                options={[15, 30, 60, 120]}
                                                handleClick={(value) => {
                                                    setMaxDuration(value);
                                                }}
                                                className={''}
                                                selectedOption={maxDuration}
                                            />
                                            <div>{`Notice Required (mins.):`}</div>
                                            <Dropdown
                                                options={[0, 60, 120, 1440]}
                                                handleClick={(value) => {
                                                    setNoticeRequired(value);
                                                }}
                                                className={''}
                                                selectedOption={noticeRequired}
                                            />
                                            <div className={styles.radioContainer}>
                                                <label>
                                                    <input
                                                        onClick={() => {
                                                            setAllowOverlap(!allowOverlap);
                                                        }}
                                                        type="radio"
                                                        value="allowOverlap"
                                                        name="allowOverlap"
                                                        checked={allowOverlap}
                                                    />
                                                    Allow Overlap
                                                </label>
                                            </div>
                                        </Col>
                                        {/* Approval type and unverified */}
                                        <Col>
                                            <div>Approval Type:</div>
                                            <Dropdown
                                                options={Object.values(ApprovalType)}
                                                handleClick={(value) => {
                                                    setApprovalType(value);
                                                }}
                                                className={''}
                                                selectedOption={approvalType}
                                            />
                                            <div className={styles.radioContainer}>
                                                <label>
                                                    <input
                                                        onClick={() => {
                                                            setAllowUnverified(!allowUnverified);
                                                        }}
                                                        type="radio"
                                                        value="allowUnverified"
                                                        name="allowUnverified"
                                                        checked={allowUnverified}
                                                    />
                                                    Allow Unverified
                                                </label>
                                            </div>
                                        </Col>
                                        {/* LockBox info */}
                                        <Col>
                                            <div>Lock Box Type:</div>
                                            <Dropdown
                                                options={Object.values(LockBoxType)}
                                                handleClick={(value) => {
                                                    setLockboxType(value);
                                                }}
                                                className={''}
                                                selectedOption={lockboxType}
                                            />
                                            <div>
                                                {`Lock Box Location: `}
                                                <input
                                                    value={lockboxLocation}
                                                    onChange={(event) =>
                                                        setLockboxLocation(event.target.value)
                                                    }
                                                    placeholder={'Front door'}
                                                />
                                            </div>
                                            <div>
                                                {`Lock Box Combo: `}
                                                <input
                                                    value={lockCombo}
                                                    onChange={(event) =>
                                                        setLockCombo(event.target.value)
                                                    }
                                                    placeholder={'1757'}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* Showing Instructions */}
                                    <Col>
                                        {`Showing Instructions: `}
                                        <textarea
                                            value={showingInstructions}
                                            onChange={(event) =>
                                                setShowingInstructions(event.target.value)
                                            }
                                            placeholder={
                                                'Please remove your shoes and make sure to close the back door. Have a great showing'
                                            }
                                        />
                                    </Col>
                                </div>
                                <Row>
                                    <Button
                                        buttonText={'Confirm'}
                                        className={styles.confirmButton}
                                        onClick={() => {
                                            connectListing(
                                                selectedListing,
                                                noticeRequired,
                                                allowOverlap,
                                                approvalType,
                                                allowUnverified,
                                                weeklyAvailability,
                                                lockboxType,
                                                lockCombo,
                                                lockboxLocation,
                                                maxDuration,
                                                showingInstructions,
                                                type,
                                                environment,
                                            );
                                            setSubmittedData({ ...selectedListing, environment });
                                            setSelectedListing(null);
                                            setHasSubmitted(true);
                                        }}
                                    />
                                    <Button
                                        buttonText={'Cancel'}
                                        className={styles.cancelButton}
                                        onClick={() => setSelectedListing(null)}
                                    />
                                </Row>
                            </Col>
                        </div>
                    )}
                    {/* Listing Cards */}
                    {foundListings.length !== 0 ? (
                        <div className={styles.cardContainer}>
                            {foundListings.map((listing, index) => {
                                return (
                                    <ListingCard
                                        listing={listing}
                                        index={index}
                                        setSelectedListing={setSelectedListing}
                                    />
                                );
                            })}
                        </div>
                    ) : null}
                </Col>
            </Row>
        </Col>
    );
};

export default ConnectListingPage;
