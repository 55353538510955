import { Stitch } from 'mongodb-stitch-browser-sdk';
import { call, put } from 'redux-saga/effects';
//import { loginSucceeded } from 'web-lib/dist/redux/auth';
import { fetchAdminAccount } from '../store/api/sagas';
import { initialization, initializationFailed } from '../redux/auth/actions';

export function* init() {
    try {
        if (process.env.REACT_APP_STITCH_APP_ID === undefined) {
            throw Error('Stitch App ID undefined.');
        }
        yield call(Stitch.initializeDefaultAppClient, process.env.REACT_APP_STITCH_APP_ID);
        const client = Stitch.defaultAppClient;
        yield put(initialization());
    } catch (error) {
        yield put(initializationFailed(error));
    }
}
