import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import classnames from 'classnames';

type ErrorMarkCardProps = {
    errorMark: number;
    count: number;
};
const ErrorMarkCard = ({ errorMark, count }: ErrorMarkCardProps) => {
    // get card background color based on how many errors there are
    return (
        <div className={styles.root}>
            <div className={styles.row}>
                <div className={classnames(styles.text, styles.firstText)}>{errorMark}</div>
                <div className={classnames(styles.text, styles.secondText)}>{count}</div>
            </div>
        </div>
    );
};

export default ErrorMarkCard;
