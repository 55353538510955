import React from 'react';
import styles from './index.module.css';
import ListingCard from '../../../../../components/ListingCard';
import { RandomLoader } from '../../../../../components/';

type ListingCardsProps = {
    listings: any[];
    loading: boolean;
};

export const ListingCards = ({ listings, loading }: ListingCardsProps) => {
    return (
        <div className={styles.root}>
            {listings ? (
                <div className={styles.scrollBox}>
                    {listings?.length > 0 ? (
                        <div className={styles.scrollBoxInside}>
                            {listings.map((listing: any, index: number) => {
                                return (
                                    <ListingCard
                                        key={index}
                                        propertyData={listing}
                                        showSchedule={false}
                                        onClickDetails={() => {
                                            window.open('https://youtu.be/dQw4w9WgXcQ?t=1');
                                        }}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <div className={styles.noListings}>No Listings for this Agent.</div>
                    )}
                </div>
            ) : (
                <div className={styles.loader}>
                    <RandomLoader />
                </div>
            )}
        </div>
    );
};

export default ListingCards;
