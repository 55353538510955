import { DateTime } from 'luxon';

/**
 * returns an array of time in the format of
 * [ 8am, 8:15, 8:30, 8:45, 9am ]...
 * used for the Schedule showing modal to display available/unavailable time slots for a showing
 */
export const getShowingTimeForDisplay = () => {
    let times = [];
    let d = DateTime.fromMillis(Date.now()).set({ hour: 8, minute: 0, second: 0 }).toJSDate();

    for (let hour = 5; hour < 23; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            d = DateTime.fromMillis(Date.now())
                .set({ hour, minute, second: 0 })
                .toJSDate({ hour, minute });
            times.push(DateTime.fromJSDate(d).toFormat(`h${minute === 0 ? 'a' : ':mma'}`));
        }
    }
    times.push('11PM');

    return times;
};

/**
 * returns an array of dates in the format of
 * [{ day: 'Sun', date: 16}, { day: 'Mon', date: 17 } ...]
 * used for selecting the date in the showing modal
 */
export const getShowingDatesForDisplay = () => {
    let dates = [];
    let d = DateTime.fromMillis(Date.now()).toJSDate();

    for (let i = 0; i < 7; i++) {
        d = DateTime.fromMillis(Date.now()).plus({ days: i }).toJSDate();
        dates.push({
            month: d.getMonth(),
            day: DateTime.fromJSDate(d).toFormat(`EEE`),
            date: DateTime.fromJSDate(d).toFormat(`d`),
        });
    }

    return dates;
};

/**
 * returns an array of time in the format of
 * [ 8am, 8:15am, 8:30am, 8:45am, 9am ]...
 * used for the Schedule showing modal to display available/unavailable time slots for a showing
 *
 * if isConnected is true, the range is 7am-9pm, if not it's 8am-8pm
 */
export const getAvailableShowingTimes = (isConnected) => {
    let times = [];
    let d = DateTime.fromMillis(Date.now()).set({ hour: 5, minute: 0, second: 0 }).toJSDate();

    let start = 8,
        finish = 20,
        finalPush = '8:00PM';
    if (isConnected) {
        start = 5;
        finish = 21;
        finalPush = '9:00PM';
    }

    for (let hour = start; hour < finish; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            d = DateTime.fromMillis(Date.now())
                .set({ hour, minute, second: 0 })
                .toJSDate({ hour, minute });
            times.push(DateTime.fromJSDate(d).toFormat(`h:mma`));
        }
    }
    times.push(finalPush);
    return times;
};
