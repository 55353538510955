import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getMarkets } from '../../../domains/dash/selectors';
import { fetchMarketsRequested } from '../../../domains/dash/actions';
import styles from './index.module.css';
import { ButtonTabBar, SupportDash } from '../../../components';

const mapStateToProps = (state: any) => ({
    markets: getMarkets(state),
});

const mapDispatchToProps = {
    fetchMarketsRequested: fetchMarketsRequested,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

function Support({ markets }: any) {
    const buttonOptions: { name: string; page: string }[] = [
        { name: 'Support Dashboard', page: 'supportDash' },
    ];
    const [page, setPage] = useState<string>('supportDash');

    return (
        <div className={styles.root}>
            <ButtonTabBar buttonNames={buttonOptions} setPage={setPage} currPage={page} />
            <div className={styles.section}>
                {
                    {
                        supportDash: <SupportDash markets={markets} />,
                    }[page]
                }
            </div>
        </div>
    );
}

export default connector(Support);
