import { STATUS } from '../../store/api/constants';

export enum AUTH_ACTION {
    Login = '[Auth] Login',
    Logout = '[Auth] Logout',
    StitchUserActions = '[Auth] Stitch User Actions',
    Initialization = '[Auth] Initialization',
}

// ---------------------------------------------------------------------------------------

export function loginRequested(username: string, password: string, history: any) {
    return {
        type: AUTH_ACTION.Login,
        status: STATUS.Requested,
        username,
        password,
        history,
    } as const;
}

export function loginSucceeded(account: any) {
    return {
        type: AUTH_ACTION.Login,
        status: STATUS.Succeeded,
        account,
    } as const;
}

export function loginFailed(error: Error) {
    return { type: AUTH_ACTION.Login, status: STATUS.Failed, error } as const;
}

export type LoginAction =
    | ReturnType<typeof loginRequested>
    | ReturnType<typeof loginSucceeded>
    | ReturnType<typeof loginFailed>;

// ---------------------------------------------------------------------------------------

export function logoutRequested() {
    return { type: AUTH_ACTION.Logout, status: STATUS.Requested } as const;
}

export function logoutSucceeded() {
    return { type: AUTH_ACTION.Logout, status: STATUS.Succeeded } as const;
}

export function logoutFailed(error: Error) {
    return { type: AUTH_ACTION.Logout, status: STATUS.Failed, error } as const;
}

export type LogoutAction =
    | ReturnType<typeof logoutRequested>
    | ReturnType<typeof logoutSucceeded>
    | ReturnType<typeof logoutFailed>;

// ---------------------------------------------------------------------------------------

export function initialization() {
    return {
        type: AUTH_ACTION.Initialization,
        status: STATUS.Requested,
        initialization: true,
    } as const;
}

export function initializationDone(isAuthenticated: boolean) {
    return {
        type: AUTH_ACTION.Initialization,
        status: STATUS.Succeeded,
        isAuthenticated,
    } as const;
}

export function initializationFailed(error: any) {
    return { type: AUTH_ACTION.Initialization, status: STATUS.Failed, error } as const;
}

export type Initialization =
    | ReturnType<typeof initialization>
    | ReturnType<typeof initializationDone>
    | ReturnType<typeof initializationFailed>;

// ---------------------------------------------------------------------------------------

export function updateStitchUserSucceeded(client: any, stitchUser: any, stitchUserId: any) {
    return {
        type: AUTH_ACTION.StitchUserActions,
        status: STATUS.Succeeded,
        client,
        stitchUser,
        stitchUserId,
    } as const;
}

export type StitchUserActions = ReturnType<typeof updateStitchUserSucceeded>;

// ---------------------------------------------------------------------------------------

export type AuthAction = LoginAction | LogoutAction | StitchUserActions | Initialization;
