import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';
import ColoredButton from '../ColoredButton';
import { InitialsIcon } from '../InitialsIcon';

type adminData = {
    firstName: string;
    lastName: string;
};
export const SnackBar = ({ firstName, lastName }: adminData) => {
    return (
        <div className={styles.header}>
            <ColoredButton
                text="Schedule a Showing"
                onClick={() => {
                    console.warn('ButtonPressed');
                }}
            />
            <div className={styles.divider}></div>
            <InitialsIcon firstName={firstName} lastName={lastName} />
        </div>
    );
};

export default SnackBar;
