import { combineReducers } from 'redux';
import { reducerAuth as auth } from '../redux/auth';
import { reducerSearch } from '../domains/dash/AllSearches/reducers';
import { reducerSearch as reducerAgent } from '../domains/dash/AgentsPage/reducers';
import { reducerDash } from '../domains/dash/reducer';
import reducerDevelopment from '../domains/dash/Development/reducers';
import { reducerData } from '../domains/dash/Data/reducer';
import { reducerAdminManagement } from '../domains/dash/ManageAdmins/reducer';
import { reducerBrokerages } from '../domains/dash/BrokeragesPage/reducer';

// DEVELOPER NOTE: Please keep the reducers in alphabetical order.

export const RootReducer = combineReducers({
    adminManagement: reducerAdminManagement,
    agents: reducerAgent,
    auth,
    brokerages: reducerBrokerages,
    dash: reducerDash,
    data: reducerData,
    development: reducerDevelopment,
    searches: reducerSearch,
});

export type WebAdminState = ReturnType<typeof RootReducer>;
