import { all, put, takeLatest, call } from 'redux-saga/effects';
import { STATUS, BROKERAGES } from '../../../store/api/constants';
import * as Actions from './actions';
import { BSON, Stitch } from 'mongodb-stitch-browser-sdk';
import { callStitchFunction, findRecord, deleteOneFromCollection } from '../../../store/api/sagas';
import { unmaskPhoneNumber } from '../../../utils/mask';

export function* updateBrokerageUser({
    user,
    _id,
    callBack
}: ReturnType<typeof Actions.updateBrokerageUserRequested>){
    try {
        const client = Stitch.defaultAppClient;
        const { firstName, lastName, phoneNumber, role } = user;
        const result = yield call(
            callStitchFunction,
            client,
            'updateBrokerageUser',
            _id,
            firstName,
            lastName,
            unmaskPhoneNumber(phoneNumber),
            role
            );
            if (result) {
                if(callBack){callBack()}
                yield put(Actions.updateBrokerageUserSucceeded(result));
            } else {
                yield put(Actions.updateBrokerageUserFailed('Something went wrong, please contact support'));
            }
    } catch (error) {
        yield put(Actions.updateBrokerageUserFailed(error));
    }
}

export function* activateBrokerageSuite({
    brokerageId,
    action,
}: ReturnType<typeof Actions.activateBrokerageSuiteRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const result = yield call(
            callStitchFunction,
            client,
            'manageBrokerageSuiteActivation',
            brokerageId.toString(),
            action,
        );
        yield put(Actions.activateBrokerageSuiteSucceded(result));
    } catch (error) {
        yield put(Actions.activateBrokerageSuiteFailed(error));
    }
}

export function* createBrokerageUser({
    brokerageId,
    user,
    callBack,
}: ReturnType<typeof Actions.createBrokerageUserRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const result = yield call(
            callStitchFunction,
            client,
            'createBrokerageUser',
            brokerageId,
            user.firstName,
            user.lastName,
            user.email,
            unmaskPhoneNumber(user.phoneNumber),
            user.role,
        );
        if (callBack) {
            callBack();
        }

        yield put(Actions.createBrokerageUserSucceded(brokerageId, user));
    } catch (error) {
        yield put(Actions.createBrokerageUserFailed(error));
    }
}

export function* getAllBrokerageUsers({
    brokerageId,
    page,
}: ReturnType<typeof Actions.getAllBrokerageUsersRequested>) {
    try {
        let id = new BSON.ObjectId(brokerageId);
        const client = Stitch.defaultAppClient;
        const result = yield call(
            callStitchFunction,
            client,
            'fetchAllBrokerageUsers',
            page,
            10,
            '', //searchText is empty temporarily
            id,
        );

        yield put(Actions.getAllBrokerageUsersSucceded(result));
    } catch (error) {
        yield put(Actions.getAllBrokerageUsersFailed(error));
    }
}

export function* getAllBrokerageAgents({
    brokerageId,
    mlsName,
    page,
}: ReturnType<typeof Actions.getAllBrokerageAgentsRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const result = yield call(
            callStitchFunction,
            client,
            'fetchAllAgents',
            page, //pageNumber
            10, //pageSize
            '', //searchText
            null, // sort
            brokerageId, //brokerageId
            mlsName,
        );
        yield put(Actions.getAllBrokerageAgentsSucceded(result));
    } catch (error) {
        yield put(Actions.getAllBrokerageAgentsFailed(error));
    }
}

export function* manageShowinglyPlusForBrokerage({
    brokerageId,
    mlsName,
    action,
}: ReturnType<typeof Actions.activateShowinglyPlusRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        let result = yield call(
            callStitchFunction,
            client,
            'manageShowinglyPlusForBrokerage',
            brokerageId,
            mlsName,
            action,
        );
        if (result._id) {
            //a common field to check if the result is not an error
            yield put(Actions.activateShowinglyPlusSucceded(result));
        } else {
            yield put(Actions.activateShowinglyPlusFailed(result));
        }
    } catch (error) {
        yield put(Actions.activateShowinglyPlusFailed(error));
    }
}

export function* fetchAllBrokerages({
    page,
    searchParam,
}: ReturnType<typeof Actions.getAllBrokeragesRequested>) {
    let paramAsObject = {};
    let allBrokerages;
    let sortBy = null;
    const client = Stitch.defaultAppClient;

    if (searchParam?.length > 0 && searchParam !== '') {
        // paramAsObject = { path: 'name', query: '/'+ searchParam + '/i'}
        paramAsObject = searchParam;
        allBrokerages = yield call(
            callStitchFunction,
            client,
            'fetchAllBrokerages',
            page,
            50,
            paramAsObject, // searchParam
            sortBy,
        );
    } else {
        allBrokerages = yield call(callStitchFunction, client, 'fetchAllBrokerages', page, 50);
    }
    try {
        yield put(Actions.getAllBrokeragesSucceded(allBrokerages));
    } catch (error) {
        yield put(Actions.getAllBrokeragesFailed(error));
    }
}

export function* fetchSelectedBrokerage({
    brokerageId,
}: ReturnType<typeof Actions.fetchSelectedBrokerageRequested>) {
    try {
        const brokerage = yield call(findRecord, BROKERAGES, {
            'markets.brokerageId': brokerageId,
        });
        yield put(Actions.fetchSelectedBrokerageSucceeded(brokerage));
    } catch (error) {
        yield put(Actions.fetchSelectedBrokerageFailed(error));
    }
}

export function* fetchBrokerageUsers({
    brokerageId,
    page,
}: ReturnType<typeof Actions.fetchSelectedBrokerageUsersRequested>) {
    try {
        let id = new BSON.ObjectId(brokerageId);
        const client = Stitch.defaultAppClient;
        const result = yield call(
            callStitchFunction,
            client,
            'fetchAllBrokerageUsers',
            page,
            10,
            '',
            id,
        );

        yield put(Actions.fetchSelectedBrokerageUsersSucceeded(result));
    } catch (error) {
        yield put(Actions.fetchSelectedBrokerageUsersFailed(error));
    }
}

export function* fetchBrokerageAgents({
    brokerageId,
    page,
}: ReturnType<typeof Actions.fetchSelectedBrokerageAgentsRequested>) {
    try {
        const paramAsObject = { 'markets.brokerageId': brokerageId };
        const client = Stitch.defaultAppClient;
        const result = yield call(
            callStitchFunction,
            client,
            'fetchAllAgents',
            page,
            10,
            paramAsObject,
        );
        yield put(Actions.fetchSelectedBrokerageAgentsSucceeded(result));
    } catch (error) {
        yield put(Actions.fetchSelectedBrokerageAgentsFailed(error));
    }
}

export function* deleteUser({ userId, callback }: ReturnType<any>) {
    try {
        yield call(deleteOneFromCollection, userId, 'brokerageUsers');
        if (callback) {
            callback();
        }

        yield put(Actions.DeleteUserSucceeded());
    } catch (error) {
        yield put(Actions.DeleteUserFailed(error));
    }
}

export function* sagaBrokerages() {
    yield all([
        takeLatest((action: any) => {
            return (
                action.type === Actions.BROKERAGE_ACTIONS.updateBrokerageUser &&
                action.status === STATUS.Requested
            );
        }, updateBrokerageUser),

        takeLatest((action: any) => {
            return (
                action.type === Actions.BROKERAGE_ACTIONS.activateBrokerageSuite &&
                action.status === STATUS.Requested
            );
        }, activateBrokerageSuite),

        takeLatest((action: any) => {
            return (
                action.type === Actions.BROKERAGE_ACTIONS.createBrokerageUser &&
                action.status === STATUS.Requested
            );
        }, createBrokerageUser),
        takeLatest((action: any) => {
            return (
                action.type === Actions.BROKERAGE_ACTIONS.getAllBrokerageUsers &&
                action.status === STATUS.Requested
            );
        }, getAllBrokerageUsers),

        takeLatest((action: any) => {
            return (
                action.type === Actions.BROKERAGE_ACTIONS.getAllBrokerageAgents &&
                action.status === STATUS.Requested
            );
        }, getAllBrokerageAgents),
        takeLatest((action: any) => {
            return (
                action.type === Actions.BROKERAGE_ACTIONS.getAllBrokeragesRequested &&
                action.status === STATUS.Requested
            );
        }, fetchAllBrokerages),
        takeLatest((action: any) => {
            return (
                action.type === Actions.BROKERAGE_ACTIONS.activateShowinglyPlus &&
                action.status === STATUS.Requested
            );
        }, manageShowinglyPlusForBrokerage),
        takeLatest((action: any) => {
            return (
                action.type === Actions.BROKERAGE_ACTIONS.fetchSelectedBrokerage &&
                action.status === STATUS.Requested
            );
        }, fetchSelectedBrokerage),
        takeLatest((action: any) => {
            return (
                action.type === Actions.BROKERAGE_ACTIONS.fetchBrokerageUsers &&
                action.status === STATUS.Requested
            );
        }, fetchBrokerageUsers),
        takeLatest((action: any) => {
            return (
                action.type === Actions.BROKERAGE_ACTIONS.fetchBrokerageAgents &&
                action.status === STATUS.Requested
            );
        }, fetchBrokerageAgents),
        takeLatest(
            (action: any) =>
                action.type === Actions.BROKERAGE_ACTIONS.DeleteUser &&
                action.status === STATUS.Requested,
            deleteUser,
        ),
    ]);
}
