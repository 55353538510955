import { BSON, RemoteMongoClient, Stitch } from 'mongodb-stitch-browser-sdk';
import { AGENTS, BROKERAGES, COLLECTION, LISTINGS_COLLECTION } from './constants';
import * as Realm from 'realm-web';
import * as DATA_CONSTANTS from '../../domains/dash/Data/constants';
import { AgentListing } from '../../utils/constants';

export function getDatabase() {
    if (process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME === undefined) {
        throw Error('Database service name is undefined');
    }
    if (process.env.REACT_APP_STITCH_DATABASE_NAME === undefined) {
        throw Error('Database name is undefined');
    }
    return Stitch.defaultAppClient
        .getServiceClient(
            RemoteMongoClient.factory,
            process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME,
        )
        .db(process.env.REACT_APP_STITCH_DATABASE_NAME);
}
export function getCollection(collectionName) {
    const mongoClient = Stitch.defaultAppClient.getServiceClient(
        RemoteMongoClient.factory,
        process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME,
    );
    const db = mongoClient.db(process.env.REACT_APP_STITCH_DATABASE_NAME);
    return db.collection(collectionName);
}
/**
 * Attempt to parse the error for its string message.
 * If that fails, fall back to error name or as a last resort, show generic error message.
 * @param stitchError Instance of error object `StitchServiceError` caught from API
 * @returns {string}
 */
export function parseStitchServiceError(stitchError: Error) {
    try {
        return JSON.parse(stitchError.message);
    } catch {
        if (stitchError?.message && typeof stitchError.message === 'string') {
            return stitchError.message;
        } else {
            return stitchError?.name || 'Something Went Wrong.';
        }
    }
}

/**
 * Uses the account on global state to get credentials for the specified environment
 * @param environment The environment the user is trying to operate in
 * @returns Object containing the appId and apiKey for the current user
 */
export function getAdminCreds(
    account: any,
    environment: DATA_CONSTANTS.EnvironmentType,
): {
    appId: string;
    apiKey: string;
} {
    // Get the appId
    const appId = DATA_CONSTANTS.APP_IDS[environment];
    if (appId === undefined || appId === null) {
        throw new Error('No such environment');
    }

    // Get apiKey from the user
    const apiKey = account.adminKeys[environment];
    if (apiKey === undefined || apiKey === null) {
        throw new Error('No key for specified environment');
    }
    return { appId, apiKey };
}

/**
 * Insert an agent into the environment specified
 * @param appId Id of the Realm App
 * @param apiKey Credentials
 * @param agentData Agent to be inserted
 * @returns Results of insert
 */
export async function createLoggedAgentInDB(appId: string, apiKey: string, agentData: any) {
    const app: Realm.App = new Realm.App({ id: appId });
    const credentials = Realm.Credentials.apiKey(apiKey);
    const user = await app.logIn(credentials);
    const client = user.mongoClient(process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME);
    const db = client.db(process.env.REACT_APP_STITCH_DATABASE_NAME);
    const results = await db.collection(AGENTS).insertOne(agentData);
    await user.logOut();
    return results;
}
/**
 * Connect a listing
 * @param appId Id of the Realm App
 * @param apiKey Credentials
 * @param agentListing agentListing object to be added to the listing in order to connect
 * @returns Results of update
 */
export async function connectListingInDB(
    appId: string,
    apiKey: string,
    agentListing: AgentListing,
) {
    const app: Realm.App = new Realm.App({ id: appId });
    const credentials = Realm.Credentials.apiKey(apiKey);
    const user = await app.logIn(credentials);
    const client = user.mongoClient(process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME);
    const db = client.db(process.env.REACT_APP_STITCH_DATABASE_NAME);
    const results = await db
        .collection(LISTINGS_COLLECTION)
        .updateOne({ _id: agentListing.listingId }, { $set: { agentListing: agentListing } });
    await user.logOut();
    return results;
}

/**
 * Delete an agent in the environment specified
 * @param appId Id of the Realm App
 * @param apiKey Credentials
 * @param agentMlsId Agent to be deleted
 * @param mlsName mls of the agent in question
 * @returns Results of deletion
 */
export async function deleteAgentInDB(appId: string, apiKey: string, agentObjectId: string) {
    const app: Realm.App = new Realm.App({ id: appId });
    const credentials = Realm.Credentials.apiKey(apiKey);
    const user = await app.logIn(credentials);
    const client = user.mongoClient(process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME);
    const db = client.db(process.env.REACT_APP_STITCH_DATABASE_NAME);
    const results = await db
        .collection(AGENTS)
        .deleteOne({ _id: new BSON.ObjectId(agentObjectId) });
    await user.logOut();
    return results;
}
/**
 * INserts a listing in the environment specified
 * @param appId Id of the Realm App
 * @param apiKey Credentials
 * @param listingData Entire listing to insert
 * @returns Results of insert
 */
export async function insertListingInDB(appId: string, apiKey: string, listingData: any) {
    const app: Realm.App = new Realm.App({ id: appId });
    const credentials = Realm.Credentials.apiKey(apiKey);
    const user = await app.logIn(credentials);
    const client = user.mongoClient(process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME);
    const db = client.db(process.env.REACT_APP_STITCH_DATABASE_NAME);
    const results = await db.collection(LISTINGS_COLLECTION).insertOne(listingData);
    await user.logOut();
    return results;
}
/**
 * Find a listing in the DB
 * @param appId Id of the Realm App
 * @param apiKey Credentials
 * @param listingId Listing Id to query by
 * @param mlsName (optional) mlsName of the listing
 * @returns array of listings
 */
export async function queryForListing(
    appId: string,
    apiKey: string,
    listingId: string,
    mlsName: string = null,
) {
    const query = mlsName
        ? { mlsList: { $elemMatch: { listingId: listingId, mlsName: mlsName } } }
        : { 'mlsList.listingId': listingId };
    const app: Realm.App = new Realm.App({ id: appId });
    const credentials = Realm.Credentials.apiKey(apiKey);
    const user = await app.logIn(credentials);
    const client = user.mongoClient(process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME);
    const db = client.db(process.env.REACT_APP_STITCH_DATABASE_NAME);
    const results = await db.collection(LISTINGS_COLLECTION).find(query);
    await user.logOut();
    return results;
}

/**
 * Find a brokerage in the DB
 * @param appId Id of the Realm App
 * @param apiKey Credentials
 * @param brokerageId brokerage id to query by
 * @param mlsName (optional) mlsName of the brokerage
 * @returns array of brokerages
 */
export async function queryForBrokerage(
    appId: string,
    apiKey: string,
    brokerageId: string,
    mlsName: string = null,
) {
    const query = mlsName
        ? { markets: { $elemMatch: { brokerageId: brokerageId, mlsName: mlsName } } }
        : { 'markets.brokerageId': brokerageId };
    const app: Realm.App = new Realm.App({ id: appId });
    const credentials = Realm.Credentials.apiKey(apiKey);
    const user = await app.logIn(credentials);
    const client = user.mongoClient(process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME);
    const db = client.db(process.env.REACT_APP_STITCH_DATABASE_NAME);
    const results = await db.collection(BROKERAGES).find(query);
    await user.logOut();
    return results;
}
/**
 * Find an agent in the DB
 * @param appId Id of the Realm App
 * @param apiKey Credentials
 * @param agentMlsId agent mls id to query by
 * @param mlsName (optional) mlsName of the agent
 * @returns array of agents
 */
export async function queryForAgent(
    appId: string,
    apiKey: string,
    agentMlsId: string,
    mlsName: string = null,
) {
    const query = mlsName
        ? { markets: { $elemMatch: { agentMlsId: agentMlsId, mlsName: mlsName } } }
        : { 'markets.agentMlsId': agentMlsId };
    const app: Realm.App = new Realm.App({ id: appId });
    const credentials = Realm.Credentials.apiKey(apiKey);
    const user = await app.logIn(credentials);
    const client = user.mongoClient(process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME);
    const db = client.db(process.env.REACT_APP_STITCH_DATABASE_NAME);
    const results = await db.collection(AGENTS).findOne(query);
    await user.logOut();
    return results;
}

/**
 * Log in to a mongodb realm client
 * @param appId Id of the Realm App
 * @param apiKey Credentials
 * @returns Authenticated Realm client
 */
export async function getRealmClient(appId: string, apiKey: string) {
    const app: Realm.App = new Realm.App({ id: appId });
    const credentials = Realm.Credentials.apiKey(apiKey);
    return await app.logIn(credentials);
}

export async function upsertBrokerageRecord(user: any, data: any) {
    const client = Stitch.defaultAppClient;
    client.auth.switchToUserWithId(user.id);
    const brokerages = getDatabase().collection('brokerages');
    const query = { stitchUserId: user.id };
    const options = { upsert: true };
    return brokerages.updateOne(query, { $set: data }, options);
}

export async function updateBrokerageRecord(objectId: any, data: any) {
    const brokerages = getDatabase().collection('brokerages');
    const query = { _id: objectId };
    return brokerages.updateOne(query, { $set: data });
}

export async function upsertAgentRecord(user: any, data: any) {
    // const client = Stitch.defaultAppClient;
    // client.auth.switchToUserWithId(user.id);
    const agents = getDatabase().collection('agents');
    const query = { stitchUserId: user.id };
    const options = { upsert: true };
    return agents.updateOne(query, { $set: data }, options);
}

export async function upsertBrokerageUserRecord(userId: any, data: any) {
    const brokerageUsers = getDatabase().collection('brokerageUsers');
    const query = { _id: new BSON.ObjectId(userId) };
    const options = { upsert: true };
    return brokerageUsers.updateOne(query, { $set: data }, options);
}

/**
 * Call the given stitch function with its params
 * @param client Mongo Stitch Client returned from initialization
 * @param endpoint
 * @param params
 * @returns {Promise<*>}
 */
export function callStitchFunction(client: any, endpoint: any, ...params: any) {
    return client.callFunction(endpoint, params);
}

// Call a stitch Function with the realm-web sdk
export function callStitchFunctionRealm(client: any, endpoint: any, ...params: any) {
    return client.functions.callFunction(endpoint, ...params.map((d) => (d ? d : undefined)));
}

export async function getPhoto(path: string) {
    try {
        return await Stitch.defaultAppClient.callFunction('getPhoto', [path]);
    } catch (error) {
        console.log(error);
    }
}

/**
 * Performs a findOne operation on a named collection
 * with the given query and projection.
 *
 * @param collectionName The name of the collection to query against
 * @param query The query to provide to the findOne function.
 * @param projection
 * @returns {Promise<Document | null>}
 */
export async function findRecord(collectionName: string, query = {}, projection = {}) {
    if (!collectionName) {
        throw new Error('collectionName is required!');
    }
    const collection = getDatabase().collection(collectionName);
    return collection.findOne(query, { projection });
}

export async function getStripePrice(client, priceId) {
    try {
        const priceResponse = await callStitchFunction(client, 'getStripePrice', priceId);
        return priceResponse;
    } catch (error) {
        //
    }
}

export async function createStripeCustomer(client, payload) {
    try {
        const customerStripeId = await callStitchFunction(client, 'createStripeCustomer', payload);
        return customerStripeId;
    } catch (error) {
        //
    }
}

export async function createBrokerageSubscription(client, payload) {
    try {
        const response = await callStitchFunction(client, 'createBrokerageSubscription', payload);
        return response;
    } catch (error) {
        //
    }
}

/**
 * Performs a find operation on a named collection
 * with the given query and projection.
 *
 * @param collectionName The name of the collection to query against
 * @param query The query to provide to the find function.
 * @param projection
 * @returns {Promise<Document[] | null>}
 */
export async function findRecords(collectionName: string, query = {}, projection = {}) {
    if (!collectionName) {
        throw new Error('collectionName is required!');
    }
    const collection = getDatabase().collection(collectionName);
    return collection.find(query, { projection }).toArray();
}

export function fetchAdminAccount() {
    const user = Stitch.defaultAppClient.auth.user;
    if (user === undefined) {
        return null;
    } else {
        return getDatabase().collection(COLLECTION.Admins).findOne({
            userId: user.id,
        });
    }
}

export async function upsertAdminProfile(user, setData) {
    const admins = getDatabase().collection(COLLECTION.Admins);
    const query = { _id: user._id };
    return admins.updateOne(query, { $set: setData });
}

export async function setShowingManagementSubscription(
    brokerageObjectId,
    paymentMethodId,
    showingManagement,
) {
    const query = {
        _id: brokerageObjectId,
    };
    const updateQuery = {
        $set: {
            paymentMethodId: paymentMethodId,
            showingManagement: {
                ...showingManagement,
                status: 'active',
                paymentSchedule: showingManagement?.paymentSchedule ?? 'monthlyBpl',
                start: new Date(),
            },
        },
    };
    const brokerages = getCollection('brokerages');
    return await brokerages.updateOne(query, updateQuery);
}

export async function insertPaymentRecord(args) {
    const paymentRecords = getCollection('paymentRecords');
    return await paymentRecords.insertOne(args);
}

export async function deleteOneFromCollection(id, collectionName) {
    const query = { _id: id };
    const collection = getCollection(collectionName);
    return await collection.deleteOne(query);
}
