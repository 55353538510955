import React, { Fragment, useState, useEffect } from 'react';
import paymentDarkGray from '../../../../../Images/paymentDarkGray.svg';
import backArrow from '../../../../../Images/backArrow.svg';
import styles from './index.module.css';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js/';
import { loadStripe } from '@stripe/stripe-js';
import { Button } from 'web-lib';

type PaymentMethodInputProps = {
    addPaymentMethod: Function;
    onBackPress: Function;
    loading: boolean;
};

type CardInfo = {
    fullName: string;
    cardNumber: string;
    monthDate: string;
    cvCode: string;
    zipCode: string;
};

type CardFormValidation = {
    isNameValid: boolean | null;
    isCardValid: boolean | null;
    isExpiryValid: boolean | null;
    isCvcValid: boolean | null;
    isZipValid: boolean | null;
};

export const PaymentMethodInput = ({
    addPaymentMethod,
    onBackPress,
    loading,
}: PaymentMethodInputProps) => {
    function PaymentForm({ setInvalid }: any) {
        const stripe = useStripe();
        const elements = useElements();
        const [v, setV] = useState<boolean>(false);
        const [canAddCard, setCanAddCard] = useState<boolean>(false);
        const [addButtonEnabled, setAddButtonEnabled] = useState(true);
        const [cardInfo, setCardInfo] = useState<CardInfo>({
            fullName: '',
            cardNumber: '',
            monthDate: '',
            cvCode: '',
            zipCode: '',
        });
        const [cardValuesValid, setCardValuesValid] = useState<CardFormValidation>({
            isNameValid: null,
            isCardValid: null,
            isExpiryValid: null,
            isCvcValid: null,
            isZipValid: null,
        });

        const update = async (e: any) => {
            const card = elements?.getElement(CardNumberElement);
            if (stripe && card) {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card,
                    billing_details: {
                        name: cardInfo?.fullName,
                        address: {
                            postal_code: cardInfo?.zipCode,
                        },
                    },
                });
                if (!error) {
                    addPaymentMethod(paymentMethod?.id, cardInfo.fullName, cardInfo.zipCode);
                    setV(false);
                } else {
                    setV(true);
                }
            }
        };

        const handleCardNameChange = () => {
            if (cardInfo.fullName.length >= 2) {
                setCardValuesValid({ ...cardValuesValid, isNameValid: true });
            } else if (cardInfo.fullName.length < 2) {
                setCardValuesValid({ ...cardValuesValid, isNameValid: false });
            }
        };

        const handleZipChange = () => {
            if (cardInfo.zipCode.length == 5) {
                setCardValuesValid({ ...cardValuesValid, isZipValid: true });
            } else if (cardInfo.zipCode.length < 5) {
                setCardValuesValid({ ...cardValuesValid, isZipValid: false });
            }
        };

        const handleCardNumberChange = (e: any) => {
            if (e.empty) {
                setCardValuesValid({ ...cardValuesValid, isCardValid: null });
            } else if (e.error?.code) {
                setCardValuesValid({ ...cardValuesValid, isCardValid: false });
            } else if (e.complete) {
                setCardValuesValid({ ...cardValuesValid, isCardValid: true });
            }
        };

        const handleExpiryChange = (e: any) => {
            if (e.empty) {
                setCardValuesValid({ ...cardValuesValid, isExpiryValid: null });
            } else if (e.error?.code) {
                setCardValuesValid({ ...cardValuesValid, isExpiryValid: false });
            } else if (e.complete) {
                setCardValuesValid({ ...cardValuesValid, isExpiryValid: true });
            }
        };

        const handleCvcChange = (e: any) => {
            if (e.empty) {
                setCardValuesValid({ ...cardValuesValid, isCvcValid: null });
            } else if (e.error?.code) {
                setCardValuesValid({ ...cardValuesValid, isCvcValid: false });
            } else if (e.complete) {
                setCardValuesValid({ ...cardValuesValid, isCvcValid: true });
            }
        };

        useEffect(() => {
            handleZipChange();
        }, [cardInfo.zipCode]);

        useEffect(() => {
            handleCardNameChange();
        }, [cardInfo.fullName]);

        useEffect(() => {
            if (
                cardValuesValid.isCardValid &&
                cardValuesValid.isCvcValid &&
                cardValuesValid.isExpiryValid &&
                cardValuesValid.isZipValid &&
                cardValuesValid.isNameValid
            )
                setCanAddCard(true);
            else setCanAddCard(false);
        }, [cardValuesValid]);

        return (
            <Fragment>
                <div className={styles.form}>
                    <div className={styles.cardNumber}>
                        <input
                            value={cardInfo.fullName}
                            onChange={(val: any) => {
                                setCardInfo({ ...cardInfo, fullName: val.target.value });
                            }}
                            className={
                                cardValuesValid.isNameValid
                                    ? styles.cardNameValid
                                    : styles.cardNameNeutral
                            }
                            placeholder={'Full Name'}
                            title={''}
                            maxLength={25}
                        />
                    </div>
                    <CardNumberElement
                        className={
                            cardValuesValid.isCardValid === null
                                ? styles.cardNumberNeutral
                                : cardValuesValid.isCardValid
                                ? styles.cardNumberContainerValid
                                : styles.cardNumberContainerInvalid
                        }
                        options={{
                            placeholder: 'Card Number',
                            iconStyle: 'solid',
                            style: {
                                base: {
                                    iconColor: 'rgb(20, 20, 20)',
                                    color: '#3a3a3a',
                                    fontSize: '18px',
                                    fontFamily: 'Poppins',
                                    fontWeight: '600',
                                    fontSmoothing: 'antialiased',
                                    '::placeholder': {
                                        color: '#d3d3d3',
                                        textAlign: 'center',
                                    },
                                    textAlign: 'center',
                                },
                                invalid: {
                                    color: '#ff0000',
                                    ':focus': {
                                        color: '#ff0000',
                                    },
                                },
                            },
                        }}
                        onChange={(e: any) => handleCardNumberChange(e)}
                    />
                    <div className={styles.twoInputs}>
                        <div className={styles.expiry}>
                            <CardExpiryElement
                                className={
                                    cardValuesValid.isExpiryValid === null
                                        ? styles.cardExpiryNeutral
                                        : cardValuesValid.isExpiryValid
                                        ? styles.cardExpiryValid
                                        : styles.cardExpiryInvalid
                                }
                                options={{
                                    style: {
                                        base: {
                                            iconColor: 'rgb(20, 20, 20)',
                                            color: '#3a3a3a',
                                            fontSize: '18px',
                                            fontFamily: 'Poppins',
                                            fontWeight: '600',
                                            fontSmoothing: 'antialiased',
                                            '::placeholder': {
                                                color: '#d3d3d3',
                                                textAlign: 'center',
                                            },
                                            textAlign: 'center',
                                        },
                                        invalid: {
                                            color: '#ff0000',
                                            ':focus': {
                                                color: '#ff0000',
                                            },
                                        },
                                    },
                                }}
                                onChange={(e: any) => handleExpiryChange(e)}
                            />
                        </div>
                        <div className={styles.cvc}>
                            <CardCvcElement
                                className={
                                    cardValuesValid.isCvcValid === null
                                        ? styles.cvcNeutral
                                        : cardValuesValid.isCvcValid
                                        ? styles.cvcValid
                                        : styles.cvcInvalid
                                }
                                options={{
                                    style: {
                                        base: {
                                            iconColor: 'rgb(20, 20, 20)',
                                            color: '#3a3a3a',
                                            fontSize: '18px',
                                            fontFamily: 'Poppins',
                                            fontWeight: '600',
                                            fontSmoothing: 'antialiased',
                                            '::placeholder': {
                                                color: '#d3d3d3',
                                                textAlign: 'center',
                                            },
                                            textAlign: 'center',
                                        },
                                        invalid: {
                                            color: '#ff0000',
                                            ':focus': {
                                                color: '#ff0000',
                                            },
                                        },
                                    },
                                }}
                                onChange={(e: any) => {
                                    handleCvcChange(e);
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.rowContainer}>
                        <div className={styles.row}>
                            <input
                                value={cardInfo.zipCode}
                                onChange={(val: any) => {
                                    if (isNaN(Number(val.target.value))) return;
                                    else {
                                        setCardInfo({ ...cardInfo, zipCode: val.target.value });
                                    }
                                }}
                                type="text"
                                className={
                                    cardValuesValid.isZipValid ? styles.zipValid : styles.zipNeutral
                                }
                                placeholder={'Zip Code'}
                                maxLength={5}
                            />

                            <Button
                                onClick={
                                    addButtonEnabled
                                        ? (e: any) => {
                                              setAddButtonEnabled(false);
                                              update(e);
                                          }
                                        : (e: any) => {
                                              setAddButtonEnabled(true);
                                              update(e);
                                          }
                                }
                                className={
                                    canAddCard ? styles.submitButton : styles.submitButtonDisabled
                                }
                                buttonText={loading ? 'Adding Card...' : 'Add Card & Pay'}
                                disabled={loading || !canAddCard || !addButtonEnabled}
                            />
                        </div>
                    </div>
                    {v ? (
                        <div className={styles.cardInvalidContainer}>
                            <span className={styles.cardInvalidText}>Card Invalid!</span>
                        </div>
                    ) : (
                        <span className={styles.invisible}>InvisibleText</span>
                    )}
                </div>
            </Fragment>
        );
    }

    const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
    const stripePromise = loadStripe(stripeKey);

    const AddCardForm = () => {
        const options = {
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap',
                },
            ],
        };

        return (
            <div className={styles.inner}>
                <Elements stripe={stripePromise} options={options}>
                    <PaymentForm />
                </Elements>
            </div>
        );
    };

    return (
        <div className={styles.root}>
            <div
                onClick={() => {
                    onBackPress();
                }}
                className={styles.back}
            >
                <img className={styles.backArrow} src={backArrow} />
                <p className={styles.backText}>Add New Payment Method</p>
            </div>
            <div className={styles.container}>
                <img className={styles.cardImage} src={paymentDarkGray} />
                {AddCardForm()}
            </div>
        </div>
    );
};
