import React, { useState } from 'react';
import Pages from './Pages';
import { ButtonTabBar } from '../../../components';
import styles from './index.module.css';
import { AccountOperationType, EnvironmentType } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { clearSearchResults } from './actions';
import { getAllowedEnvironments } from '../../auth/selectors';

const Data = () => {
    // Clear the search list every time they change tabs
    const dispatch = useDispatch();
    const changePage = (page: string) => {
        dispatch(clearSearchResults());
        setPage(page);
    };
    const allowedEnvironments: any = useSelector(getAllowedEnvironments);

    // Hold the operations and other settings here to persist through tabs
    const [accountOperation, setAccountOperation] = useState<AccountOperationType>('Create');
    const [environment, setEnvironment] = useState<EnvironmentType>(allowedEnvironments[0]);
    const [page, setPage] = useState('1');

    return (
        <div className={styles.root}>
            <ButtonTabBar
                buttonNames={[
                    { name: 'Testing Accounts', page: '1' },
                    { name: 'Attribute Listings', page: '2' },
                    { name: 'Connect Listings', page: '3' },
                ]}
                setPage={changePage}
                currPage={page}
            />
            <Pages
                page={page}
                accountOperation={accountOperation}
                setAccountOperation={setAccountOperation}
                environment={environment}
                allowedEnvironments={allowedEnvironments}
                setEnvironment={setEnvironment}
            />
        </div>
    );
};

export default Data;
