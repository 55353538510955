import React from 'react';
import styles from './index.module.css';
import defHomeImage from 'web-lib/images/defaultHomeImage.png';
import { UnconnectedIcon } from 'asset-lib/src/assets/svg';
import { timeSince } from '../../utils';
import { ItemCarousel } from '../ItemCarousel';
interface Address {
    street: string;
    full?: string;
    city: string;
    state: string;
    postalCode: string;
}
interface PropertyData {
    listPrice: string;
    listingId: string;
    address: Address;
    beds: number;
    baths: number;
    sqft: number;
    status: string;
    photo: string;
    showType?: string;
    lockType?: string;
    lockCode?: string;
    mlsNumber?: string;
    mls?: any;
    listingStatus?: string;
    listingAgent?: string;
    phone?: string;
    photos?: string[];
    mlsList?: any[];
    listDate?: any;
    agent?: any;
    office?: any;
    property: any;
    agentListing?: any;
}

type PropertyInfoCardProps = {
    onClickDetails?: Function;
    propertyData: PropertyData;
    showSchedule?: boolean;
};

export const ListingCard = ({
    onClickDetails = () => {},
    showSchedule = true,
    propertyData,
}: PropertyInfoCardProps) => {
    const { address } = propertyData;
    const listingIsNotConnected = propertyData?.agentListing === undefined;

    const formattedAddress =
        address?.full && address?.full.length > 30
            ? address?.full.substring(0, 30) + '...'
            : address?.full;

    const formattedCityStateAddress =
        propertyData?.address?.city +
        ', ' +
        propertyData?.address?.state +
        ' ' +
        propertyData?.address?.postalCode;
    return (
        <div className={styles.root}>
            <img
                src={propertyData?.photos?.length ? propertyData?.photos[0] : defHomeImage}
                className={styles.image}
                referrerPolicy={'no-referrer'}
            />
            <div className={styles.listingDetailsColumn}>
                <div className={styles.listingAddressRow}>
                    {formattedAddress}
                    {listingIsNotConnected && (
                        <div className={styles.listingNotConnectedContainer}>
                            <img
                                className={styles.notConnectedIcon}
                                src={UnconnectedIcon}
                                alt={'icon'}
                                referrerPolicy={'no-referrer'}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.listingCityStateZip}>{formattedCityStateAddress}</div>
                <div className={styles.seeDetailsButton}>
                    <span
                        onClick={() => {
                            onClickDetails();
                        }}
                    >
                        See Details
                    </span>
                </div>
            </div>
            <div className={styles.mlsInfoContainer}>
                <ItemCarousel
                    cards={propertyData?.mlsList?.map((data: any, index: number) => (
                        <div key={index} className={styles.mlsDetails}>
                            <div className={styles.mlsText}>
                                <b>MLS: </b>
                                {data?.mlsName ? data?.mlsName : 'None listed'}
                            </div>

                            <div className={styles.mlsText}>
                                <b>Listing ID: </b>
                                {data?.listingId ? data?.listingId : 'None listed'}
                            </div>
                            <div className={styles.mlsText}>
                                <b>Status: </b>
                                {data?.standardStatus ? data?.standardStatus : 'None listed'}
                            </div>
                        </div>
                    ))}
                    displaySize={1}
                    overlayImage={false}
                    cardWidth={220}
                />
            </div>
            <div className={styles.moreInfo}>
                <div className={styles.dateInfo}>
                    <b>Date Listed: </b>
                    {propertyData?.listDate
                        ? timeSince(new Date(propertyData?.listDate))
                        : 'None listed'}
                </div>
                <div>
                    <b>Date Connected: </b>
                    {propertyData?.agentListing?.connectionDate
                        ? timeSince(propertyData?.agentListing?.connectionDate)
                        : 'None listed'}
                </div>
            </div>
        </div>
    );
};

export default ListingCard;
