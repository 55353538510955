import React, { useState } from 'react';
import styles from '../index.module.css';
import { chartSources } from '../mongoCharts';
import classnames from 'classnames';

function GeneralDash() {
    // chart specifications
    const maxDataAge = '600';
    const baseUrl =
        'https://charts.mongodb.com/charts-showingly-development-kcldi/embed/charts?id=';
    const options = `&theme=${'dark'}&attribution=false&autoRefresh=true&maxDataAge=${maxDataAge}&`;

    //styles
    const fullStyle = classnames(styles.iframeStyleFull, styles.dark);
    const numberStyle = classnames(styles.iframeNumber, styles.dark);
    const chartStyle = classnames(styles.iframeChart, styles.dark);
    const tableStyle = classnames(styles.iframeTable, styles.dark);

    return (
        <div className={styles.chartContainer}>
            <div className={styles.title}></div>
            <div className={styles.row}>
                <div className={styles.check1}>
                    <iframe
                        title="chart"
                        className={fullStyle}
                        src={`${chartSources.totalAgents}${options}`}
                    ></iframe>
                </div>
                <div className={styles.check2}>
                    <div>
                        <iframe
                            title="chart"
                            className={numberStyle}
                            src={`${chartSources.totalConsumer}${options}`}
                        ></iframe>
                        <iframe
                            title="chart"
                            className={numberStyle}
                            src={`${chartSources.upcomingShowings}${options}`}
                        ></iframe>
                        <iframe
                            title="chart"
                            className={numberStyle}
                            src={`${chartSources.completedShowings}${options}`}
                        ></iframe>
                    </div>
                    <div>
                        <iframe
                            title="chart"
                            className={numberStyle}
                            src={`${chartSources.connectedListings}${options}`}
                        ></iframe>
                        <iframe
                            title="chart"
                            className={numberStyle}
                            src={`${chartSources.activeLast48}${options}`}
                        ></iframe>
                        <iframe
                            title="chart"
                            className={numberStyle}
                            src={`${chartSources.OnlySSO}${options}`}
                        ></iframe>
                    </div>
                </div>
            </div>

            <div className={styles.row2}>
                <iframe
                    title="chart"
                    className={chartStyle}
                    src={`${chartSources.agentsEachDay}${options}`}
                ></iframe>
                <iframe
                    title="chart"
                    className={chartStyle}
                    src={`${chartSources.agentsOverTime}${options}`}
                ></iframe>
                <iframe
                    title="chart"
                    className={chartStyle}
                    src={`${chartSources.completedShowingsGraph}${options}`}
                ></iframe>
                <iframe
                    title="chart"
                    className={chartStyle}
                    src={`${chartSources.completedDelegationsGraph}${options}`}
                ></iframe>
                <iframe
                    title="chart"
                    className={chartStyle}
                    src={`${chartSources.consumersEachDay}${options}`}
                ></iframe>
                <iframe
                    title="chart"
                    className={chartStyle}
                    src={`${chartSources.consumersOverTime}${options}`}
                ></iframe>
                <iframe
                    title="chart"
                    className={chartStyle}
                    src={`${chartSources.leadsPurchased}${options}`}
                ></iframe>
            </div>

            <div className={styles.row2}>
                <iframe
                    title="chart"
                    className={tableStyle}
                    src={`${chartSources.agentsSubsMarket}${options}`}
                ></iframe>
                <iframe
                    title="chart"
                    className={tableStyle}
                    src={`${chartSources.connectedListingsMarket}${options}`}
                ></iframe>
                <iframe
                    title="chart"
                    className={tableStyle}
                    src={`${chartSources.listingViews}${options}`}
                ></iframe>
                <iframe
                    title="chart"
                    className={tableStyle}
                    src={`${chartSources.delegationsLeadsMarket}${options}`}
                ></iframe>
                <iframe
                    title="chart"
                    className={tableStyle}
                    src={`${chartSources.agentsPerMonth}${options}`}
                ></iframe>
                <iframe
                    title="chart"
                    className={tableStyle}
                    src={`${chartSources.consumersPerMonth}${options}`}
                ></iframe>
            </div>
        </div>
    );
}

export default GeneralDash;
