import { all, call } from 'redux-saga/effects';
import { sagaAuth } from '../domains/auth/sagas';
import { sagaSearch } from '../domains/dash/AllSearches/sagas';
import { sagaDash } from '../domains/dash/sagas';
import { developmentSaga } from '../domains/dash/Development/sagas';
import { init } from '../init/saga';
import { sagaData } from '../domains/dash/Data/sagas';
import { sagaManageAdmins } from '../domains/dash/ManageAdmins/sagas';
import { sagaSearch as sagaAgents } from '../domains/dash/AgentsPage/sagas';
import { sagaBrokerages } from '../domains/dash/BrokeragesPage/sagas';

export function* rootSaga() {
    yield all([
        call(init),
        call(sagaAuth),
        call(sagaDash),
        call(sagaData),
        call(sagaSearch),
        call(developmentSaga),
        call(sagaManageAdmins),
        call(sagaAgents),
        call(sagaBrokerages),
    ]);
}
