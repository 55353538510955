import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import {
    STATUS,
    LISTINGS_COLLECTION,
    SHOWING_REQUESTS,
    AGENTS,
    BROKERAGES,
    BROKERAGE_USERS,
} from '../../../store/api/constants';
import * as Actions from './actions';
import { BSON, Stitch, StitchUser } from 'mongodb-stitch-browser-sdk';
import {
    callStitchFunction,
    createBrokerageSubscription,
    createStripeCustomer,
    findRecord,
    findRecords,
    getStripePrice,
    insertPaymentRecord,
    parseStitchServiceError,
    setShowingManagementSubscription,
    updateBrokerageRecord,
    upsertBrokerageUserRecord,
    upsertAgentRecord,
} from '../../../store/api/sagas';
import { getAccount, getClient, getStitchUser, getStitchUserId } from '../../../redux';
import { generateSignature } from '../../../utils';
import { createShowingManagementSubscriptionRequested } from './actions';
import { any } from 'ramda';

export function* searchAgents({
    searchText,
    marketName,
}: ReturnType<typeof Actions.searchAgentsRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const agents = yield call(
            callStitchFunction,
            client,
            'textSearchAgents',
            searchText,
            null,
            null,
            marketName,
        );
        if (agents) {
            yield put(Actions.searchAgentsSucceeded(agents));
        } else {
            window.alert('Unable to Search for Agents - 1.');
        }
    } catch (error) {
        yield put(Actions.searchAgentsFailed(error));
        window.alert('Unable to Search for Agents - 2.');
    }
}

export function* searchListings({
    searchText,
    marketId,
    mlsIdSearch,
}: ReturnType<typeof Actions.searchListingsRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const listingsLimit = 20;
        const listings = yield call(
            callStitchFunction,
            client,
            'textSearchListings',
            searchText,
            true, //is extra data
            null, //brokerageId,
            listingsLimit,
            [new BSON.ObjectID(marketId)],
            mlsIdSearch,
        );
        if (listings) {
            yield put(Actions.searchListingsSucceeded(listings));
        } else {
            window.alert('Unable to Search for Listings - 1.');
        }
    } catch (error) {
        yield put(Actions.searchListingsFailed(error));
        window.alert('Unable to Search for Listings - 2.');
    }
}

export function* searchBrokerages({
    searchText,
    marketId,
}: ReturnType<typeof Actions.searchBrokeragesRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const brokerages = yield call(
            callStitchFunction,
            client,
            'textSearchBrokerage',
            searchText,
            new BSON.ObjectID(marketId),
        );
        if (brokerages) yield put(Actions.searchBrokeragesSucceeded(brokerages));
        else window.alert('Unable to Search for Brokerages - 1.');
    } catch (error) {
        yield put(Actions.searchBrokeragesFailed(error));
        window.alert('Unable to Search for Brokerages - 2.');
    }
}

export function* fetchShowingAvailability({
    listingId,
}: ReturnType<typeof Actions.fetchShowingAvailabilityRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const firstDate: any = new Date();
        var lastDate: any = new Date();
        lastDate.setHours(0, 0, 0, 0);
        lastDate.setDate(lastDate.getDate() + 7);

        const scheduledShowings = yield call(callStitchFunction, client, 'runGeneralQuery', {
            collectionName: SHOWING_REQUESTS,
            query: {
                listingId,
                'listing.agentListing.listingId': { $exists: true },
                'listing.agentListing.allowOverlap': { $ne: true },

                start: {
                    $gte: firstDate,
                }, 
                end: {
                    $lt: lastDate,
                },
                status: { $nin: ['denied', 'cancelled', 'canceled'] },
            },
            projection: {
                start: 1,
                end: 1,
                listing: 1,
            },
            signature: generateSignature(),
        });

        yield put(Actions.fetchShowingAvailabilitySucceeded(scheduledShowings));
    } catch (error) {
        yield put(Actions.fetchShowingAvailabilityFailed(error));
        window.alert('Unable to fetch availability for this listing.');
    }
}
export function* scheduleShowing({
    listingId,
    startTime,
    endTime,
    agent,
    offset,
}: ReturnType<typeof Actions.scheduleShowingRequested>) {
    try {
        const client = yield select(getClient);

        const showingScheduled = yield call(
            callStitchFunction,
            client,
            'createShowing',
            'agent',
            listingId, // function will convert to objectId
            startTime,
            endTime,
            null, // client
            //7,
            offset, //offset
            null, // delegationPrice
            agent.stitchUserId,
        );
        if (showingScheduled) {
            yield put(Actions.scheduleShowingSucceeded(showingScheduled));
        }
    } catch (error) {
        yield put(Actions.scheduleShowingFailed(error));
        window.alert('Unable to schedule that showing.');
    }
}

export function* fetchBrokerageRoster({
    brokerageId,
}: ReturnType<typeof Actions.fetchBrokerageRosterRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const skip = 0;
        const limit = 2000;
        const filter = 'first';
        const args = [brokerageId, skip, limit, filter];

        const agents = yield callStitchFunction(
            client,
            'validateSystemUser',
            'getPaginatedBrokerageAgents',
            args,
        );

        if (agents) {
            yield put(Actions.fetchBrokerageRosterSucceeded(agents));
        } else window.alert('Unable to fetch brokerage roster - 1.');
    } catch (error) {
        yield put(Actions.fetchBrokerageRosterFailed(error));
        window.alert('Unable to fetch brokerage roster - 2.');
    }
}

export function* fetchBrokerageAdmins({
    brokerageId,
}: ReturnType<typeof Actions.fetchBrokerageAdminsRequested>) {
    try {
        const admins = yield call(findRecords, BROKERAGE_USERS, { brokerageId: brokerageId });
        yield put(Actions.fetchBrokerageAdminsSucceeded(admins));
    } catch (error) {
        yield put(Actions.fetchBrokerageAdminsFailed(error));
        window.alert('Unable to fetch brokerage admins.');
    }
}

export function* fetchFormattedMarkets({
    objId,
    collection,
}: ReturnType<typeof Actions.fetchFormattedMarketsRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const markets = yield call(
            callStitchFunction,
            client,
            'formatUserMarkets',
            collection,
            objId,
        );
        if (markets) {
            yield put(Actions.fetchFormattedMarketsSucceeded(markets));
        }
    } catch (error) {
        yield put(Actions.fetchFormattedMarketsFailed(error));
        window.alert('Could not format user markets.');
    }
}

export function* fetchBrokeragePlusPrices({
    marketId,
    agentCount,
}: ReturnType<typeof Actions.fetchBrokeragePlusPricesRequested>) {
    try {
        const client = yield select(getClient);
        const brokerageMarket = yield call(findRecord, 'markets', {
            _id: new BSON.ObjectID(marketId),
        });

        if (brokerageMarket) {
            const { brokerageSuitePricing = undefined } = brokerageMarket;
            let monthlyPriceId: any = null;
            let annualPriceId: any = null;
            const sizes = Object.keys(brokerageSuitePricing);
            for (var i = 0; i < sizes.length; i++) {
                const size = sizes[i];
                const splitSize = size.split('-');
                const range = [parseInt(splitSize[0]), parseInt(splitSize[1])];
                if (agentCount >= range[0] && (agentCount <= range[1] || agentCount >= 101)) {
                    monthlyPriceId = brokerageSuitePricing[size].monthly;
                    annualPriceId = brokerageSuitePricing[size].annual;
                    break;
                }
            }

            const monthlyPrice = yield call(getStripePrice, client, monthlyPriceId);

            const annualPrice = yield call(getStripePrice, client, annualPriceId);
            const prices = [monthlyPrice, annualPrice];
            yield put(Actions.fetchBrokeragePlusPricesSucceeded(prices));
        }
    } catch (error) {
        yield put(Actions.fetchBrokeragePlusPricesFailed(error));
        window.alert('Could not fetch brokerage plus pricing.');
    }
}

export function* fetchAgentListings({
    agentObjId,
}: ReturnType<typeof Actions.fetchAgentListingsRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const listings = yield call(callStitchFunction, client, 'getAgentsListings', agentObjId);
        yield put(Actions.fetchAgentListingsSucceeded(listings));
    } catch (error) {
        yield put(Actions.fetchAgentListingsFailed(error));
        window.alert('Could not fetch listings associated with this agent.');
    }
}

export function* updateSelectedBrokerage({
    brokerageId,
    brokerageStatus,
    superAdminId,
    adminsToUpdate,
}: ReturnType<typeof Actions.updateSelectedBrokerageRequested>) {
    const client = Stitch.defaultAppClient;
    const args = [brokerageId, brokerageStatus, superAdminId, adminsToUpdate];
    try {
        const brokerageUpdate = yield call(
            callStitchFunction,
            client,
            'validateSystemUser',
            'verifyBrokerage',
            args,
        );

        if (brokerageUpdate) {
            const brokerage = yield call(findRecord, BROKERAGES, {
                _id: brokerageId,
            });
            if (brokerage) {
                console.log('brokerage', brokerage);
                yield put(Actions.setSelectedBrokerage(brokerage));
                yield put(Actions.fetchBrokerageAdminsRequested(brokerage._id));
                yield put(Actions.updateSelectedBrokerageSucceeded(brokerageStatus));
            }
        }
    } catch (error) {
        yield put(Actions.updateSelectedBrokerageFailed(error));
        window.alert('Unable to Update this 🅱️rockerij.');
    }
}

export function* refetchSelectedBrokerage({
    brokerageId,
}: ReturnType<typeof Actions.createShowingManagementSubscriptionSucceeded>) {
    const client = Stitch.defaultAppClient;
    try {
        const brokerage = yield call(findRecord, BROKERAGES, {
            _id: brokerageId,
        });
        if (brokerage) {
            yield put(Actions.setSelectedBrokerage(brokerage));
        }
    } catch (error) {
        window.alert('There was an issue refetching the brokerage.');
    }
}

export function* fetchListing({ listingObjId }: ReturnType<typeof Actions.fetchListingRequested>) {
    const client = Stitch.defaultAppClient;
    try {
        console.log('fetch lsitings');
        const listing = yield call(findRecord, LISTINGS_COLLECTION, {
            _id: listingObjId,
        });
        console.log('l', listing);
        if (listing) {
            yield put(Actions.fetchListingSucceeded(listing));
        } else {
            window.alert('Was unable to find this listing.');
        }
    } catch (error) {
        yield put(Actions.fetchListingFailed(error));
        window.alert('There was an issue fetching this listing.');
    }
}

export function* fetchListingShowings({
    listingObjId,
}: ReturnType<typeof Actions.fetchListingShowingsRequested>) {
    const client = Stitch.defaultAppClient;
    try {
        const showings = yield call(callStitchFunction, client, 'runGeneralQuery', {
            collectionName: SHOWING_REQUESTS,
            query: { listingId: listingObjId },
            sort: { _id: -1 },
            signature: generateSignature(),
        });
        if (showings) {
            yield put(Actions.fetchListingShowingsSucceeded(showings));
        } else {
            window.alert('There was an issue finding showings associated with this listing.');
        }
    } catch (error) {
        yield put(Actions.fetchListingShowingsFailed(error));
        window.alert('An error occurred when trying to fetch showings for this listing.');
    }
}
export function* updateShowingStatus({
    showingId,
    newShowingStatus,
}: ReturnType<typeof Actions.updateShowingStatusRequested>): Generator<any, any, any> {
    try {
        const client = Stitch.defaultAppClient;

        const { modifiedCount } = yield call(
            callStitchFunction,
            client,
            'updateShowingRequestStatusOrType',
            {
                id: showingId,
                status: newShowingStatus,
            },
        );
        const updatedShowing = yield call(findRecord, 'showingRequests', {
            _id: new BSON.ObjectID(showingId),
        });

        const { listingId } = updatedShowing;
        yield call(fetchListingShowings, listingId);

        yield put(Actions.updateShowingStatusSucceeded(updatedShowing));
        yield put(Actions.fetchListingShowingsRequested(listingId));
    } catch (error) {
        const message = parseStitchServiceError(error);
        yield put(Actions.updateShowingStatusFailed(showingId, error.message));
        window.alert('Unable to update this showing request.');
    }
}
export function* fetchPasswordChange({
    stitchUserId,
}: ReturnType<typeof Actions.fetchPasswordChangeRequested>): Generator<any, any, any> {
    try {
        const agent = yield call(findRecord, 'agents', {
            stitchUserId,
        });

        const randomNum = Math.floor(Math.random() * 999999) + 1000;
        const emailFront =
            agent.email.indexOf('+') != -1 ? agent.email.indexOf('+') : agent.email.indexOf('@');
        const emailAlias =
            agent.email.substring(0, emailFront) +
            '+' +
            randomNum +
            agent.email.substring(agent.email.indexOf('@'));

        yield call(
            upsertAgentRecord,
            { id: stitchUserId },
            {
                status: 'logged',
                email: emailAlias,
                stitchUserId: agent.phoneNumber,
            },
        );
        const updatedAgent = yield call(findRecord, 'agents', {
            email: emailAlias,
        });

        yield put(
            Actions.fetchPasswordChangeSucceeded(
                updatedAgent.status,
                updatedAgent.email,
                updatedAgent.stitchUserId,
            ),
        );
        yield put(Actions.setAgentSelected(updatedAgent));
        window.alert('Agent successfully updated');
    } catch (error) {
        const message = error;
        yield put(Actions.fetchPasswordChangeFailed(error.message));
        window.alert(message);
    }
}
export function* createShowingManagementSubscription({
    paymentMethodId,
    showingManagement,
    brokerage,
}: ReturnType<typeof Actions.createShowingManagementSubscriptionRequested>): Generator<
    any,
    any,
    any
> {
    try {
        const response = yield call(
            setShowingManagementSubscription,
            brokerage?._id,
            paymentMethodId,
            showingManagement,
        );

        if (
            showingManagement?.paymentSchedule === 'monthlySubscription' &&
            showingManagement?.cost
        ) {
            // insert an item into paymentRecords collection if the schedule is monthlySubscription and the cost exists on the brokerage document
            var dt = new Date();
            var month = dt.getMonth() + 1;

            var year = dt.getFullYear();

            let daysInMonth = new Date(year, month, 0).getDate();
            const now = new Date().getDate();

            const items = {
                subscriptions: ['showingManagement'],
            };
            const args = {
                status: 'pending',
                paymentMethodId,
                // amount is to determine how much to charge them for the rest of the month.
                amount: Math.round((showingManagement?.cost / daysInMonth) * (daysInMonth - now)),
                customerStripeId: brokerage?.customerStripeId,
                userObjectId: brokerage?._id,
                chargedFor: 'monthlySubscription',
                userType: 'brokerage',
                dateCreated: new Date(),
                items: items,
            };

            yield call(insertPaymentRecord, args);
        }
        if (response?.modifiedCount) {
            yield put(Actions.createShowingManagementSubscriptionSucceeded(brokerage?._id));
            window.alert('$ucess 💰');
        } else {
            yield put(
                Actions.createShowingManagementSubscriptionFailed(
                    'There was an issue purchasing showing management. Please contact support',
                ),
            );
            window.alert('🥸 There was an issue purchasing showing management - 1.');
        }
    } catch (error) {
        console.log('error at the payment thingy: ', error);
        yield put(
            Actions.createShowingManagementSubscriptionFailed(
                'There was an issue purchasing showing management. Please contact support',
            ),
        );
        window.alert('🥸 There was an issue purchasing showing management - 2.');
    }
}

export function* createBrokeragePlusSubscription({
    customerId,
    paymentMethodId,
    priceId,
    brokerageObjectId,
    newCustomerDetails,
}: ReturnType<typeof Actions.createBrokeragePlusSubscriptionRequested>): Generator<any, any, any> {
    try {
        let customerStripeId;
        const client = yield select(getClient);
        if (customerId) {
            customerStripeId = customerId;
        } else {
            customerStripeId = yield call(createStripeCustomer, client, newCustomerDetails);
            yield call(updateBrokerageRecord, brokerageObjectId, { customerStripeId });
        }

        const stripeSubscriptionDetails = {
            customerId: customerStripeId,
            paymentMethodId,
            priceId,
        };

        const subscription = yield call(
            createBrokerageSubscription,
            client,
            stripeSubscriptionDetails,
        );

        const subscriptionParsed = JSON.parse(subscription?.Payload);
        if (subscriptionParsed.id) {
            const now = new Date();
            yield call(updateBrokerageRecord, brokerageObjectId, {
                subscription: {
                    id: subscriptionParsed?.id,
                    status: 'active',
                    subscribeDate: now,
                    paymentMethodId,
                    priceId,
                },
            });
            yield put(Actions.createBrokeragePlusSubscriptionSucceeded(subscription));
            window.alert('Theres that moolah amirite');
        } else {
            const errorMessage = subscriptionParsed?.errorMessage;
            yield put(Actions.createBrokeragePlusSubscriptionFailed(errorMessage));
            window.alert(errorMessage);
        }
    } catch (error) {
        console.log('error at the payment thingy: ', error);
        yield put(
            Actions.createBrokeragePlusSubscriptionFailed(
                'There was an issue purchasing showing management. Please contact support',
            ),
        );
        window.alert(' 🥸 There was an issue purchasing brokerage subscription.');
    }
}

export function* changeBrokerageUserStatus({
    brokerageObjectId,
    brokerageUserId,
    setData,
}: ReturnType<typeof Actions.updateBrokerageUserDocument>): Generator<any, any, any> {
    try {
        const response = yield call(upsertBrokerageUserRecord, brokerageUserId, setData);
        if (response?.modifiedCount) {
            yield put(Actions.fetchBrokerageAdminsRequested(new BSON.ObjectId(brokerageObjectId)));
        }
    } catch (error) {
        console.log('error at changeBrokerageUserStatus', error);
        window.alert('There was an error updating the brokerage user status.');
    }
}

export function* fetchStats({
    userObjectId,
    userType,
}: ReturnType<typeof Actions.fetchStatsRequested>): Generator<any, any, any> {
    try {
        const client = Stitch.defaultAppClient;
        const userStats = yield call(
            callStitchFunction,
            client,
            'metricsGetGroupedStats',
            new BSON.ObjectId(userObjectId),
            userType,
        );
        if (userStats?.length) {
            const statsFilterButtons = userStats?.map((s) => {
                return { name: s.displayMlsName, page: JSON.stringify(s) };
            });
            yield put(Actions.fetchStatsSucceeded(userStats, statsFilterButtons));
        }
    } catch (error) {
        yield put(Actions.fetchStatsFailed(error));
        window.alert(`Unable to fetch ${userType} stats.`);
    }
}

export function* fetchListingAgents({
    brokerage,
}: ReturnType<typeof Actions.fetchBrokerageListingAgentsRequested>): Generator<any, any, any> {
    try {
        const client = Stitch.defaultAppClient;
        const listingAgents = yield call(
            callStitchFunction,
            client,
            'runAggregationListings',
            'getBrokerageListingAgents',
            { brokerageObject: brokerage },
        );
        if (listingAgents) {
            yield put(Actions.fetchBrokerageListingAgentsSucceeded(listingAgents));
        }
    } catch (error) {
        window.alert('Unable to fetch this brokerages listing agents.');
        yield put(Actions.fetchBrokerageListingAgentsFailed(error));
    }
}

export function* sagaSearch() {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchAgentListings &&
                action.status === STATUS.Requested,
            fetchAgentListings,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchFormattedMarkets &&
                action.status === STATUS.Requested,
            fetchFormattedMarkets,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.TextSearchAgents &&
                action.status === STATUS.Requested,
            searchAgents,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.TextSearchListings &&
                action.status === STATUS.Requested,
            searchListings,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.TextSearchBrokerages &&
                action.status === STATUS.Requested,
            searchBrokerages,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchShowingAvailability &&
                action.status === STATUS.Requested,
            fetchShowingAvailability,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.ScheduleShowing &&
                action.status === STATUS.Requested,
            scheduleShowing,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchBrokerageRoster &&
                action.status === STATUS.Requested,
            fetchBrokerageRoster,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchBrokerageAdmins &&
                action.status === STATUS.Requested,
            fetchBrokerageAdmins,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.UpdateSelectedBrokerage &&
                action.status === STATUS.Requested,
            updateSelectedBrokerage,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchSelectedListing &&
                action.status === STATUS.Requested,
            fetchListing,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchListingShowings &&
                action.status === STATUS.Requested,
            fetchListingShowings,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.UpdateShowingStatus &&
                action.status === STATUS.Requested,
            updateShowingStatus,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchPasswordChange &&
                action.status === STATUS.Requested,
            fetchPasswordChange,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.CreateShowingManagementSubscription &&
                action.status === STATUS.Requested,
            createShowingManagementSubscription,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.CreateShowingManagementSubscription &&
                action.status === STATUS.Succeeded,
            refetchSelectedBrokerage,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.CreateBrokeragePlusSubscription &&
                action.status === STATUS.Requested,
            createBrokeragePlusSubscription,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.ChangeBrokerageUserStatus &&
                action.status === STATUS.Requested,
            changeBrokerageUserStatus,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchBrokeragePlusPrices &&
                action.status === STATUS.Requested,
            fetchBrokeragePlusPrices,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchStats &&
                action.status === STATUS.Requested,
            fetchStats,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchBrokerageListingAgents &&
                action.status === STATUS.Requested,
            fetchListingAgents,
        ),
    ]);
}
