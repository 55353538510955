import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { ButtonTabBar } from '../../../../../components';

type statsInfoProps = {
    stats: any[];
    mlsOptions: any[];
    type: 'agent' | 'brokerage';
};
export const StatsInfo = ({ stats, mlsOptions, type }: statsInfoProps) => {
    const [stringifiedStats, setStringifiedStats] = useState<string>(mlsOptions[0].page);
    const [currentStat, setCurrentStat] = useState<any>(JSON.parse(mlsOptions[0].page));

    useEffect(() => {
        const allStat = JSON.parse(stringifiedStats);
        setCurrentStat(allStat?.statDoc);
    }, [stringifiedStats]);

    const DetailsLabel = ({ label, value }: { label?: string; value?: number }) => {
        return (
            <div className={styles.detailsLabel}>
                <div className={styles.labelconstant}>{label}:&nbsp;</div>
                <div className={styles.labeltext}>{value ?? '-'}</div>
            </div>
        );
    };
    return (
        <div className={styles.root}>
            <div className={styles.title}>Stats</div>
            {mlsOptions ? (
                <>
                    <ButtonTabBar
                        buttonNames={mlsOptions}
                        setPage={setStringifiedStats}
                        currPage={stringifiedStats}
                    />
                    <div className={styles.row}>
                        <div className={styles.col1}>
                            <DetailsLabel
                                label={'Active Listings'}
                                value={currentStat?.activeListingCount}
                            />
                            <DetailsLabel
                                label={'Listing Perc'}
                                value={currentStat?.activeListingPerc}
                            />
                        </div>
                        <div className={styles.col}>
                            <DetailsLabel label={'YTD Vol'} value={currentStat?.ytdTotalVolume} />
                            <DetailsLabel label={'YTD Units'} value={currentStat?.ytdTotalUnits} />
                            <DetailsLabel
                                label={'YTD Avg. Price'}
                                value={currentStat?.ytdAvgPrice}
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        {type === 'brokerage' && (
                            <div className={styles.col}>
                                <DetailsLabel
                                    label={'Active Agents'}
                                    value={currentStat?.activeAgentCount}
                                />
                                <DetailsLabel
                                    label={'Logged Agents'}
                                    value={currentStat?.loggedAgentCount}
                                />
                                <DetailsLabel
                                    label={'Total Agents'}
                                    value={currentStat?.totalAgentCount}
                                />
                                <DetailsLabel label={'Agent Perc'} value={currentStat?.agentPerc} />
                            </div>
                        )}

                        <div className={styles.col}>
                            <DetailsLabel
                                label={'Active Clients'}
                                value={currentStat?.activeClientCount}
                            />
                            <DetailsLabel
                                label={'Logged Clients'}
                                value={currentStat?.loggedClientCount}
                            />
                            <DetailsLabel
                                label={'Total Clients'}
                                value={currentStat?.totalClientCount}
                            />
                            <DetailsLabel label={'Client Perc'} value={currentStat?.consumerPerc} />
                        </div>
                    </div>
                </>
            ) : (
                <div>yo</div>
            )}
        </div>
    );
};

export default StatsInfo;
