import React, { useEffect, useState } from 'react';
import styles from './index.module.css';

export const RandomLoader = () => {
    const random = Math.floor(Math.random() * 100);

    const loadingIcon =
        random < 71
            ? 'https://showingly-image-assets.s3.amazonaws.com/Loader+Icon.gif' //showingly
            : random < 76
            ? 'https://reactfordataviz.com/21ab5c09f8bf0500529d9af1519a2be5/media-3WmWdBzqveXaE-giphy.gif' //wat
            : random < 81
            ? 'https://giphy.com/embed/KPgOYtIRnFOOk' //gorilla
            : random < 91
            ? 'https://giphy.com/embed/110F1JFzWKtiA8' //child
            : random < 96
            ? 'https://giphy.com/embed/S7MpxxGT3EnDy' //dog
            : 'https://gfycat.com/ifr/AbandonedGeneralGhostshrimp'; //kermit

    const type = random < 81 ? 'img' : 'iframe';
    const logoStyle = random < 71 ? styles.showinglyLogo : styles.logo;

    return (
        <div>
            <div className={styles.loadingContainer}>
                {type === 'iframe' && <iframe className={logoStyle} src={loadingIcon} />}
                {type === 'img' && <img className={logoStyle} src={loadingIcon} />}
            </div>
        </div>
    );
};

export default RandomLoader;
