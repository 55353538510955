import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import styles from './index.module.css';
import { RandomLoader } from '../../../components';
import { Loader, SearchBar, PropertyListingCard } from 'web-lib';
import { useHistory } from 'react-router-dom';
import { fetchAllAgentsRequested, setSelectedAgent } from './actions';
import { getAllAgents, getAllAgentsLoading, getTotalPages, getTotalCount } from './selectors';
import { getMarkets } from '../selectors';
import { Assets } from 'asset-lib/src/index';
import ColoredButton from '../../../components/ColoredButton';
import NewTableHeader from '../../../components/NewTableHeader';
import NewPersonCard from '../../../components/NewPersonCard';
import Paginator from '../../../components/Paginator';
import { prettifyWords } from '../../../utils/prettifyWords';

const mapDispatchToProps = {
    fetchAllAgentsRequested: fetchAllAgentsRequested,
    setSelectedAgent: setSelectedAgent,
};
const mapStateToProps = (state: any) => ({
    allAgents: getAllAgents(state),
    markets: getMarkets(state),
    loading: getAllAgentsLoading(state),
    totalPages: getTotalPages(state),
    getTotalCount: getTotalCount(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const AgentsPage = ({
    allAgents,
    setSelectedAgent,
    totalPages,
    loading,
    getTotalCount,
    fetchAllAgentsRequested,
}: any) => {
    /*-------- Constants --------*/
    const history = useHistory();

    /*-------- States ---------*/
    const [searchText, setSearchText] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>();
    const [page, setPage] = useState<number>(1);

    /*-------- Use Effects ---------*/
    useEffect(() => {
        fetchAllAgentsRequested(page, searchText);
    }, [page]);

    useEffect(() => {
        fetchAllAgentsRequested(1, searchText);
    }, [searchText]);

    function showAgentCard() {
        if (allAgents && allAgents.length) {
            return (
                <div>
                    {allAgents.map((agent: any) => {
                        function onClickAgent() {
                            setSelectedAgent(agent);
                            history.push(
                                `agentsPage/agent/${agent.markets[0].agentMlsId}/${agent.markets[0].marketName}`,
                            );
                        }
                        return (
                            <NewPersonCard
                                firstName={prettifyWords(agent?.firstName)}
                                lastName={prettifyWords(agent?.lastName)}
                                ID={agent?.markets[0]?.agentMlsId}
                                listings={agent?.listings[0]?.activeListingCount}
                                status={agent?.status}
                                onClickViewProfile={onClickAgent}
                                avgSoldPrice={0}
                                prodVolume={0}
                                market={agent?.markets[0]?.marketName}
                            />
                        );
                    })}
                </div>
            );
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <h3 className={styles.h3Title}>Agents</h3>
                <div className={styles.innerContainer}>
                    <SearchBar
                        inputStyles={styles.searchBarInputStyles}
                        onSubmit={(text: string = '') => {
                            setSearchText(text);
                        }}
                        disabled={disabled}
                        placeholderText={'Search Agent by name'}
                    />
                    <ColoredButton text="Filter" onClick={() => {}}></ColoredButton>
                </div>

                {getTotalCount > 0 ? (
                    <div className={styles.pageCounter}>{getTotalCount} Agents</div>
                ) : (
                    ''
                )}
            </div>
            <NewTableHeader typeOfHeader={'agents'}></NewTableHeader>
            {loading == true ? (
                <div className={styles.loaderContainer}>
                    <RandomLoader />
                </div>
            ) : (
                showAgentCard()
            )}
            {totalPages > 1 ? (
                <Paginator
                    totalPages={totalPages}
                    currentPage={page}
                    onPrev={() => setPage(page - 1)}
                    onNext={() => setPage(page + 1)}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default connector(AgentsPage);
