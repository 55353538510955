import { STATUS } from '../../../store/api/constants';

export enum ADMIN_ACTIONS {
    FetchAdminUsers = '[Admin] Fetch Admin users',
}

export const fetchAdminUsersRequested = () =>
    ({
        type: ADMIN_ACTIONS.FetchAdminUsers,
        status: STATUS.Requested,
    } as const);
export const fetchAdminUsersSucceeded = (adminUsers: Array<any>) =>
    ({
        type: ADMIN_ACTIONS.FetchAdminUsers,
        status: STATUS.Succeeded,
        adminUsers,
    } as const);
export const fetchAdminUsersFailed = (error: any) =>
    ({
        type: ADMIN_ACTIONS.FetchAdminUsers,
        status: STATUS.Failed,
        error,
    } as const);

export type FetchAdminUsersActions =
    | ReturnType<typeof fetchAdminUsersRequested>
    | ReturnType<typeof fetchAdminUsersSucceeded>
    | ReturnType<typeof fetchAdminUsersFailed>;

export type AdminActions = FetchAdminUsersActions;
