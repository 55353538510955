import { Stitch, UserPasswordCredential } from 'mongodb-stitch-browser-sdk';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    AUTH_ACTION,
    initializationDone,
    loginFailed,
    loginRequested,
    loginSucceeded,
    logoutFailed,
    logoutRequested,
    logoutSucceeded,
    updateStitchUserSucceeded,
} from '../../redux/auth';
import { STATUS } from '../../store/api/constants';
import { fetchAdminAccount, upsertAdminProfile } from '../../store/api/sagas';
import { fetchMarketsRequested, fetchMlsListRequested } from '../dash/actions';

/*
 * Upon booting the app, check the login status of the user that is
 * managed by the Stitch SDK.
 * @return {IterableIterator<object>}
 */
export function* initAuth() {
    try {
        const client = Stitch.defaultAppClient;
        const stitchUser = Stitch.defaultAppClient?.auth?.user;
        const stitchUserId = Stitch.defaultAppClient?.auth?.user?.id;

        if (client && stitchUser && stitchUserId) {
            yield put(updateStitchUserSucceeded(client, stitchUser, stitchUserId));
        }
        const isRememberMe = localStorage.getItem('remember');
        const adminAccount = yield call(fetchAdminAccount);
        if (isRememberMe === 'true') {
            if (stitchUser?.isLoggedIn) {
                yield put(initializationDone(stitchUser?.isLoggedIn));

                // load client data and profile data on page reload
                yield put(loginSucceeded(adminAccount));
                yield call(upsertAdminProfile, adminAccount, { lastSession: new Date() });
                //    yield put(getProfileDataRequested())
            } else {
                yield put(initializationDone(false));
            }
        } else {
            if (stitchUser?.isLoggedIn) {
                yield put(logoutRequested());
                yield put(initializationDone(false));
                localStorage.removeItem('remember');
            } else {
                yield put(initializationDone(false));
            }
        }
    } catch (error) {
        yield put(initializationDone(false));
        yield call(alert, {
            type: 'error',
            message: 'Failed to reach the servers. Please try again later.',
        });
    }
}

export function* login({ username, password, history }: ReturnType<typeof loginRequested>) {
    try {
        yield Stitch.defaultAppClient.auth.loginWithCredential(
            new UserPasswordCredential(username, password),
        );
        const adminAccount = yield call(fetchAdminAccount);
        if (adminAccount) {
            const client = Stitch.defaultAppClient;
            const stitchUser = Stitch.defaultAppClient?.auth?.user;
            const stitchUserId = Stitch.defaultAppClient?.auth?.user?.id;

            if (client && stitchUser && stitchUserId) {
                yield put(updateStitchUserSucceeded(client, stitchUser, stitchUserId));
            }
            yield put(loginSucceeded(adminAccount));
            yield call(upsertAdminProfile, adminAccount, { lastSession: new Date() });
        } else {
            throw Error('No admin account associated with this user.');
        }
    } catch (error) {
        yield put(loginFailed(error));
        window.alert('Incorrect Username or Password.');
    }
}

export function* loginSuccess() {
    yield all([put(fetchMarketsRequested()), put(fetchMlsListRequested())]);
}

export function* logout() {
    try {
        yield Stitch.defaultAppClient.auth.logout();
        yield put(logoutSucceeded());
    } catch (error) {
        yield put(logoutFailed(error));
    }
}

export function* sagaAuth() {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.Initialization && action.status === STATUS.Requested,
            initAuth,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.Login && action.status === STATUS.Requested,
            login,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.Login && action.status === STATUS.Succeeded,
            loginSuccess,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.Logout && action.status === STATUS.Requested,
            logout,
        ),
    ]);
}
