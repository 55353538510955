import React from 'react';
import styles from './index.module.css';
import TopHat from '../../../../../../Images/TopHat.png';
import { timeSince } from '../../../../../../utils';

import { formatPhoneNumberForDisplay, formatEmailForDisplay } from '../../../../../../utils';

type adminUser = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    profileImage: string;
    devKeys: string | null;
    stagingKeys: string | null;
    productionKeys: string | null;
    lastSession: Date | null;
};

type adminCardProps = {
    admin: adminUser;
};
export const AdminUseCard = ({ admin }: adminCardProps) => {
    const profileImage =
        admin.profileImage && admin.profileImage !== '' ? admin.profileImage : TopHat;

    return (
        <div className={styles.root}>
            <div className={styles.row}>
                <div>
                    <img className={styles.image} src={profileImage} />
                </div>

                <div className={styles.agentInfo}>
                    <div className={styles.name}>
                        {admin.firstName} {admin.lastName}
                    </div>
                    <div className={styles.contact}>
                        <div>{formatPhoneNumberForDisplay(admin.phoneNumber)}</div>
                        <div>{admin.email}</div>
                    </div>
                </div>

                <div className={styles.agentInfo}>
                    <div className={styles.name}>Last Session</div>
                    <div className={styles.contact}>
                        <div>{timeSince(admin?.lastSession)}</div>
                    </div>
                </div>
                <div className={styles.agentInfo}>
                    {(admin.devKeys || admin.stagingKeys || admin.productionKeys) && (
                        <>
                            <div className={styles.name}>Data Keys</div>
                            <div className={styles.contact}>
                                {admin.devKeys && <div>Dev</div>}
                                {admin.stagingKeys && <div>Staging</div>}
                                {admin.productionKeys && <div>Production</div>}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminUseCard;
