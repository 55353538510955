import React, { useEffect, useState } from 'react';
import { Row } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { initialization, isAuthLoading, loginRequested } from '../../../redux/auth';
import background from '../../../Images/LoginImage.svg';
import { Button } from '../../../components';
import styles from './index.module.css';
import logo from '../../../Images/logo.svg';
import check from '../../../Images/checkMark.svg';

const mapStateToProps = (state: any) => ({
    authLoading: isAuthLoading(state),
});

const mapDispatchToProps = {
    loginRequested: loginRequested,
    initialization: initialization,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

function LoginPage(args: ReduxProps) {
    const history = useHistory();
    const [loggedInUser, setLoggedInUser] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const onSubmit = ({ username, password }: { username: string; password: string }) => {
        args.loginRequested(username, password, history);
    };

    return (
        <Row className={styles.root}>
            <div className={styles.leftDiv}>
                <img className={styles.image} src={background} />
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <img className={styles.logo} src={logo}></img>
                    <div className={styles.title}>
                        <h2>Log in</h2>
                        <p className={styles.subtitle}>
                            Enter your credentials to access your account
                        </p>
                    </div>
                    <div className={styles.inputGroup}>
                        <input
                            id={'username'}
                            ref={register({ required: 'Email field is required' })}
                            type={'username'}
                            name={'username'}
                            className={styles.formControl}
                            placeholder="Email"
                        />
                        {/* {errors.email && <span className={styles.errorText}>{'Error'}</span>} */}
                    </div>
                    <div className={styles.inputGroup}>
                        <input
                            id={'password'}
                            ref={register({ required: 'Password field is required' })}
                            type={'password'}
                            name={'password'}
                            className={styles.formControl}
                            placeholder="Password"
                        />
                        {/* {errors.password && <span className={styles.errorText}>{'Error'}</span>} */}
                    </div>
                    <div className={styles.checkdiv}>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked={rememberMe}
                                onChange={() => {
                                    setRememberMe(!rememberMe);
                                    localStorage.setItem('remember', (!rememberMe).toString());
                                }}
                            />
                        </label>
                        <div className={styles.remember}>Remember me on this device</div>
                    </div>
                    <Button
                        buttonText={args.authLoading ? '...' : 'Login'}
                        buttonAlias="Primary"
                        className={styles.buttonControl}
                    />
                </form>
            </div>
        </Row>
    );
}

export const ConnectedLoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
