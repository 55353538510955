import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import GeneralInfo from '../Common/GeneralInfo';
import { DateTime } from 'luxon';
import { timeSince } from '../../../../utils/';
import { connect } from 'react-redux';
import {
    getLoading,
    getSelectedBrokerage,
    getBrokerageRoster,
    getBrokerageAdmins,
    getFormattedMarkets,
    getPaymentSuccessful,
    getPlusPrices,
    getStats,
    getStatsFilters,
    getListingAgents,
    getLoadingListingAgents,
} from '../selectors';
import {
    updateBrokerageUserDocument,
    createShowingManagementSubscriptionRequested,
    updateSelectedBrokerageRequested,
    fetchBrokeragePlusPricesRequested,
    createBrokeragePlusSubscriptionRequested,
} from '../actions';
import UserCard from '../Common/UserCard';
import MarketsInfo from '../Common/MarketsInfo';
import { SearchBar } from 'web-lib';
import { TableHeader, PersonCard, RandomLoader, ListingAgentCard } from '../../../../components';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { PaymentMethodInput } from '../Common/PaymentMethodInput';
import StatsInfo from '../Common/StatsInfo';

const mapDispatchToProps = {
    updateSelectedBrokerageRequested: updateSelectedBrokerageRequested,
    createShowingManagementSubscriptionRequested: createShowingManagementSubscriptionRequested,
    createBrokeragePlusSubscriptionRequested: createBrokeragePlusSubscriptionRequested,
    updateBrokerageUserDocument: updateBrokerageUserDocument,
    fetchBrokeragePlusPrices: fetchBrokeragePlusPricesRequested,
};
const mapStateToProps = (state: any) => ({
    loading: getLoading(state),
    brokerage: getSelectedBrokerage(state),
    agents: getBrokerageRoster(state),
    admins: getBrokerageAdmins(state),
    brokerageMarkets: getFormattedMarkets(state),
    plusPrices: getPlusPrices(state),
    paymentSuccessful: getPaymentSuccessful(state),
    brokerageStats: getStats(state),
    statsFilters: getStatsFilters(state),
    listingAgents: getListingAgents(state),
    loadingListingAgents: getLoadingListingAgents(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type BrokerageViewerProps = {
    loading: boolean;
    brokerage: any;
    brokerageMarkets: any;
    agents: any[];
    admins: any[];
    listings?: any;
    paymentSuccessful: any;
    updateSelectedBrokerageRequested: Function;
    createShowingManagementSubscriptionRequested: Function;
    createBrokeragePlusSubscriptionRequested: Function;
    updateBrokerageUserDocument: Function;
    fetchBrokeragePlusPrices: Function;
    plusPrices?: any;
    brokerageStats: any[];
    statsFilters: any[];
    listingAgents: any[];
    loadingListingAgents: boolean;
};

export const BrokerageViewer = ({
    loading,
    brokerage,
    brokerageMarkets,
    agents,
    admins,
    listings,
    updateSelectedBrokerageRequested,
    createShowingManagementSubscriptionRequested,
    createBrokeragePlusSubscriptionRequested,
    paymentSuccessful,
    updateBrokerageUserDocument,
    fetchBrokeragePlusPrices,
    plusPrices,
    brokerageStats,
    statsFilters,
    listingAgents,
    loadingListingAgents,
}: BrokerageViewerProps) => {
    const [agentsToMap, setAgentsToMap] = useState<any[]>(agents);
    const [isAdd, setIsAdd] = useState(null);
    const brokerageStatuses = ['unverified', 'verified', 'suspended', 'denied'];
    sessionStorage.setItem('redirectUponRefresh', 'true');

    const saveBrokerage = (newStatus: string, superAdmin: any, allBrokerageAdmins: any[]) => {
        var adminsToUpdate = [];
        const superAdminToSet = superAdmin?._id ? new BSON.ObjectID(superAdmin._id) : null;

        // remove super admin from rest of admins to update separately
        if (allBrokerageAdmins && superAdmin) {
            allBrokerageAdmins.forEach((admin: any) => {
                if (superAdmin._id.toString() != admin._id.toString()) {
                    adminsToUpdate.push(admin._id);
                }
            });
        }
        updateSelectedBrokerageRequested(
            brokerage?._id,
            newStatus,
            superAdminToSet,
            adminsToUpdate,
        );
    };
    const submitHandler = (searchText: any) => {
        //If the user inputs something, let's search...
        if (agents && searchText !== '') {
            setAgentsToMap(
                agents.filter((agent: any) =>
                    (agent.firstName + ' ' + agent.lastName)
                        .toLowerCase()
                        .includes(searchText.toLowerCase()),
                ),
            );
        }
        //Other wise reset the agents to the initial array
        else if (searchText === '') {
            setAgentsToMap(agents);
        }
    };
    useEffect(() => {
        if (!agentsToMap.length) {
            setAgentsToMap(agents);
        }
    }, [agents]);

    useEffect(() => {
        if (paymentSuccessful) {
            setIsAdd(null);
        }
    }, [paymentSuccessful]);

    const [formattedPrices, setFormattedPrices] = useState([
        { value: null, label: 'Loading' },
        { value: null, label: 'Loading' },
    ]);

    useEffect(() => {
        if (plusPrices?.length) {
            let newPrices = [];
            plusPrices.map((price, index) => {
                const amountInCents = price?.unit_amount;
                const recurringInterval = price?.recurring?.interval;
                const priceId = price?.id;
                const formattedPrice = `$${amountInCents / 100}/${recurringInterval}`;
                newPrices[index] = { value: priceId, label: formattedPrice };
            });
            setFormattedPrices(newPrices);
            setSelectedPlusPrice(newPrices[0]?.value);
        }
    }, [plusPrices]);

    const marketId = brokerage?.markets?.length && brokerage?.markets[0]?.marketObjectId;
    const customerId = brokerage?.customerStripeId || null;
    let newCustomerDetails = null;
    if (!customerId) {
        newCustomerDetails = admins.length
            ? {
                  email: admins[0]?.email,
                  phoneNumber: admins[0]?.phoneNumber,
                  firstName: admins[0]?.firstName,
                  lastName: admins[0]?.lastName,
              }
            : {
                  email: brokerage.name,
                  phoneNumber: 'none',
                  firstName: brokerage.name,
                  lastName: 'admin',
              };
    }
    const agentCountForPricing = agents.length;

    useEffect(() => {
        if (marketId) {
            fetchBrokeragePlusPrices(marketId, agentCountForPricing);
        }
    }, []);

    const [showingManagement, setShowingManagement] = useState<any>({});
    const [selectedPlusPrice, setSelectedPlusPrice] = useState<any>(formattedPrices[0]?.value);

    return (
        <div className={styles.root}>
            {!!isAdd ? (
                isAdd === 'showingManagement' ? (
                    <div>
                        <div>Custom Price (cents) </div>
                        <div style={{ flex: 1, flexDirection: 'row' }}>
                            <input
                                onChange={(value) => {
                                    setShowingManagement({
                                        ...showingManagement,
                                        cost: Number(value.target.value),
                                    });
                                }}
                            />
                            <div>
                                {showingManagement.cost
                                    ? '$' + (showingManagement.cost / 100).toFixed(2)
                                    : null}
                            </div>
                        </div>
                        <select
                            onChange={(value) => {
                                setShowingManagement({
                                    ...showingManagement,
                                    paymentSchedule: value.target.value,
                                });
                            }}
                        >
                            <option value={'monthlyBpl'} selected>
                                Monthly Per Listing (default)
                            </option>
                            <option value={'monthlySubscription'}>Monthly Subscription</option>
                        </select>
                        <PaymentMethodInput
                            addPaymentMethod={(paymentMethodId: any) => {
                                createShowingManagementSubscriptionRequested(
                                    paymentMethodId,
                                    showingManagement,
                                    brokerage,
                                );
                            }}
                            onBackPress={() => {
                                setIsAdd(null);
                            }}
                            loading={loading}
                        />
                    </div>
                ) : (
                    <div>
                        <select
                            onChange={(value) => {
                                setSelectedPlusPrice(value.target.value);
                            }}
                        >
                            <option value={formattedPrices[0]?.value} selected>
                                {formattedPrices[0]?.label}
                            </option>
                            <option value={formattedPrices[1]?.value}>
                                {formattedPrices[1]?.label}
                            </option>
                        </select>
                        <PaymentMethodInput
                            addPaymentMethod={(paymentMethodId: any) => {
                                createBrokeragePlusSubscriptionRequested(
                                    customerId,
                                    paymentMethodId,
                                    selectedPlusPrice,
                                    brokerage?._id,
                                    newCustomerDetails,
                                );
                            }}
                            onBackPress={() => {
                                setIsAdd(null);
                            }}
                            loading={loading}
                        />
                    </div>
                )
            ) : (
                <>
                    <div className={styles.row}>
                        <div className={styles.generalInfoDiv}>
                            <GeneralInfo
                                name={brokerage?.name}
                                id={brokerage?.brokerageId}
                                market={brokerage?.markets?.length && brokerage?.markets[0]?.market}
                                mlsName={brokerage?.mlsName}
                                status={
                                    brokerage?.adminVerificationStatus
                                        ? brokerage?.adminVerificationStatus
                                        : ''
                                }
                                hasSubscription={brokerage?.subscription?.status === 'active'}
                                agentCount={agents.length}
                                isEditable={true}
                                onClickSave={saveBrokerage}
                                possibleStatuses={brokerageStatuses}
                                admins={admins}
                                showingManagement={brokerage?.showingManagement}
                                onPressAddShowingManagement={() => setIsAdd('showingManagement')}
                                onPressAddBrokeragePlus={() => setIsAdd('brokeragePlus')}
                            />
                            <div className={styles.mlsInfo}>
                                <MarketsInfo markets={brokerageMarkets} />
                            </div>
                        </div>
                        <div>
                            <div className={styles.adminDiv}>
                                <div className={styles.sectionText}>Brokerage Admins:</div>
                                <div className={styles.line}></div>

                                {loading && (
                                    <div className={styles.loader}>
                                        <RandomLoader />
                                    </div>
                                )}
                                {admins &&
                                    admins.length > 0 &&
                                    !loading &&
                                    admins.map((admin: any) => {
                                        return (
                                            <>
                                                <UserCard
                                                    name={`${admin.firstName} ${admin.lastName} `}
                                                    phoneNumber={admin.phoneNumber}
                                                    email={admin.email}
                                                    status={admin.status ? admin.status : ''}
                                                    isSuperAdmin={
                                                        admin.isSuperAdmin
                                                            ? admin.isSuperAdmin
                                                            : false
                                                    }
                                                    lastSession={
                                                        admin.lastSession
                                                            ? timeSince(admin.lastSession)
                                                            : null
                                                    }
                                                    signUpDate={
                                                        admin.signUpDate
                                                            ? timeSince(admin.signUpDate)
                                                            : null
                                                    }
                                                    onClickVerify={() => {
                                                        updateBrokerageUserDocument(
                                                            brokerage?._id.toString(),
                                                            admin?._id.toString(),
                                                            { status: 'verified' },
                                                        );
                                                    }}
                                                    onClickUnverify={() => {
                                                        updateBrokerageUserDocument(
                                                            brokerage?._id.toString(),
                                                            admin?._id.toString(),
                                                            { status: 'unverified' },
                                                        );
                                                    }}
                                                    onClickMakeSuperAdmin={() => {
                                                        updateBrokerageUserDocument(
                                                            brokerage?._id.toString(),
                                                            admin?._id.toString(),
                                                            { isSuperAdmin: true },
                                                        );
                                                    }}
                                                    onClickMakeNotSuperAdmin={() => {
                                                        updateBrokerageUserDocument(
                                                            brokerage?._id.toString(),
                                                            admin?._id.toString(),
                                                            { isSuperAdmin: false },
                                                        );
                                                    }}
                                                />
                                                <div className={styles.line}></div>
                                            </>
                                        );
                                    })}
                                {admins.length === 0 && !loading && (
                                    <div className={styles.noBrokerages}>No Brokerage Admins</div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* <div className={styles.agentDirectory}>
                        <SearchBar
                            inputStyles={styles.searchBarInputStyles}
                            onSubmit={(newText: any) => submitHandler(newText)}
                            disabled={false}
                            placeholderText={'Search by Name'}
                        />
                        <TableHeader type={'agent'} />
                        <div className={styles.agentDirectoryScroll}>
                            {agentsToMap &&
                                agentsToMap.length &&
                                agentsToMap.map((agent: any, index: number) => {
                                    function onClickAgent() {
                                        window.alert('🤦🏽‍♀🧑🏽‍💻 Not Available Yet 🧑🏽‍💻🤦🏽‍♀');
                                    }
                                    return (
                                        <PersonCard
                                            name={`${agent.firstName} ${agent.lastName}`}
                                            firstElement={agent?.phoneNumber}
                                            secondElement={agent.email}
                                            thirdElement={
                                                agent.markets &&
                                                agent.markets.length &&
                                                agent.markets[0]
                                                    ? agent.markets[0].agentMlsId
                                                    : ''
                                            }
                                            status={agent.status}
                                            type="agent"
                                            onClickViewProfile={onClickAgent}
                                        />
                                    );
                                })}
                        </div>
                    </div> */}
                    <div className={styles.row}>
                        {!loading && brokerageStats.length ? (
                            <StatsInfo
                                stats={brokerageStats}
                                mlsOptions={statsFilters}
                                type={'brokerage'}
                            />
                        ) : (
                            <RandomLoader />
                        )}
                        <div className={styles.listingAgents}>
                            <div className={styles.sectionText}>Listing Agents:</div>
                            <div className={styles.line}></div>

                            {loadingListingAgents && (
                                <div className={styles.loader}>
                                    <RandomLoader />
                                </div>
                            )}
                            {!loadingListingAgents && listingAgents?.length ? (
                                listingAgents.map((agent) => {
                                    const tempAgent = {
                                        ...agent.agent,
                                        brokerageId: agent.brokerageId,
                                        agentMlsId: agent.agentMlsId,
                                        mlsName: agent.mlsName,
                                    };
                                    return (
                                        <>
                                            <ListingAgentCard
                                                agent={tempAgent}
                                                listingInfo={agent.listingInfo}
                                                connectedListings={agent.connectedListings}
                                                totalListings={agent.totalListings}
                                            />
                                            <div className={styles.line}></div>
                                        </>
                                    );
                                })
                            ) : (
                                <div className={styles.noBrokerages}>
                                    <div>No Agents With Listings</div>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default connector(BrokerageViewer);
