import { STATUS } from '../../../store/api/constants';
import { BSON } from 'realm-web';

export enum BROKERAGE_ACTIONS {
    fetchBrokerageUsers = '[Brokerages] Fetch Brokerage Users',
    fetchSelectedBrokerage = '[Brokerages] Fetch Selected Brokerage',
    fetchBrokerageAgents = '[Brokerages] Fetch Brokerage Agents',
    getAllBrokeragesRequested = '[Brokerages] Get All Brokerages',
    getAllBrokerageUsers = '[Brokerages] Get All Brokerage Users',
    getAllBrokerageAgents = '[Brokerages] Get All Brokerage Agents',
    setBrokeragePage = '[Brokerages] Set Brokerage Page',
    setSelectedBrokerage = '[Brokerages] Set Selected Brokerage',

    activateShowinglyPlus = '[Brokerages] Activate Showingly Plus',
    clearActivatePlusStates = '[Brokerages] Clear Activate Showingly Plus States',

    createBrokerageUser = '[Brokerages] Create Brokerage User',
    clearCreateUserStates = '[Brokerages] Clear Create Brokerage User States',

    activateBrokerageSuite = '[Brokerages] Activate Brokerage Suite',
    clearActivateBSuiteStates = '[Brokerages] Clear Activate Brokerage Suite States',

    DeleteUser = '[Brokerages] Delete Brokerage User',

    updateBrokerageUser = '[Brokerages] Update Brokerage User',
}

export function updateBrokerageUserRequested(user: any, _id: any, callBack: Function) {
    return {
        type: BROKERAGE_ACTIONS.updateBrokerageUser,
        status: STATUS.Requested,
        user,
        _id,
        callBack,
    };
}

export function updateBrokerageUserSucceeded(user: any) {
    return {
        type: BROKERAGE_ACTIONS.updateBrokerageUser,
        status: STATUS.Succeeded,
        user,
    };
}

export function updateBrokerageUserFailed(error: any) {
    return {
        type: BROKERAGE_ACTIONS.updateBrokerageUser,
        status: STATUS.Failed,
        error,
    };
}

export type updateBrokerageUser =
    | ReturnType<typeof updateBrokerageUserRequested>
    | ReturnType<typeof updateBrokerageUserSucceeded>
    | ReturnType<typeof updateBrokerageUserFailed>;

export function clearActivateBSuiteStatesRequested() {
    return {
        type: BROKERAGE_ACTIONS.clearActivateBSuiteStates,
        status: STATUS.Requested,
    };
}
export type clearActivateBSuiteStates = ReturnType<typeof clearActivateBSuiteStatesRequested>;

export function clearActivatePlusStatesRequested() {
    return {
        type: BROKERAGE_ACTIONS.clearActivatePlusStates,
        status: STATUS.Requested,
    };
}
export type clearActivatePlusStates = ReturnType<typeof clearActivatePlusStatesRequested>;

export function clearCreateUserStatesRequested() {
    return {
        type: BROKERAGE_ACTIONS.clearCreateUserStates,
        status: STATUS.Requested,
    };
}
export type clearCreateUserStates = ReturnType<typeof clearCreateUserStatesRequested>;

export function activateBrokerageSuiteRequested(brokerageId: string, action: 'enable' | 'disable') {
    return {
        type: BROKERAGE_ACTIONS.activateBrokerageSuite,
        status: STATUS.Requested,
        brokerageId,
        action,
    };
}
export function activateBrokerageSuiteSucceded(response: any) {
    return {
        type: BROKERAGE_ACTIONS.activateBrokerageSuite,
        status: STATUS.Succeeded,
        response,
    };
}
export function activateBrokerageSuiteFailed(error: any) {
    return {
        type: BROKERAGE_ACTIONS.activateBrokerageSuite,
        status: STATUS.Failed,
        error,
    };
}

export type activateBrokerageSuite =
    | ReturnType<typeof activateBrokerageSuiteRequested>
    | ReturnType<typeof activateBrokerageSuiteSucceded>
    | ReturnType<typeof activateBrokerageSuiteFailed>;

export function createBrokerageUserRequested(
    brokerageId: string,
    user: any,
    callBack: Function | null = null,
) {
    return {
        type: BROKERAGE_ACTIONS.createBrokerageUser,
        status: STATUS.Requested,
        brokerageId,
        user,
        callBack,
    };
}

export function createBrokerageUserSucceded(brokerageId: any, user: any) {
    return {
        type: BROKERAGE_ACTIONS.createBrokerageUser,
        status: STATUS.Succeeded,
        brokerageId,
        user,
    };
}

export function createBrokerageUserFailed(error: any) {
    return {
        type: BROKERAGE_ACTIONS.createBrokerageUser,
        status: STATUS.Failed,
        error,
    };
}

export type createBrokerageUser =
    | ReturnType<typeof createBrokerageUserRequested>
    | ReturnType<typeof createBrokerageUserSucceded>
    | ReturnType<typeof createBrokerageUserFailed>;

export function getAllBrokerageUsersRequested(brokerageId: string, page) {
    return {
        type: BROKERAGE_ACTIONS.getAllBrokerageUsers,
        status: STATUS.Requested,
        brokerageId,
        page,
    };
}

export function getAllBrokerageUsersSucceded(brokerageUsers: any) {
    return {
        type: BROKERAGE_ACTIONS.getAllBrokerageUsers,
        status: STATUS.Succeeded,
        brokerageUsers,
    };
}

export function getAllBrokerageUsersFailed(error: any) {
    return {
        type: BROKERAGE_ACTIONS.getAllBrokerageUsers,
        status: STATUS.Failed,
        error,
    };
}

export type getAllBrokerageUsers =
    | ReturnType<typeof getAllBrokerageUsersRequested>
    | ReturnType<typeof getAllBrokerageUsersSucceded>
    | ReturnType<typeof getAllBrokerageUsersFailed>;

export function getAllBrokerageAgentsRequested(brokerageId: string, mlsName: string, page: number) {
    return {
        type: BROKERAGE_ACTIONS.getAllBrokerageAgents,
        status: STATUS.Requested,
        brokerageId,
        mlsName,
        page,
    };
}

export function getAllBrokerageAgentsSucceded(brokerageAgents: any) {
    return {
        type: BROKERAGE_ACTIONS.getAllBrokerageAgents,
        status: STATUS.Succeeded,
        brokerageAgents,
    };
}

export function getAllBrokerageAgentsFailed(error: any) {
    return {
        type: BROKERAGE_ACTIONS.getAllBrokerageAgents,
        status: STATUS.Failed,
        error,
    };
}

export type getAllBrokerageAgents =
    | ReturnType<typeof getAllBrokerageAgentsRequested>
    | ReturnType<typeof getAllBrokerageAgentsSucceded>
    | ReturnType<typeof getAllBrokerageAgentsFailed>;

export function activateShowinglyPlusRequested(
    brokerageId: string,
    mlsName: string,
    action: 'add' | 'remove',
) {
    return {
        type: BROKERAGE_ACTIONS.activateShowinglyPlus,
        status: STATUS.Requested,
        brokerageId,
        mlsName,
        action,
    } as const;
}

export function activateShowinglyPlusSucceded(result: any) {
    return {
        type: BROKERAGE_ACTIONS.activateShowinglyPlus,
        status: STATUS.Succeeded,
        result,
    } as const;
}

export function activateShowinglyPlusFailed(error: string) {
    return {
        type: BROKERAGE_ACTIONS.activateShowinglyPlus,
        status: STATUS.Failed,
        error,
    } as const;
}

export type activateShowinglyPlus =
    | ReturnType<typeof activateShowinglyPlusRequested>
    | ReturnType<typeof activateShowinglyPlusSucceded>
    | ReturnType<typeof activateShowinglyPlusFailed>;

export function setSelectedBrokerage(brokerage: any) {
    return {
        type: BROKERAGE_ACTIONS.setSelectedBrokerage,
        brokerage,
    } as const;
}

export type setSelectedBrokerage = ReturnType<typeof setSelectedBrokerage>;

export function setBrokeragePage(page: number) {
    return {
        type: BROKERAGE_ACTIONS.setBrokeragePage,
        currentPage: page,
        page,
    };
}

export type SetCurrentPage = ReturnType<typeof setBrokeragePage>;

export function getAllBrokeragesRequested(page: number, searchParam?: any) {
    return {
        type: BROKERAGE_ACTIONS.getAllBrokeragesRequested,
        status: STATUS.Requested,
        page,
        searchParam,
    } as const;
}
export function getAllBrokeragesSucceded(brokerages: any) {
    return {
        type: BROKERAGE_ACTIONS.getAllBrokeragesRequested,
        status: STATUS.Succeeded,
        brokerages: brokerages,
    } as const;
}
export function getAllBrokeragesFailed(error: string) {
    return {
        type: BROKERAGE_ACTIONS.getAllBrokeragesRequested,
        status: STATUS.Failed,
        error: error,
    } as const;
}
export type GetAllBrokerages =
    | ReturnType<typeof getAllBrokeragesRequested>
    | ReturnType<typeof getAllBrokeragesSucceded>
    | ReturnType<typeof getAllBrokeragesFailed>;

/*----------- Fetch Selected Brokerage  ------------*/
export type SelectedBrokerage =
    | ReturnType<typeof fetchSelectedBrokerageRequested>
    | ReturnType<typeof fetchSelectedBrokerageSucceeded>
    | ReturnType<typeof fetchSelectedBrokerageFailed>;

export const fetchSelectedBrokerageRequested = (brokerageId: string) =>
    <const>{
        type: BROKERAGE_ACTIONS.fetchSelectedBrokerage,
        status: STATUS.Requested,
        brokerageId,
    };

export const fetchSelectedBrokerageSucceeded = (brokerage: any) =>
    <const>{
        type: BROKERAGE_ACTIONS.fetchSelectedBrokerage,
        status: STATUS.Succeeded,
        brokerage,
    };

export const fetchSelectedBrokerageFailed = (error: any) =>
    <const>{
        type: BROKERAGE_ACTIONS.fetchSelectedBrokerage,
        status: STATUS.Failed,
        error,
    };

/*----------- Fetch Selected Brokerage Users  ------------*/
export type BrokerageUsers =
    | ReturnType<typeof fetchSelectedBrokerageUsersRequested>
    | ReturnType<typeof fetchSelectedBrokerageUsersSucceeded>
    | ReturnType<typeof fetchSelectedBrokerageUsersFailed>;

export const fetchSelectedBrokerageUsersRequested = (brokerageId: string, page: number = 1) =>
    <const>{
        type: BROKERAGE_ACTIONS.fetchBrokerageUsers,
        status: STATUS.Requested,
        brokerageId,
        page,
    };

export const fetchSelectedBrokerageUsersSucceeded = (brokerageUsers: any) =>
    <const>{
        type: BROKERAGE_ACTIONS.fetchBrokerageUsers,
        status: STATUS.Succeeded,
        brokerageUsers,
    };

export const fetchSelectedBrokerageUsersFailed = (error: any) =>
    <const>{
        type: BROKERAGE_ACTIONS.fetchBrokerageUsers,
        status: STATUS.Failed,
        error,
    };

/*----------- Fetch Selected Brokerage Agents  ------------*/
export type BrokerageAgents =
    | ReturnType<typeof fetchSelectedBrokerageAgentsRequested>
    | ReturnType<typeof fetchSelectedBrokerageAgentsSucceeded>
    | ReturnType<typeof fetchSelectedBrokerageAgentsFailed>;

export const fetchSelectedBrokerageAgentsRequested = (brokerageId: string, page: number = 1) =>
    <const>{
        type: BROKERAGE_ACTIONS.fetchBrokerageAgents,
        status: STATUS.Requested,
        brokerageId,
        page,
    };

export const fetchSelectedBrokerageAgentsSucceeded = (brokerageAgents: any) =>
    <const>{
        type: BROKERAGE_ACTIONS.fetchBrokerageAgents,
        status: STATUS.Succeeded,
        brokerageAgents,
    };

export const fetchSelectedBrokerageAgentsFailed = (error: any) =>
    <const>{
        type: BROKERAGE_ACTIONS.fetchBrokerageAgents,
        status: STATUS.Failed,
        error,
    };

/*--------------- Delete ---------------*/

export function DeleteUserRequested(userId: BSON.ObjectId, callback: Function) {
    return {
        type: BROKERAGE_ACTIONS.DeleteUser,
        status: STATUS.Requested,
        userId,
        callback,
    };
}

export function DeleteUserSucceeded() {
    return {
        type: BROKERAGE_ACTIONS.DeleteUser,
        status: STATUS.Succeeded,
    };
}

export function DeleteUserFailed(error: string) {
    return {
        type: BROKERAGE_ACTIONS.DeleteUser,
        status: STATUS.Failed,
        error,
    };
}
