import { ErrorObject, serializeError } from 'serialize-error';
import { STATUS } from '../../../store/api/constants';
import { SEARCH_ACTION } from './actions';

export const initialState = {
    loading: false,
    // searching
    agents: [],
    brokerages: [],
    consumers: [],
    listings: [],
    // viewer
    selectedViewingBrokerage: null,
    brokerageRoster: [],
    brokerageAdmins: [],
    selectedViewingAgent: null,
    agentListings: [],
    loadingAgentListings: false,
    formattedMarkets: [],
    stats: [],
    statsFilterButtons: null,
    listingAgents: [],
    loadingListingAgents: [],
    // scheduling
    selectedListing: null,
    listingShowings: [],
    selectedListingMarketData: null,
    unavailableDates: [],
    showingSuccessfullyCreated: false,
    showingCreated: null,
    selectedViewingListing: null,
    listingLoading: false,
    updateShowing: null,
    resetPassword: null,

    error: null,
};
export type Searches = typeof initialState;

export function reducerSearch(state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case SEARCH_ACTION.TextSearchAgents: {
            console.log('random commit');
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                    };
                }
                case STATUS.Succeeded: {
                    const { agents } = action;
                    return {
                        ...state,
                        agents,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.TextSearchListings: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                    };
                }
                case STATUS.Succeeded: {
                    const { listings } = action;
                    return {
                        ...state,
                        listings,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.TextSearchBrokerages: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                    };
                }
                case STATUS.Succeeded: {
                    const { brokerages } = action;
                    return {
                        ...state,
                        brokerages,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.FetchShowingAvailability: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { unavailableDates } = action;
                    return {
                        ...state,
                        unavailableDates,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        //dev-1097
        //===========
        case SEARCH_ACTION.FetchPasswordChange: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { userStatus, email, stitchUserId } = action;

                    return {
                        ...state,
                        resetPassword: {
                            userStatus,
                            email,
                            stitchUserId,
                        },
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        //===========
        case SEARCH_ACTION.ScheduleShowing: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { showingCreated } = action;
                    return {
                        ...state,
                        showingCreated,
                        loading: false,
                        showingSuccessfullyCreated: true,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.ClearShowingAvailability: {
            return {
                ...state,
                unavailableDates: [],
            };
        }

        case SEARCH_ACTION.ClearShowingScheduled: {
            return {
                ...state,
                showingSuccessfullyCreated: false,
                showingCreated: null,
            };
        }

        case SEARCH_ACTION.SetSelectedBrokerage: {
            const { brokerage } = action;
            return {
                ...state,
                selectedViewingBrokerage: brokerage,
            };
        }
        case SEARCH_ACTION.ClearSelectedAgent: {
            return {
                ...state,
                selectedViewingAgent: null,
                formattedMarkets: [],
                agentListings: [],
                stats: [],
                statsFilterButtons: [],
            };
        }
        case SEARCH_ACTION.ClearSelectedBrokerage: {
            return {
                ...state,
                selectedViewingBrokerage: null,
                brokerageRoster: [],
                brokerageAdmins: [],
                formattedMarkets: [],
                stats: [],
                statsFilterButtons: [],
            };
        }
        case SEARCH_ACTION.FetchBrokerageRoster: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { roster } = action;
                    return {
                        ...state,
                        brokerageRoster: roster,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.FetchBrokerageAdmins: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { admins } = action;
                    return {
                        ...state,
                        brokerageAdmins: admins,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.UpdateSelectedBrokerage: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }

        case SEARCH_ACTION.SetAgentSelected: {
            const { agent } = action;
            return {
                ...state,
                selectedViewingAgent: agent,
            };
        }

        case SEARCH_ACTION.FetchSelectedListing: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        listingLoading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { listing } = action;

                    return {
                        ...state,
                        loading: false,
                        selectedViewingListing: listing,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        listingLoading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.FetchListingShowings: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        showingsLoading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { showings } = action;

                    return {
                        ...state,
                        showingsLoading: false,
                        listingShowings: showings,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        showingsLoading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.FetchFormattedMarkets: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { markets } = action;

                    return {
                        ...state,
                        loading: false,
                        formattedMarkets: markets,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.FetchBrokeragePlusPrices: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { prices } = action;
                    return {
                        ...state,
                        loading: false,
                        plusPrices: prices,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.FetchAgentListings: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingAgentListings: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { listings } = action;
                    return {
                        ...state,
                        loadingAgentListings: false,
                        agentListings: listings,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loadingAgentListings: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.UpdateShowingStatus: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { showing } = action;
                    return {
                        ...state,
                        loading: false,
                        updateShowing: showing,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.CreateShowingManagementSubscription: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loading: false,
                        paymentSuccessful: true,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loading: false,
                        errors: action.errors,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.FetchStats: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { stats, statsFilterButtons } = action;

                    return {
                        ...state,
                        loading: false,
                        stats,
                        statsFilterButtons,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.FetchBrokerageListingAgents: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingListingAgents: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { listingAgents } = action;

                    return {
                        ...state,
                        loadingListingAgents: false,
                        listingAgents,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loadingListingAgents: false,
                    };
                }
                default:
                    return state;
            }
        }
        default:
            return state;
    }
}
