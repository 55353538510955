import { LoadingIcon } from 'asset-lib/src/assets/gif';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { connect } from 'react-redux';
import { Button, Dropdown } from '../../../../../../components';
import { createLoggedAgentRequested } from '../../../actions';
import { InternExternType, LoggedAgentDataType } from '../../../constants';
import { getDataError, getDataLoading } from '../../../selectors';

import styles from './index.module.css';

const mapDispatchToProps = {
    createLoggedAgent: createLoggedAgentRequested,
};
const mapStateToProps = (state: any) => ({
    dataLoading: getDataLoading(state),
    dataErrors: getDataError(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type CreateAgentProps = {
    environment: 'dev' | 'staging' | 'production';
    createLoggedAgent: Function;
    dataLoading: any;
    dataErrors: any;
};

const CreateAgent = (props: CreateAgentProps) => {
    const { environment, createLoggedAgent, dataLoading, dataErrors } = props;

    const [internExtern, setInternExtern] = useState<InternExternType>('Internal');
    const [submittedData, setSubmittedData] = useState<LoggedAgentDataType | null>(null);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    useEffect(() => {
        if (dataLoading === false && submittedData !== null && dataErrors === null) {
            setShowSuccess(true);
        }
    }, [dataLoading, dataErrors, submittedData]);

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });

    const submit = (data: any) => {
        setShowSuccess(false);
        createLoggedAgent({ ...data, internExtern }, environment);
        setSubmittedData({ ...data, internExtern, environment });
    };

    return dataLoading === true ? (
        <img src={LoadingIcon} height={250} />
    ) : (
        <div className={styles.root}>
            {/* Success Message */}
            {showSuccess && (
                <div
                    style={{
                        marginBottom: 50,
                    }}
                >
                    <div className={styles.successText1}>
                        {submittedData.internExtern} agent created in {environment}:
                    </div>
                    <div className={styles.successText1}>First Name: {submittedData.firstName}</div>
                    <div className={styles.successText1}>Last Name: {submittedData.lastName}</div>
                    <div className={styles.successText1}>Phone: {submittedData.phone}</div>
                    <div className={styles.successText1}>Email: {submittedData.email}</div>
                </div>
            )}
            {/* Error messaging */}
            {dataErrors && (
                <div className={styles.errorText1}>
                    An error has occurred:{' '}
                    {dataErrors.errorCode || dataErrors.error || dataErrors.message}
                </div>
            )}
            {dataErrors?.link && (
                <div className={styles.errorText1}>
                    {`Give this link to a dev: ${dataErrors.link}`}
                </div>
            )}
            {/* Type of tester */}
            <div className={styles.header}>Internal or External account?</div>
            <Dropdown
                options={['Internal', 'External']}
                handleClick={(option) => {
                    setInternExtern(option);
                }}
                className={''}
            />
            <div className={styles.spacer} />
            {/* Agent data input form */}
            <form className={styles.form} onSubmit={handleSubmit(submit)}>
                <div className={styles.inputGroup}>
                    <input
                        id={'email'}
                        type={'string'}
                        name={'email'}
                        ref={register({ required: 'Email field is required.' })}
                        className={styles.formControl}
                        placeholder="Email (format: andrew@showingly.com)"
                    />
                    {errors.email && (
                        <span className={styles.errorText}>{errors.email.message}</span>
                    )}
                </div>
                <div className={styles.inputGroup}>
                    <input
                        id={'firstName'}
                        type={'string'}
                        name={'firstName'}
                        ref={register({ required: 'First name field is required.' })}
                        className={styles.formControl}
                        placeholder="First Name"
                    />
                    {errors.firstName && (
                        <span className={styles.errorText}>{errors.firstName.message}</span>
                    )}
                </div>
                <div className={styles.inputGroup}>
                    <input
                        id={'lastName'}
                        type={'string'}
                        name={'lastName'}
                        ref={register({ required: 'Last name field is required.' })}
                        className={styles.formControl}
                        placeholder="Last Name"
                    />
                    {errors.lastName && (
                        <span className={styles.errorText}>{errors.lastName.message}</span>
                    )}
                </div>
                <div className={styles.inputGroup}>
                    <input
                        id={'phone'}
                        type={'string'}
                        name={'phone'}
                        ref={register({ required: 'Phone field is required.' })}
                        className={styles.formControl}
                        placeholder="Phone Number (format: +13037252907)"
                    />
                    {errors.phone && (
                        <span className={styles.errorText}>{errors.phone.message}</span>
                    )}
                </div>
                <div className={styles.outContainer}>
                    <div className={styles.flexDiv}>
                        <Button
                            buttonText={`Create account in ${props.environment}`}
                            className={styles.button}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default connector(CreateAgent);
