import React from 'react';
import styles from './index.module.css';

type parameters = {
    totalPages: number;
    currentPage: number;
    onPrev: any;
    onNext: any;
};

export const Paginator = ({ totalPages, currentPage, onPrev, onNext }: parameters) => {
    return (
        <div className={styles.pagination}>
            <div className={styles.paginationText}>
                Page {currentPage} of {totalPages}
            </div>
            <div className={styles.paginationBtns}>
                <div
                    className={styles.paginationBtn}
                    onClick={() => onPrev()}
                    style={{
                        opacity: currentPage == 1 ? 0.5 : 1,
                        pointerEvents: currentPage == 1 ? 'none' : 'auto',
                    }}
                >
                    Previous
                </div>
                <div
                    className={styles.paginationBtn}
                    onClick={() => onNext()}
                    style={{
                        opacity: currentPage == totalPages ? 0.5 : 1,
                        pointerEvents: currentPage == totalPages ? 'none' : 'auto',
                    }}
                >
                    Next
                </div>
            </div>
        </div>
    );
};
export default Paginator;
