import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';

type parameters = {
    Brokerage: any;
};
export const BrokerageInformationTab = ({ Brokerage }: parameters) => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.subtitle}>
                {' '}
                <h3>Brokerage Information </h3>
            </div>
            <div className={styles.informationContainer}>
                <div className={styles.information}>
                    {' '}
                    <strong>Name</strong> {Brokerage.name}{' '}
                </div>
                <div className={styles.information}>
                    {' '}
                    <strong>Brokerage ID</strong> {Brokerage.markets[0].brokerageId}{' '}
                </div>
                <div className={styles.information}>
                    {' '}
                    <strong>Market</strong> {Brokerage.markets[0].displayMlsName}{' '}
                </div>
                <div className={styles.information}>
                    {' '}
                    <strong>Agents </strong> {Brokerage.agentCount}{' '}
                </div>
            </div>
        </div>
    );
};
export default BrokerageInformationTab;
