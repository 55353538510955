import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ProfileHeader } from '../../../../components/ProfileHeader';
import styles from './index.module.css';
import { RandomLoader } from '../../../../components';
import { useParams } from 'react-router-dom';
import { getAgentSubscriptionRequested, fetchSelectedAgentProfileRequested } from '../actions';
import { getSelectedAgent, getAgentSubscription, getFetchAgentProfileLoader } from '../selectors';
import AgentInformationTab from '../../../../components/AgentInformationtab';
import ManagePlanTab from '../../../../components/ManagePlan';
import { AgentProfileParams } from '../types';
const mapDispatchToProps = {
    getAgentSubscriptionRequested: getAgentSubscriptionRequested,
};
const mapStateToProps = (state: any) => ({
    Agent: getSelectedAgent(state),
    AgentSubscription: getAgentSubscription(state),
    loader: getFetchAgentProfileLoader(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
const AgentDetails = ({ Agent, AgentSubscription, getAgentSubscriptionRequested, loader }: any) => {
    /*-------- Constants --------*/
    const dispatch = useDispatch();
    const { id: agentUrlId, marketName } = useParams<AgentProfileParams>();
    const { markets = [], stitchUserId } = Agent;

    /*-------- Use Effects ---------*/
    useEffect(() => {
        if (markets[0]?.agentMlsId !== agentUrlId) {
            dispatch(fetchSelectedAgentProfileRequested(agentUrlId, marketName));
        }
    }, []);

    useEffect(() => {
        if (Object.keys(Agent).length > 0) {
            getAgentSubscriptionRequested(stitchUserId);
        }
    }, [Agent]);

    return (
        <div>
            <div className={styles.outerContainer}>
                {loader ? (
                    <div className={styles.innerContainer}>
                        <RandomLoader />
                    </div>
                ) : Object.keys(Agent).length > 0 ? (
                    <div className={styles.innerContainer}>
                        <ProfileHeader
                            firstName={Agent.firstName}
                            lastName={Agent.lastName}
                            brokerageName={'Brokerage name'}
                        ></ProfileHeader>
                        <AgentInformationTab Agent={Agent}></AgentInformationTab>
                        <ManagePlanTab agent={Agent}></ManagePlanTab>
                    </div>
                ) : (
                    <div className={styles.innerContainer}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <span className={styles.noAgentFound}>No Agent Found</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default connector(AgentDetails);
