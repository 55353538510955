import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';

type parameters = {
    text: string;
    onClick: Function;
    colorsReversed?: boolean;
    color?: 'blue'|'red';
};
export const ColoredButton = ({ text, onClick, colorsReversed = false, color = 'blue'  }: parameters) => {
    let chosenColor = color == 'red'? '#FF4343': '#48a4ff';
    let defaultStyle =
    {
        backgroundColor:chosenColor,
        borderColor: chosenColor,
        color: 'white',
    }
    let reversedStyle =
    {
        backgroundColor: 'white',
        color: colorsReversed ? chosenColor : 'white',
        border: '2px solid' + chosenColor,
        }
    return (
        <button
            
            className={colorsReversed == true ? styles.primaryBtnAlt : styles.primaryBtn}
            style={colorsReversed == true ? reversedStyle : defaultStyle}
            onClick={() => {
                onClick();
            }}
        >
            {' '}
            {text}{' '}
        </button>
    );
};

export default ColoredButton;
