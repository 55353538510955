import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import TopHat from '../../../../../Images/TopHat.png';
import classnames from 'classnames';
import { SearchableDropdown, Button, ButtonV4 } from 'web-lib';
import { RandomLoader } from '../../../../../components';
import { BSON } from 'mongodb-stitch-browser-sdk';

type GeneralInfoProps = {
    name: string;
    id?: string;
    market?: string;
    mlsName?: string;
    status: string;
    loading?: boolean;
    image?: string;
    agentCount?: number;
    hasSubscription?: boolean;
    isEditable?: boolean;
    onClickSave?: Function;
    possibleStatuses?: any[];
    admins?: any[];
    phone?: string;
    email?: string;
    lastSession?: string;
    showingManagement?: any;
    onPressAddShowingManagement?: Function;
    onPressAddBrokeragePlus?: Function;
};

export const GeneralInfo = ({
    image,
    name,
    id,
    market,
    mlsName,
    status,
    agentCount,
    hasSubscription,
    isEditable,
    onClickSave,
    loading,
    possibleStatuses,
    admins,
    phone,
    email,
    lastSession,
    showingManagement,
    onPressAddShowingManagement,
    onPressAddBrokeragePlus,
}: GeneralInfoProps) => {
    const profileImage = !image || image === '' ? TopHat : image;
    const color =
        status === 'verified' || status === 'active'
            ? styles.verified
            : status === 'pending' || status === 'logged'
            ? styles.pending
            : status === 'unverified'
            ? styles.unverified
            : status === 'suspended'
            ? styles.suspended
            : status === 'denied'
            ? styles.denied
            : styles.status;
    const rootStyle = classnames(styles.status, color);
    const subscription = hasSubscription ? (
        'Has Brokerage+'
    ) : (
        <div style={{ width: '300px', display: 'flex' }}>
            <ButtonV4
                className={styles.showingManagementButton}
                text="Subscribe to Brokerage+"
                onClick={() => {
                    onPressAddBrokeragePlus();
                }}
            />
        </div>
    );
    const [editing, setEditing] = useState<boolean>(false);
    const [updatedStatus, setUpdatedStatus] = useState<string>(null);
    const [newSuperAdminString, setNewSuperAdminString] = useState<string>(null);
    const [newSuperAdmin, setNewSuperAdmin] = useState<any>(null);
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    /**
     * Whenever an admin is selected, grab their objectId
     * If the person is unselected, set superAdmin to null
     */
    useEffect(() => {
        if (newSuperAdminString && newSuperAdminString !== 'none') {
            setNewSuperAdmin(JSON.parse(newSuperAdminString));
        } else if (newSuperAdminString === 'none') {
            setNewSuperAdmin(null);
        }
    }, [newSuperAdminString]);

    /**
     *  If status is ever unselected, set status to null
     */
    useEffect(() => {
        if (updatedStatus === 'none') {
            setUpdatedStatus(null);
        }
    }, [updatedStatus]);

    /**
     * Make sure button is disabled to prevent status from being updated when it shouldn't be.
     */
    useEffect(() => {
        if (
            (!updatedStatus && !newSuperAdmin) ||
            (updatedStatus === 'verified' && !newSuperAdmin) ||
            (newSuperAdmin && !updatedStatus) ||
            (newSuperAdmin && updatedStatus !== 'verified')
        ) {
            setSaveDisabled(true);
        } else {
            setSaveDisabled(false);
        }
    }, [updatedStatus, newSuperAdmin]);
    const nullOption = null;

    return (
        <div className={styles.root}>
            {!loading && (
                <div>
                    <div className={styles.buttonDiv}>
                        {isEditable &&
                            onClickSave &&
                            (!editing ? (
                                <div className={styles.editing}>
                                    <Button
                                        buttonText={'Edit'}
                                        className={styles.editButton}
                                        onClick={() => {
                                            setEditing(true);
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className={styles.editing}>
                                    <Button
                                        buttonText={'Cancel'}
                                        className={styles.cancelButton}
                                        onClick={() => {
                                            setEditing(false);
                                            setUpdatedStatus(null);
                                            setNewSuperAdmin(null);
                                            setNewSuperAdminString(null);
                                            setSaveDisabled(true);
                                        }}
                                    />

                                    <Button
                                        buttonText={'Save'}
                                        className={
                                            saveDisabled
                                                ? styles.editButtonDisabled
                                                : styles.editButton
                                        }
                                        onClick={() => {
                                            setEditing(false);
                                            onClickSave(updatedStatus, newSuperAdmin, admins);
                                            setUpdatedStatus(null);
                                            setNewSuperAdmin(null);
                                            setNewSuperAdminString(null);
                                            setSaveDisabled(true);
                                        }}
                                        disabled={saveDisabled}
                                    />
                                </div>
                            ))}
                    </div>
                    <div className={styles.imgContainer}>
                        <img className={styles.img} src={profileImage} />
                    </div>
                    <div className={styles.infoContainer}>
                        <div>
                            <div className={styles.name}>{name}</div>
                            <div>{phone}</div>
                            <div>{email}</div>
                        </div>
                        {!editing ? (
                            <div className={rootStyle}>
                                {status === '' ? 'UNVERIFIED' : status.toUpperCase()}
                            </div>
                        ) : (
                            <div className={styles.dropdownRow}>
                                <div>
                                    <select
                                        id="dropdown"
                                        name="status"
                                        className={styles.statusDropdown}
                                        onChange={(e) => setUpdatedStatus(e.target.value)}
                                    >
                                        <option value={'none'} selected>
                                            Choose Status
                                        </option>
                                        {possibleStatuses &&
                                            possibleStatuses.map((status: any, index: any) => (
                                                <option key={status} value={status}>
                                                    {status}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        )}

                        <div className={styles.otherInfo}>
                            {id && <div>{id}</div>}
                            {market && mlsName && (
                                <div>
                                    {market} - {mlsName}
                                </div>
                            )}
                            {agentCount && <div>{agentCount} Agents</div>}
                            {isEditable && (
                                <>
                                    <div>{subscription}</div>

                                    {showingManagement?.status !== 'active' ? (
                                        <Button
                                            buttonText={'Add Showing Management Subscription'}
                                            className={styles.showingManagementButton}
                                            onClick={() => {
                                                onPressAddShowingManagement();
                                            }}
                                        />
                                    ) : (
                                        <div>Has Showing Management</div>
                                    )}
                                </>
                            )}

                            <div>Last Session: {lastSession}</div>
                        </div>
                    </div>
                </div>
            )}
            {loading && (
                <div className={styles.loader}>
                    <RandomLoader />
                </div>
            )}
        </div>
    );
};

export default GeneralInfo;
