import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';

import { connect } from 'react-redux';

import styles from './index.module.css';

import AdminUsers from './AdminUsers';

const mapDispatchToProps = {};
const mapStateToProps = (state: any) => ({});
const connector = connect(mapStateToProps, mapDispatchToProps);

type ManagementPageProps = {
    page: string;
};

const ManagementPage = (props: ManagementPageProps) => {
    const { page } = props;

    switch (page) {
        case '1':
            return <AdminUsers />;
        case '2':
            return <>{`Coming Soon`}</>;
        default:
            return <>{`Nothing to see here`}</>;
    }
};

export default connector(ManagementPage);
