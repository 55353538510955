import { BSON } from 'realm-web';

export enum APP_IDS {
    dev = 'showingly-development-fndeq',
    staging = 'showingly-staging-wzhho',
    production = 'showingly-production-ckmll',
}

export type InternExternType = 'Internal' | 'External';
export type EnvironmentType = 'dev' | 'staging' | 'production';
export type AccountOperationType = 'Create' | 'Delete';
export type AgentSearchType = 'Internal' | 'External' | 'Both' | 'Any';
export type ListingSearchType = 'Address' | 'ListingId';

export type LoggedAgentDataType = {
    internExtern: InternExternType;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
};

export const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const defaultStartEnd = {
    0: {
        startTime: new Date('December 17, 1995 08:00:00'),
        endTime: new Date('December 17, 1995 20:00:00'),
    },
    1: {
        startTime: new Date('December 17, 1995 08:00:00'),
        endTime: new Date('December 17, 1995 20:00:00'),
    },
    2: {
        startTime: new Date('December 17, 1995 08:00:00'),
        endTime: new Date('December 17, 1995 20:00:00'),
    },
    3: {
        startTime: new Date('December 17, 1995 08:00:00'),
        endTime: new Date('December 17, 1995 20:00:00'),
    },
    4: {
        startTime: new Date('December 17, 1995 08:00:00'),
        endTime: new Date('December 17, 1995 20:00:00'),
    },
    5: {
        startTime: new Date('December 17, 1995 08:00:00'),
        endTime: new Date('December 17, 1995 20:00:00'),
    },
    6: {
        startTime: new Date('December 17, 1995 08:00:00'),
        endTime: new Date('December 17, 1995 20:00:00'),
    },
};

export const listingDocument = (listing, agentData, brokerage) => {
    return {
        ...listing,
        testListing: true,
        dateAttributed: new Date(),
        agent: {
            lastName: agentData.lastName,
            firstName: agentData.firstName,
            contact: {
                email: agentData.email,
                office: 'tester',
                cell: 'tester',
            },
            address: null,
        },
        coAgent: null,
        office: {
            name: brokerage.name,
            contact: {
                email: null,
                office: null,
                cell: null,
            },
        },
        name: brokerage.name,
        marketName: agentData.markets[0].marketName,
        marketObjectId: agentData.markets[0].marketObjectId,
        buyerAgent: {
            firstName: null,
            lastName: null,
            officeId: null,
            id: null,
        },
        mlsList: [
            {
                agentMlsId: agentData.markets[0].agentMlsId,
                brokerageId: agentData.markets[0].brokerageId,
                listingId: `TEST${agentData.lastName.toUpperCase()}${Math.floor(
                    Math.random() * 1000,
                )}`,
                mlsName: agentData.markets[0].mlsName,
                displayMlsName: agentData.markets[0].displayMlsName,
                standardStatus: 'Active',
                mlsId: 987654321,
                source: 'Test Data',
                servingName: brokerage.name || '',
                disclaimer: 'This is a fake listing generated by an admin',
            },
        ],
    };
};

export const agentDocument = ({
    firstName,
    lastName,
    phone,
    email,
    internExtern,
}: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    internExtern: string;
}) => {
    const splitEmail = email.split('@');
    splitEmail[0] = splitEmail[0] + '+' + Math.floor(Math.random() * 1000000000);
    const newEmail = splitEmail.join('@');
    return {
        markets: [
            {
                agentMlsId: `${firstName.toUpperCase()}${Math.floor(Math.random() * 1000)}`,
                brokerageId: 'KWR80',
                email: newEmail,
                displayMlsName: 'REColorado',
                jobTitle: 'Tester',
                marketName: 'Colorado',
                mlsName: 'recolorado',
                mlsStatus: 'Active',
                marketObjectId: new BSON.ObjectID('5ed063602fb2fd449a371852'),
                phoneNumber: phone,
            },
        ],
        status: 'logged',
        email: newEmail,
        firstName: firstName,
        lastName: lastName,
        otherPhoneNumber: null,
        phoneNumber: phone,
        stitchUserId: `Invalid${Math.floor(Math.random() * 10000000000000000)}`,
        lastLoggedSynced: new Date(),
        createdByAdminDate: new Date(),
        testingType: internExtern,
        settings: {
            showings: {
                showingUpdates: {
                    push: true,
                    email: true,
                },
            },
            crm: {
                connections: {
                    push: true,
                    email: true,
                },
                favorites: {
                    push: true,
                    email: true,
                },
                listingNotes: {
                    push: true,
                    email: true,
                },
            },
            brokerage: {
                newEvents: {
                    push: true,
                    email: true,
                },
                upcomingEvents: {
                    push: true,
                    email: true,
                },
                announcements: {
                    push: true,
                    email: true,
                },
                rankingsProgress: {
                    push: true,
                    email: true,
                },
                newListings: {
                    push: true,
                    email: true,
                },
            },
            pickup: {
                pickupNotifications: {
                    push: true,
                    email: true,
                },
            },
            social: {
                profileSettings: {
                    privateAccount: false,
                },
                newFollower: {
                    push: true,
                    email: true,
                },
                postLikes: {
                    push: true,
                    email: true,
                },
                postComments: {
                    push: true,
                    email: true,
                },
                newListing: {
                    push: true,
                    email: true,
                },
                newBadge: {
                    push: true,
                    email: true,
                },
            },
        },
        lastSynced: new Date(),
    };
};
