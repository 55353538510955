import { createSelector } from 'reselect';
import { SearchableDropdown } from 'web-lib';
import { search } from '../sagas';
import { Agents } from './reducers';

export const getAgentsState = (state: { agents: Agents }) => state.agents || {};
export const getLoading = createSelector(getAgentsState, (agents: any) => agents.loading);
export const getAgents = createSelector(getAgentsState, (agents: any) => agents.agents || {});
export const getAllAgents = createSelector(
    getAgentsState,
    (agents: any) => agents.allAgents[0]?.data || {},
);
export const getTotalCount = createSelector(
    getAgentsState,
    (agents: any) => agents.allAgents[0]?.metadata[0]?.total || {},
);
export const getSelectedAgent = createSelector(
    getAgentsState,
    (agents: any) => agents.selectedAgent || {},
);
export const getAgentSubscription = createSelector(
    getAgentsState,
    (agents: any) => agents.agentSubscription || {},
);

export const getAllAgentsLoading = createSelector(
    getAgentsState,
    (agents: any) => agents.loading || {},
);
export const getTotalPages = createSelector(
    getAgentsState,
    (agents: any) => Math.ceil(agents.allAgents[0]?.metadata[0]?.total / 50) || {},
);
export const getCurrentPage = createSelector(
    getAgentsState,
    (agents: any) => agents.currentPage || {},
);

export const getFetchAgentProfileLoader = createSelector(
    getAgentsState,
    (agents: any) => agents.fetchAgentProfileLoader,
);
