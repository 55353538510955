// Constant agent type that is used as a parameter in API calls.
export const USER_TYPE_AGENT = 'agent';

// The name of the view for showing requests
export const SHOWING_REQUESTS_VIEW = 'showingRequestsView';

// The name of the showing requests collection
export const SHOWING_REQUESTS = 'showingRequests';

// The name of the agents collection
export const AGENTS = 'agents';

export enum COLLECTION {
    Admins = 'admins',
    Agents = 'agents',
    Consumers = 'consumers',
    Markets = 'markets',
}

// The name of the feedback collection
export const FEEDBACK = 'feedback';

// The name of the consumers collection
export const CONSUMERS = 'consumers';

// The name of the favorites collection
export const FAVORITES = 'favorites';

// The name of the markets collection
export const MARKETS_COLLECTION = 'markets';

// The name of the pending connections collection
export const PENDING_CONNECTIONS = 'pendingConnections';

// The name of the listings collection
export const LISTINGS_COLLECTION = 'listings';

// The name of the stripe accounts collection
export const STRIPE_ACCOUNTS_COLLECTION_NAME = 'stripeAccounts';

// The name of the markets collection
export const MARKETS_COLLECTION_NAME = 'markets';

// The name of the brokerage collection
export const BROKERAGES = 'brokerages';

// The name of the badges collection
export const BADGES = 'badges';

export const BROKERAGE_USERS = 'brokerageUsers';

export enum STATUS {
    Requested,
    Succeeded,
    Failed,
}
