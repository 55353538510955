import React from 'react';
import { Col, Row } from 'react-grid-system';
import styles from './index.module.css';
import { CheckmarkGreen } from 'asset-lib/src/assets/svg';

export const ListingCard = (props: {
    listing: any;
    index: number;
    setSelectedListing: Function;
}) => {
    const { listing, index, setSelectedListing } = props;
    return (
        <div className={styles.cardRoot} onClick={() => setSelectedListing(listing)} key={index}>
            <Col>
                <Row>{listing.address.full}</Row>
                <Row>
                    {listing.mlsList?.map((mls) => {
                        return `ListingId: ${mls.agentMlsId} - MLS: ${mls.mlsName} `;
                    })}
                </Row>
            </Col>
            {listing.agentListing && (
                <Col style={{ alignSelf: 'center' }}>
                    <Row>
                        <img height={25} width={25} src={CheckmarkGreen} />
                        <div style={{ marginLeft: 7 }}>Connected</div>
                    </Row>
                </Col>
            )}
        </div>
    );
};
