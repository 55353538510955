import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { routerPush, routerRef } from './router';
import sha256 from 'crypto-js/sha256';

export { routerRef, routerPush };

export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

/**
 * Takes the phone number format stored in stitch and returns the number in
 * the format `+1 (012) 345-6789` or w/o international code if none is given.
 * @param {string} phoneNumber in the format `+10123456789` or w/o intl code
 * @param {boolean} includeDialingCode - Whether or not to add the country code
 * @returns {string}
 */
export function formatPhoneNumberForDisplay(phoneNumber: string, includeDialingCode = false) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match && match.length > 4) {
        const intlCode = match[1] ? '+1 ' : '';
        return includeDialingCode
            ? [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
            : ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumber;
}

export function formatEmailForDisplay(email: any) {
    var formattedEmail = email;
    if (email?.indexOf('+')) {
        const front = email.substr(0, email.indexOf('+'));
        const end = email.substr(email.indexOf('@'), email.length);
        if (front?.length && end?.length) {
            formattedEmail = front + end;
        }
    }
    return formattedEmail;
}

export function useOutsideClick(ref: any, doStuff: any) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                doStuff();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

export function timeSince(created: any) {
    if (created) {
        let message;
        const now = Date.now();
        const timeInMillis = new Date(created).getTime();
        const createdAtSeconds = (now - timeInMillis) / 1000;
        const minutesSinceCreation = createdAtSeconds / 60;
        const hoursSinceCreation = minutesSinceCreation / 60;
        const daysSinceCreation = hoursSinceCreation / 24;

        if (createdAtSeconds < 1) {
            message = 'Just Now';
        } else if (createdAtSeconds < 60 && createdAtSeconds > 1) {
            message = `${createdAtSeconds.toFixed(0)}s`;
        } else if (minutesSinceCreation >= 1 && minutesSinceCreation < 60) {
            message = `${minutesSinceCreation.toFixed(0)}m`;
        } else if (hoursSinceCreation >= 1 && hoursSinceCreation < 24) {
            message = `${hoursSinceCreation.toFixed(0)}h`;
        } else if (daysSinceCreation >= 1 && daysSinceCreation < 30) {
            message = `${daysSinceCreation.toFixed(0)}d`;
        } else {
            message = DateTime.fromJSDate(created).toFormat('D');
        }

        return message;
    }
    return 'No Session'; //Return no session if no last session is stored
}
export const generateSignature = () => {
    const secret = process.env.REACT_APP_CLIENT_VALIDATION;
    const timestamp = new Date().toISOString();
    const hash = sha256(`${timestamp} ${secret}`).toString();
    return { hash, timestamp };
};
