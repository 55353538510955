import React, { useState, useCallback, useRef } from 'react';
import styles from '../index.module.css';
import { supportCharts } from '../mongoCharts';
import { Button, Dropdown } from '../..';
import classnames from 'classnames';

type supportDashProps = {
    markets: any;
};

const SupportDash = ({ markets }: supportDashProps) => {
    let marketArray = markets && markets.map((market) => market.name);
    marketArray.unshift('All');
    const [chosenMarket, setMarket] = useState(marketArray[0]);

    // chart options
    const filter = chosenMarket === 'All' ? '{}' : `{"Market": "${chosenMarket}"}`;
    const maxDataAge = '180';
    const baseUrl =
        'https://charts.mongodb.com/charts-showingly-development-kcldi/embed/charts?id=';
    const options = `&theme=${'dark'}&attribution=false&autoRefresh=true&maxDataAge=${maxDataAge}&filter=${filter}&`;
    //For chart where filters don't apply
    const nonFilterOptions = `&theme=${'dark'}&attribution=false&autoRefresh=true&maxDataAge=${maxDataAge}&`;

    const style = classnames(styles.iframeStyleFullLength, styles.dark);
    return (
        <div className={styles.chartContainer}>
            <div className={styles.outerDropdown}>
                <label className={styles.details}>Market:</label>
                <div className={styles.formControl}>
                    <select
                        id="dropdown"
                        name="market"
                        className={styles.dropdown}
                        onChange={(e) => setMarket(e.target.value)}
                    >
                        {marketArray &&
                            marketArray.map((market: any, index: any) => (
                                <option key={market} value={market}>
                                    {market}
                                </option>
                            ))}
                    </select>
                </div>
            </div>
            <div className={styles.supportDiv}>
                <iframe
                    title="chart"
                    src={`${supportCharts.recentSignups}${options}`}
                    className={classnames(styles.iframeStyleFullLength, styles.dark)}
                ></iframe>
                <iframe
                    title="chart"
                    src={`${supportCharts.connectedListingsTable}${options}`}
                    className={style}
                ></iframe>
                <iframe
                    title="chart"
                    src={`${supportCharts.upcomingShowingsTable}${options}`}
                    className={style}
                ></iframe>
                <iframe
                    title="chart"
                    src={`${supportCharts.allDelegations}${nonFilterOptions}`}
                    className={style}
                ></iframe>
            </div>
        </div>
    );
};

export default SupportDash;
