import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ButtonV4 } from 'web-lib';
import styles from './index.module.css';
import { ButtonTabBar, LineGraph } from '../../../components';
import ErrorLogCard from './ErrorLogCard';
import {
    fetchErrorLogsRequested,
    setErrorFunctionSelected,
    fetchErrorGraphRequested,
    fetchOtherErrorDataRequested,
} from './actions';
import { getTodaysErrors } from './selectors';
import { functionError } from '../../../utils/constants';

const mapDispatchToProps = {
    fetchErrorLogsRequested,
    setErrorFunctionSelected,
    fetchErrorGraphRequested,
    fetchOtherErrorDataRequested,
};
const mapStateToProps = (state: any) => ({
    todaysErrors: getTodaysErrors(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type DevelopmentPageProps = {
    fetchErrorLogsRequested: Function;
    setErrorFunctionSelected: Function;
    fetchErrorGraphRequested: Function;
    fetchOtherErrorDataRequested: Function;
    todaysErrors: Array<any>;
};
const Development = ({
    fetchErrorLogsRequested,
    setErrorFunctionSelected,
    fetchErrorGraphRequested,
    fetchOtherErrorDataRequested,
    todaysErrors,
}: DevelopmentPageProps) => {
    useEffect(() => {
        fetchErrorLogsRequested();
    }, ['Call this useEffect only once']);

    let history = useHistory();
    function handleClick(path: string) {
        history.push(path);
    }
    const buttonOptions: { name: string; page: string }[] = [
        { name: 'Error Logs', page: 'errorLogs' },
    ];
    const [page, setPage] = useState<string>('errorLogs');

    const errorLogs = () => {
        return (
            <>
                <div className={styles.buttonDiv}>
                    <ButtonV4
                        color={'red'}
                        text={'Refresh Logs'}
                        onClick={() => fetchErrorLogsRequested()}
                    />
                </div>
                <div className={styles.header}>
                    <div className={styles.row}>
                        <div className={styles.text}>Function Name</div>
                        <div className={styles.text}>Application</div>
                        <div className={styles.text}>Errors Today</div>
                    </div>
                </div>

                {todaysErrors?.map((error: functionError) => {
                    function onClickFunction() {
                        history.push(`development/errorLogs/${error?._id?.functionName}`);
                        setErrorFunctionSelected(error);
                        fetchErrorGraphRequested(error?._id?.functionName, error?._id?.application);
                        fetchOtherErrorDataRequested(
                            error?._id?.functionName,
                            error?._id?.application,
                        );
                    }
                    return (
                        <ErrorLogCard
                            functionName={error?._id?.functionName}
                            application={error?._id?.application}
                            errorCount={error?.errorCount}
                            onClickError={onClickFunction}
                        />
                    );
                })}
            </>
        );
    };

    return (
        <div className={styles.root}>
            <ButtonTabBar buttonNames={buttonOptions} setPage={setPage} currPage={page} />
            {
                {
                    errorLogs: errorLogs(),
                }[page]
            }
        </div>
    );
};

export default connector(Development);
