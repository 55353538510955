import React, { useState } from 'react';
import styles from './index.module.css';
import backArrow from 'web-lib/images/backArrow.svg';
import { ButtonV4 } from 'web-lib';
import { useHistory } from 'react-router-dom';

type ListingHeaderProps = {
    selectedMenuItem: string;
    setSelectedMenuItem: Function;
};

const ListingHeader = ({
    selectedMenuItem,
    setSelectedMenuItem = () => {},
}: ListingHeaderProps) => {
    const history = useHistory();
    type MenuItem = {
        name: string;
        onClick: Function;
        disabled: boolean;
    };

    const MenuItem = ({ name, onClick, disabled }: MenuItem) => {
        return (
            <span
                style={
                    disabled
                        ? { color: '#d3d3d3', cursor: 'unset' }
                        : selectedMenuItem === name
                        ? { borderBottom: '2px solid #48A4FF', color: '#3a3a3a' }
                        : { color: '#6a6a6a' }
                }
                onClick={() => {
                    !disabled && onClick();
                }}
            >
                {name}
            </span>
        );
    };
    return (
        <div className={styles.header}>
            <div className={styles.backButton} onClick={() => history.goBack()}>
                <img alt={'leftArrow'} src={backArrow} className={styles.arrowIcon} />
                <span>Listing Details</span>
            </div>
            <div className={styles.menuContainer}>
                <MenuItem
                    name={'Home Info'}
                    onClick={() => setSelectedMenuItem('Home Info')}
                    disabled={false}
                />
                <MenuItem
                    name={'Showing History'}
                    onClick={() => setSelectedMenuItem('Showing History')}
                    disabled={false}
                />
            </div>
            {/* <div className={styles.scheduleButton}>
                <ButtonV4
                    text={'Schedule'}
                    onClick={() => {
                        window.alert('IT NO WORK RN :(');
                    }}
                />
            </div> */}
        </div>
    );
};

export default ListingHeader;
