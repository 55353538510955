import { createSelector } from 'reselect';
import { AuthState } from '../../redux';
import { EnvironmentType } from '../dash/Data/constants';

export const getAuthState = (state: any) => state.auth || {};
export const getProfileState = (state: any) => state.profile || {};

export const getIsUserAuthenticated = createSelector(getAuthState, (auth) => auth.isAuthenticated);

export const getAdminAccount = createSelector(getAuthState, (auth) => auth.account);

export const getAuthLoading = createSelector(getAuthState, (auth) => auth.loading);

export const getAllowedEnvironments = createSelector(getAuthState, (auth: any) =>
    Object.keys(auth?.account?.adminKeys || {}),
);
