import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import styles from './index.module.css';
import { RandomLoader } from '../../../../components';
import { Loader, SearchBar, PropertyListingCard } from 'web-lib';
import { useParams } from 'react-router-dom';

import {
    getSelectedBrokerage,
    getSelectedBrokerageAgents,
    getSelectedBrokerageUsers,
    getSelectedBrokerageUsersTotalPages,
    getSelectedBrokerageAgentsTotalPages,
    getLoadingSelectedBrokerage,
    getLoadingBrokerageUsers,
    getLoadingBrokerageAgents,
    getFetchBrokerageUsersError,
    getFetchBrokerageAgentsError,
    getCreateBrokerageUserError,
    getCreateBrokerageUserLoading,
    getCreateBrokerageUserSuccess,
    isDeletingUser,
    getLoadingUpdateUser,
    getErrorUpdateUser,
} from '../selectors';
import { Assets } from 'asset-lib/src/index';
import BrokerageHeader from '../../../../components/BrokerageHeader';
import BrokerageInformationTab from '../../../../components/BrokerageInformationTab';
import BrokerageManagePlanTab from '../../../../components/BrokerageManagePlanTab';
import BrokerageUsers from '../../../../components/BrokerageUsers';
import ColoredButton from '../../../../components/ColoredButton';
import Notification from '../../../../components/Notification';
import {
    fetchSelectedBrokerageRequested,
    fetchSelectedBrokerageUsersRequested,
    fetchSelectedBrokerageAgentsRequested,
    getAllBrokerageAgentsRequested,
    getAllBrokerageUsersRequested,
    clearCreateUserStatesRequested,
    DeleteUserRequested,
} from '../actions';
import { fetchSelectedBrokerage } from '../sagas';
import { getInitLoading } from '../../../../redux';
import UserForm from '../../../../components/UserForm';
import Paginator from '../../../../components/Paginator';
import { ObjectID } from 'mongodb';
import BrokerageAgents from '../../../../components/BrokerageAgents';

type navParams = {
    id: string;
};
const mapDispatchToProps = {
    getAllBrokerageUsersRequested: getAllBrokerageUsersRequested,
    getAllBrokerageAgentsRequested: getAllBrokerageAgentsRequested,
    clearCreateUserStates: clearCreateUserStatesRequested,
};
const mapStateToProps = (state: any) => ({
    brokerage: getSelectedBrokerage(state),
    fetchBrokerageUsersError: getFetchBrokerageUsersError,
    fetchBrokerageAgentsError: getFetchBrokerageAgentsError(state),
    getSelectedBrokerageUsersTotalPages: getSelectedBrokerageUsersTotalPages(state),
    getSelectedBrokerageAgentsTotalPages: getSelectedBrokerageAgentsTotalPages(state),
    initializationLoader: getInitLoading(state),
    loadingSelectedBrokerage: getLoadingSelectedBrokerage(state),
    loadingBrokerageUsers: getLoadingBrokerageUsers(state),
    loadingBrokerageAgents: getLoadingBrokerageAgents(state),
    selectedBrokerageUsers: getSelectedBrokerageUsers(state),
    selectedBrokerageAgents: getSelectedBrokerageAgents(state),
    loadingCreateUser: getCreateBrokerageUserLoading(state),
    errorCreateUser: getCreateBrokerageUserError(state),
    successCreateUser: getCreateBrokerageUserSuccess(state),
    getLoadingUpdateUser: getLoadingUpdateUser(state),
    getErrorUpdateUser: getErrorUpdateUser(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const BrokeragesDetails = ({
    brokerage,
    clearCreateUserStates,
    fetchBrokerageUsersError,
    fetchBrokerageAgentsError,
    getSelectedBrokerageUsersTotalPages,
    getSelectedBrokerageAgentsTotalPages,
    getAllBrokerageUsersRequested,
    getAllBrokerageAgentsRequested,
    initializationLoader,
    loadingSelectedBrokerage,
    loadingBrokerageUsers,
    loadingBrokerageAgents,
    selectedBrokerageUsers,
    selectedBrokerageAgents,
    /*.. CREATE BROKERAGE USER ..*/
    loadingCreateUser,
    errorCreateUser,
    successCreateUser,
    /*.. UPDATE BROKERAGE USER ..*/

    getLoadingUpdateUser,
    getErrorUpdateUser,
}: any) => {
    /*----- Redux ------------*/
    const dispatch = useDispatch();
    /*-------- States ---------*/
    const [tab, setTab] = useState<'Information' | 'Agents' | 'Users'>('Information');
    const [showForm, setshowForm] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [userMenu, setUserMenu] = useState(null);
    const [deletingUser, setDeletingUser] = useState<ObjectID | null>(null);

    /*-------- Constants --------*/
    const params = useParams<navParams>();

    useEffect(() => {
        setPage(1);
    }, [tab]);

    /*.. CREATE BROKERAGE USER ..*/

    useEffect(() => {
        let message = errorCreateUser?.message
            ? errorCreateUser?.message?.split('"message":"')[1].split('"')[0]
            : 'Please contact support';
        if (errorCreateUser) {
            window.alert('User creation failed, ' + message);
            clearCreateUserStates();
        }
    }, [errorCreateUser]);

    /*-------- Effects ---------*/

    useEffect(() => {
        let message = getErrorUpdateUser?.message
            ? getErrorUpdateUser?.message?.split('"message":"')[1].split('"')[0]
            : 'Please contact support';
        if (getErrorUpdateUser) {
            window.alert('User update failed: ' + message);
        }
    }, [getErrorUpdateUser]);

    /*-------- Effects ---------*/

    useEffect(() => {
        const urlBrokerageId = params.id;
        if (!Object.keys(brokerage).length) {
            dispatch(fetchSelectedBrokerageRequested(urlBrokerageId));
            dispatch(fetchSelectedBrokerageUsersRequested(brokerage?._id, page));
            dispatch(fetchSelectedBrokerageAgentsRequested(urlBrokerageId, page));
        } else {
            getAllBrokerageUsersRequested(brokerage?._id, page);
            getAllBrokerageAgentsRequested(
                brokerage?.markets[0]?.brokerageId,
                brokerage?.markets[0]?.mlsName,
                page,
            );
        }
    }, [page, brokerage]);

    function getTab(tab: 'Information' | 'Agents' | 'Users') {
        switch (tab) {
            case 'Information':
                return (
                    <div>
                        <BrokerageInformationTab Brokerage={brokerage} />
                        <BrokerageManagePlanTab
                            Brokerage={brokerage}
                            userCount={selectedBrokerageUsers.length}
                        />
                    </div>
                );
            case 'Agents':
                if (loadingBrokerageAgents) {
                    return <RandomLoader />;
                } else if (fetchBrokerageAgentsError?.length > 0) {
                    return <h2>An error ocurred, please contact support</h2>;
                } else if (Object.keys(selectedBrokerageAgents).length > 0) {
                    return (
                        <>
                            {selectedBrokerageAgents.map((user: any) => {
                                return <BrokerageAgents user={user} />;
                            })}
                            <Paginator
                                totalPages={getSelectedBrokerageAgentsTotalPages}
                                currentPage={page}
                                onPrev={() => {
                                    setPage(page - 1);
                                }}
                                onNext={() => {
                                    setPage(page + 1);
                                }}
                            />
                        </>
                    );
                } else if (selectedBrokerageAgents?.data == undefined) {
                    return (
                        <div className={styles.pagination}>
                            <h2>No agents found</h2>
                        </div>
                    );
                } else {
                    return <h2>Something happened, please reload this page or contact support</h2>;
                }
            case 'Users':
                if (loadingBrokerageUsers) {
                    return <RandomLoader />;
                } else if (fetchBrokerageUsersError?.length > 0) {
                    return <h2>An error ocurred, please contact support</h2>;
                } else if (Object.keys(selectedBrokerageUsers).length > 0) {
                    return (
                        <>
                            <ColoredButton
                                colorsReversed={false}
                                text={'Create User'}
                                onClick={() => setshowForm(true)}
                            />
                            {selectedBrokerageUsers.map((user: any) => {
                                return (
                                    <BrokerageUsers
                                        user={user}
                                        userMenu={userMenu}
                                        setUserMenu={setUserMenu}
                                        deletingUser={deletingUser}
                                        setDeletingUser={setDeletingUser}
                                        isDeletingUser={isDeletingUser}
                                        handleDeleteUser={handleDeleteUser}
                                        loadingUpdateUser={getLoadingUpdateUser}
                                        refreshBrokerageUsers={() =>
                                            getAllBrokerageUsersRequested(brokerage?._id, 1)
                                        }
                                    />
                                );
                            })}
                            <Paginator
                                totalPages={getSelectedBrokerageUsersTotalPages}
                                currentPage={page}
                                onPrev={() => {
                                    setPage(page - 1);
                                }}
                                onNext={() => {
                                    setPage(page + 1);
                                }}
                            />
                        </>
                    );
                } else if (selectedBrokerageUsers?.data == undefined) {
                    return (
                        <div className={styles.pagination}>
                            <h2>No users found</h2>
                        </div>
                    );
                } else {
                    return <h2>Something happened, please reload this page or contact support</h2>;
                }
        }
    }

    const handleDeleteUser = (userId: ObjectID) => {
        dispatch(
            DeleteUserRequested(userId, () => {
                setDeletingUser(null);
                getAllBrokerageUsersRequested(brokerage?._id, 1);
            }),
        );
    };

    return (
        <div className={styles.outerContainer}>
            {initializationLoader == false && loadingSelectedBrokerage == true ? (
                <RandomLoader />
            ) : loadingSelectedBrokerage == false && Object.keys(brokerage).length ? (
                <div className={styles.innerContainer}>
                    <BrokerageHeader
                        Name={brokerage?.name}
                        ID={brokerage?.markets[0]?.brokerageId}
                        Status={brokerage?.markets[0]?.mlsStatus}
                    />

                    <div className={styles.tabContainer}>
                        <div className={styles.tab} onClick={() => setTab('Information')}>
                            <div className={styles.tabText}>Information</div>
                            <div
                                className={styles.tabLine}
                                style={{
                                    backgroundColor: tab == 'Information' ? '#48A4FF' : '#bfbfbf',
                                }}
                            ></div>
                        </div>

                        <div
                            className={styles.tab}
                            onClick={() => {
                                setTab('Users');
                            }}
                        >
                            <div className={styles.tabText}>Users</div>
                            <div
                                className={styles.tabLine}
                                style={{
                                    backgroundColor: tab == 'Users' ? '#48A4FF' : '#bfbfbf',
                                }}
                            ></div>
                        </div>
                        <div
                            className={styles.tab}
                            onClick={() => {
                                setTab('Agents');
                            }}
                        >
                            <div className={styles.tabText}>Agents</div>
                            <div
                                className={styles.tabLine}
                                style={{
                                    backgroundColor: tab == 'Agents' ? '#48A4FF' : '#bfbfbf',
                                }}
                            ></div>
                        </div>
                    </div>

                    {getTab(tab)}
                </div>
            ) : null}
            <UserForm
                typeOfForm={'Create'}
                show={showForm}
                closeForm={() => setshowForm(false)}
                brokerageId={brokerage?._id}
                loading={loadingCreateUser}
                callBack={() => {
                    window.alert('User created successfully');
                    setshowForm(false);
                    getAllBrokerageUsersRequested(brokerage?._id, 1);
                    clearCreateUserStates();
                }}
            />
        </div>
    );
};

export default connector(BrokeragesDetails);
