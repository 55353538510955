import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { Line } from 'react-chartjs-2';

type errorDataPoint = {
    date: Date;
    stringDate: string;
    value: number;
};
type LineGraphProps = {
    dataSet: Array<errorDataPoint>;
    functionName: string;
};

export const LineGraph = ({ dataSet, functionName }: LineGraphProps) => {
    // Get array of dates for the past week
    let stringDateArr = [];
    const today = new Date();
    let end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    let start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    start.setDate(start.getDate() - 7);
    while (start < end) {
        start.setDate(start.getDate() + 1);
        const dateToAdd = new Date(start);
        const stringDate = dateToAdd.toISOString().slice(0, 10);

        stringDateArr.push(stringDate);
    }

    // change format of data for what the Line graph can handle
    const graphableData = dataSet.map((dataPoint: errorDataPoint) => {
        return {
            time: dataPoint.date,
            x: dataPoint.date,
            y: dataPoint.value,
        };
    });

    // if we are missing any dates, add them in as zeros and resort the array
    if (graphableData.length < stringDateArr.length) {
        stringDateArr.forEach((stringDate: string) => {
            const index = dataSet.findIndex((dataPoint) => dataPoint.stringDate === stringDate);
            if (index === -1) {
                graphableData.push({
                    time: new Date(stringDate),
                    x: new Date(stringDate),
                    y: 0,
                });
            }
        });
    }
    graphableData.sort((a: any, b: any) => new Date(a.x).getTime() - new Date(b.x).getTime());

    const data = {
        labels: stringDateArr,
        datasets: [
            {
                label: functionName,
                fill: false,
                lineTension: 0.2,
                borderColor: '#48a4ff',
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderWidth: 2,
                pointRadius: 4,
                pointHitRadius: 10,
                data: graphableData,
            },
        ],
    };
    const lineOptions = {
        scales: {
            xAxes: [
                {
                    display: true,
                    type: 'time',
                    time: {
                        parser: 'MM/DD/YYYY HH:mm',
                        tooltipFormat: 'll HH:mm',
                        unit: 'day',
                        unitStepSize: 1,
                        displayFormats: {
                            day: 'MM/DD/YYYY',
                        },
                    },
                },
            ],
            yAxes: [
                {
                    // stacked: true,
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
            ],
        },
        legend: {
            display: false,
        },
        tooltips: {
            enabled: true,
        },
        responsive: true,
        maintainAspectRatio: true,
    };

    return <div className={styles.testDiv}>{/* <Line data={data} options={lineOptions} /> */}</div>;
};
export default LineGraph;
